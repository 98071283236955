import {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {
  lighten,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useTheme,
} from '@mui/material';

function DigitalClockCustom({
                              value = dayjs(),
                              minTime,
                              maxTime,
                              interval = 30,
                              onChange = () => {
                              },
                            }) {

  const theme = useTheme();
  

  const [timeOptions, setTimeOptions] = useState([]);

  function handleClick(option) {
    onChange(option);
  }

  useEffect(() => {

    const options = [];

    let startTimeOption = minTime || dayjs().startOf('day');
    let endTimeOption = maxTime || dayjs().endOf('day');

    let timeOption = startTimeOption;

    while (timeOption.isSameOrBefore(endTimeOption)) {
      options.push(timeOption);
      if (timeOption?.format('HH:mm') === '23:30' && interval === 30) {
        timeOption = timeOption.add(29, 'minutes');
      }else if (timeOption?.format('HH:mm') === '23:45' && interval === 15) {
        timeOption = timeOption.add(14, 'minutes');
      }
      
      else {
        timeOption = timeOption.add(interval, 'minute');
      }
    }
    setTimeOptions(options);

    //todo: this exists to correct popper not calculating correct height/position before time options are generated
    //alternative is to set keepMounted to true on popper component, this is causing performance issues.
    window.dispatchEvent(new CustomEvent('resize'));

  }, [minTime, maxTime, interval]);

  return (
      <List>
        {
          timeOptions.map((option, index) => {
            return <ListItem
                key={index}
                disablePadding
                onClick={() => {
                  handleClick(option);
                }}
            >
              <ListItemButton
                  selected={option?.format('HH:mm') === value?.format('HH:mm')}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: lighten(theme.palette.primary.main,
                            .1),
                      },
                    },
                  }}
              >
                <ListItemText primary={option.format('LT')}/>
              </ListItemButton>
            </ListItem>;
          })
        }
      </List>
  );

}

export default DigitalClockCustom;