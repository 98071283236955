import {
  alpha,
  Box,
  Divider,
  LinearProgress,
  Stack,
  useTheme,
} from '@mui/material';
import EmptyPlaceholder from '../Empty/EmptyPlaceholder';
import {SearchOff} from '@mui/icons-material';

function TableCustom({
                       columns,
                       rows,
                       getRowId,
                       loading,
                       emptySlot,
                       isSearching,
                     }) {

  return (
      <Stack>
        <TableCustomHeader columns={columns}/>
        <TableCustomBody
            columns={columns}
            rows={rows}
            loading={loading}
            getRowId={getRowId}
            emptySlot={emptySlot}
            isSearching={isSearching}
        />
      </Stack>
  );

}

function TableCustomHeader({columns, loading}) {

  const theme = useTheme();

  return (
      <Box
          sx={{
            display: 'flex',
            backgroundColor: theme.palette.background.neutral,
          }}
      >
        {
          columns?.map((column, index) => {
            return (
                <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: column.align || 'flex-start',
                      flex: column.flex || 1,
                      py: 2,
                      px: 2,
                      fontSize: '14px',
                      fontWeight: 600,
                      color: theme.palette.text.secondary,
                    }}
                >
                  {column?.label}
                </Box>
            );
          })
        }
      </Box>
  );
}

function TableCustomBody({
                           columns,
                           rows,
                           getRowId,
                           emptySlot,
                           loading,
                           isSearching,
                         }) {

  const theme = useTheme();

  return (
      <Stack sx={{position: 'relative'}}>
        {
            loading && <LinearProgress
                sx={{position: 'absolute', top: 0, left: 0, right: 0, zIndex: 2}}/>
        }
        {
            loading && rows && <LoadingOverlay/>
        }
        {
            loading && (!rows || rows?.length === 0) &&
            <Box sx={{
              height: '400px',
              backgroundColor: '#fff',
              borderRadius: '0 0 12px 12px',
              border: `1px solid ${theme.palette.divider}`,
            }}
            />
        }
        {
            !loading && (!rows || (!isSearching && rows?.length === 0)) &&
            <EmptyContainer>
              {emptySlot ?? <EmptyPlaceholder title={'No data'}/>}
            </EmptyContainer>
        }
        {
            !loading && (rows && (isSearching  && rows?.length === 0)) &&
            <EmptyContainer>
              {
                <EmptyPlaceholder
                    icon={<SearchOff color={'action'}/>}
                    title={'No Search Results'}
                />
              }
            </EmptyContainer>
        }
        {
            rows?.length > 0 &&
            <Stack
                divider={<Divider sx={{borderStyle: 'dashed'}}/>}
                sx={{
                  backgroundColor: '#fff',
                  border: `1px solid ${theme.palette.divider}`,
                  borderTop: 'none',
                  borderRadius: '0 0 12px 12px',
                }}
            >
              {
                rows?.map((row, index) => {
                  return (
                      <TableCustomRow key={index} row={row} columns={columns}/>
                  );
                })
              }
            </Stack>
        }

      </Stack>
  );
}

function TableCustomRow({row, columns}) {
  return (
      <Box
          sx={{
            display: 'flex',
          }}
      >
        {
          columns.map((column, index) => {
            return (
                <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: column.align || 'flex-start',
                      flex: column.flex || 1,
                      py: 3,
                      px: 2,
                    }}
                >
                  {column?.renderCell && column.renderCell(row)}
                </Box>
            );
          })
        }

      </Box>
  );
}

function LoadingOverlay() {
  return (
      <Box sx={{
        pointerEvents: 'none',
        zIndex: 1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        borderRadius: '0 0 12px 12px',
        backgroundColor: alpha('#fff', .6),
      }}/>
  );
}

function EmptyContainer({children}) {

  const theme = useTheme();

  return (
      <Box
          sx={{
            height: '300px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fff',
            borderRadius: '0 0 12px 12px',
            border: `1px solid ${theme.palette.divider}`,
          }}
      >
        {children}
      </Box>
  );
}

export default TableCustom;
