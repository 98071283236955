import RegistrationsSection from './RegistrationsSection';
import {useTranslate} from '@tolgee/react';
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import DataGridReusable from '../../_Global/DatGrid/DataGridReusable';
import {useMemo, useState} from 'react';
import {
  columnTypeDefaults,
} from '../../../vendor/mui/dataGridFilterColumnTypes';
import {
  Add,
  CheckCircle,
  Circle,
  MoreVert,
  PersonRemove,
} from '@mui/icons-material';
import PageCard from '../../_Global/Page/PageCard/PageCard';
import RegistrationsAddParticipantDialog
  from './RegistrationsAddParticipantDialog';
import DialogConfirmation from '../../../dialogs/DialogConfirmation';
import {
  tournamentsParticipantUpdate_PATCH,
  tournamentsRegistrationsParticipantRemove_POST,
} from '../../../api/tournaments';
import {useSnackbar} from 'notistack';

function RegistrationsSectionRegistered({
                                          sharedColumns,
                                          tournamentDetails,
                                          rows,
                                          loading,
                                          onParticipantAddRemove = () => {
                                          },
                                          registered,
                                          setRegistered,
                                        }) {

  const {t} = useTranslate();

  const [showAddParticipant, setShowAddParticipant] = useState(false);

  const sectionLabel = t('tournaments.registrations.section.registered');

  const columns = [
    ...sharedColumns,
    {
      ...columnTypeDefaults,
      filterable: false,
      sortable: false,
      field: 'checkin',
      headerAlign: 'center',
      flex: .3,
      headerName: t('tournaments.registrations.table.column.checkin'),
      renderCell: ({row}) => {
        return (
            <CheckIn
                row={row}
                setRegistered={setRegistered}
                registered={registered}
            />
        );
      },
    },
    {
      ...columnTypeDefaults,
      filterable: false,
      sortable: false,
      field: 'action',
      flex: 0,
      headerName: '',
      renderCell: ({row}) => {
        return (
            <Stack
                direction="row"
                justifyContent={'flex-end'}
                alignItems={'center'}
                px={1}
                sx={{height: '100%'}}
            >
              <RowActions
                  row={row}
                  onRemove={onParticipantAddRemove}
              />
            </Stack>
        );
      },
    },
  ];

  const categories = tournamentDetails?.tournamentCategories;

  const maxRegistrationsCount = useMemo(() => {
    return categories?.reduce(
        (sum, category) => sum + category.participantCount, 0);
  }, [categories]);

  const tournamentSport = tournamentDetails?.sport;

  return (
      <>
        <RegistrationsSection
            label={sectionLabel}
            count={rows?.length}
            action={
              <Button
                  onClick={() => {
                    setShowAddParticipant(true);
                  }}
                  startIcon={<Add/>}
                  disabled={!registered || registered?.length >=
                      maxRegistrationsCount}
              >
                {t('tournaments.registrations.action.addParticipants')}
              </Button>
            }
        >
          <PageCard>
            <DataGridReusable
                isLoading={loading}
                columns={columns}
                rows={rows}
                getRowId={(row) => row.id}
            />
          </PageCard>
        </RegistrationsSection>
        <RegistrationsAddParticipantDialog
            open={showAddParticipant}
            onClose={() => setShowAddParticipant(false)}
            onAdd={onParticipantAddRemove}
            categories={categories}
            registered={registered}
            tournamentSport={tournamentSport}
        />
      </>
  );
}

function RowActions({row, onRemove}) {

  const {t} = useTranslate();
  const {enqueueSnackbar} = useSnackbar();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [showRemoveParticipantConfirmation, setShowRemoveParticipantConfirmation] = useState(
      false);
  const [isSubmittingRemoveParticipant, setIsSubmittingRemoveParticipant] = useState();

  const participantId = row?.id;

  function handleRemoveParticipant() {

    setIsSubmittingRemoveParticipant(true);

    tournamentsRegistrationsParticipantRemove_POST(
        {participantId, cbSuccess, cbFail});

    function cbSuccess() {
      onRemove();
      setIsSubmittingRemoveParticipant(false);
      setShowRemoveParticipantConfirmation(false);
    }

    function cbFail(e) {
      const err = e.response?.data?.data;
      enqueueSnackbar(err || 'Error removing participant', {variant: 'error'});
      setIsSubmittingRemoveParticipant(false);

    }
  }

  return (
      <>
        <IconButton
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
        >
          <MoreVert/>
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => {
              setAnchorEl(null);
            }}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
        >
          <MenuItem
              onClick={() => {
                setShowRemoveParticipantConfirmation(true);
                setAnchorEl(null);
              }}
          >
            <ListItemIcon>
              <PersonRemove/>
            </ListItemIcon>
            <ListItemText>
              {t('tournaments.registrations.action.removeParticipant')}
            </ListItemText>

          </MenuItem>
        </Menu>
        <DialogConfirmation
            variant={'error'}
            isOpen={showRemoveParticipantConfirmation}
            isSubmitting={isSubmittingRemoveParticipant}
            dialogTitle={t(
                'tournaments.registrations.removeParticipant.confirm.title')}
            dialogContent={t(
                'tournaments.registrations.removeParticipant.confirm.content')}
            handleClose={() => setShowRemoveParticipantConfirmation(false)}
            handleConfirm={handleRemoveParticipant}
            confirmButtonLabel={t(
                'tournaments.registrations.removingParticipant.confirm.button')}
        />
      </>
  );
}

function CheckIn({row, setRegistered, registered}) {

  const [loading, setLoading] = useState();

  const checkedIn = row?.checkedIn;
  const participantId = row?.id;

  const previousParticipants = [...(registered || [])];

  function handleCheckin() {

    setLoading(true);

    setRegistered((participants) => {
      return participants.map(p => {
        if (p.id === participantId) {
          return {...p, checkedIn: !checkedIn};
        } else {
          return p;
        }
      });
    });

    tournamentsParticipantUpdate_PATCH({
      participantId,
      body: {checkedIn: !checkedIn},
      cbSuccess,
      cbFail,
    });

    function cbSuccess(res) {
      setLoading(false);
    }

    function cbFail(e) {
      setRegistered(previousParticipants);
      setLoading(false);
    }
  }

  return (
      <Stack
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            height: '100%',
          }}
      >
        <IconButton
            disabled={loading}
            color={checkedIn ? 'success' : 'default'}
            onClick={() => {
              handleCheckin();
            }}
        >
          {checkedIn && <CheckCircle/>}
          {!checkedIn && <Circle sx={{opacity: '.2'}}/>}
        </IconButton>
      </Stack>

  );
}

export default RegistrationsSectionRegistered;
