import {
  Button,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {Stack} from '@mui/system';
import ChipRating from '../../components/_Global/Chips/ChipRating';
import {useEffect, useMemo, useRef, useState} from 'react';
import {Add, Clear, PersonOffOutlined, Search} from '@mui/icons-material';
import TableCustom from '../../components/_Global/Table/TableCustom';
import EmptyPlaceholder from '../../components/_Global/Empty/EmptyPlaceholder';
import {
  useTournamentMatchesContext,
} from '../../components/Tournaments/Matches/TournamentMatchesProvider';
import {tournamentsMatchesReplacePlayerList_GET} from '../../api/tournaments';
import TournamentParticipantStatusChip
  from '../../components/Tournaments/TournamentParticipantStatusChip';

function DialogTournamentMatchesReplacePlayer({
                                                open,
                                                onClose,
                                                replaceSource,
                                                replaceMatchId,
                                              }) {

  const {t} = useTranslate();

  return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth={'lg'}>
        <DialogTitle>
          {t('tournaments.matches.replacePlayer.dialog.title')}
        </DialogTitle>
        <DialogContent sx={{py: 2}}>
          <ReplaceDescription replaceSource={replaceSource}/>
          <ReplaceList
              open={open}
              replaceMatchId={replaceMatchId}
              replaceSource={replaceSource}
          />
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={1} sx={{width: '100%'}}
                 justifyContent="flex-start">
            <Button onClick={onClose} variant="outlined">
              {t('global.buttons.actions.close')}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
  );
}

function ReplaceDescription({replaceSource}) {

  const {t} = useTranslate();

  //todo icu formatting
  // const rating = (() => {
  //   const r = replaceSource?.user?.rating;
  //   if (typeof r === 'number') {
  //     return r.toFixed(2);
  //   } else {
  //     return 'NA';
  //   }
  // })();

  const name = (() => {
    if (replaceSource) {
      return `${replaceSource?.user?.firstName ||
      ''} ${replaceSource?.user?.lastName || ''}`;
    }
  })();

  return (
      <Typography>
        {t('tournaments.matches.replacePlayer.dialog.description',{
          name:name
        })}

      </Typography>
  );

}

function ReplaceList({open, replaceMatchId, replaceSource}) {

  const [participants, setParticipants] = useState();
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState();

  const filtered = useMemo(() => {
    if (!participants) return;
    if (!searchTerm) return participants;
    return filterParticipants(participants, searchTerm);
  }, [searchTerm, participants]);

  function filterParticipants(participants, searchTerm) {

    if (!searchTerm) return participants;

    searchTerm = searchTerm.toLowerCase().trim();

    return participants?.filter(p => {

      if (!p?.team || !p?.team?.tournamentParticipants) return false;

      return p.team.tournamentParticipants.some(participant => {
        const user = participant.user;
        const firstName = user?.firstName?.trim().toLowerCase() || '';
        const lastName = user?.lastName?.trim().toLowerCase() || '';
        const fullName = `${firstName} ${lastName}`.trim();

        return (
            firstName.includes(searchTerm) ||
            lastName.includes(searchTerm) ||
            fullName.includes(searchTerm)
        );
      });

    });
  }

  function fetchParticipants() {

    setLoading(true);
    tournamentsMatchesReplacePlayerList_GET({
      matchId: replaceMatchId,
      sourcePlayerId: replaceSource?.id,
      cbSuccess,
      cbFail,
    });

    function cbSuccess(res) {
      const data = res?.data?.data;
      const filterSameMatch = data?.filter((i) => !i.sameMatch);
      setParticipants(filterSameMatch);
      setLoading(false);
    }

    function cbFail(e) {
      setParticipants([]);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (open && replaceMatchId && replaceSource) {
      fetchParticipants();
    }
  }, [open, replaceMatchId, replaceSource]);

  return (
      <Stack>
        <ReplaceListHeader
            participants={participants}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
        />
        <ReplaceListBody
            searchTerm={searchTerm}
            participants={filtered}
            loading={loading}
        />
      </Stack>
  );
}

function ReplaceListHeader({participants, searchTerm, setSearchTerm}) {

  const searchRef = useRef(null);

  const disabled = !participants || participants?.length === 0;

  const debounceSetSearchTerm = debounce((value) => {
    setSearchTerm(value);
  }, 500);

  function handleClearSearch() {
    if (searchRef.current) {
      searchRef.current.value = '';
    }
    setSearchTerm('');
  }

  return (
      <Stack
          mt={1}
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            py: 2,
          }}
      >
        <TextField
            sx={{width: '280px'}}
            disabled={disabled}
            inputRef={searchRef}
            onChange={(event) => {
              debounceSetSearchTerm(event.target.value);
            }}
            InputProps={{
              startAdornment: <InputAdornment position={'start'}>
                <Search color={'action'}/>
              </InputAdornment>,
              endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearSearch}>
                      <Clear/>
                    </IconButton>
                  </InputAdornment>
              ),
            }}
            placeholder={'Search...'}
        />
      </Stack>
  );

}

function ReplaceListBody({participants, loading, searchTerm}) {

  const {t} = useTranslate();

  const columns = [
    {
      flex: 0.5,
      align: 'center',
      label: t('tournaments.scoreboard.table.column.ranking'),
      renderCell: (row) => {
        const ranking = row?.rank;
        if (typeof ranking === 'number' && ranking > 0) {
          return `${ranking}.`;
        }
      },
    },

    {
      flex: 1,
      label: t('tournaments.scoreboard.table.column.players'),
      renderCell: (row) => {

        const participants = row?.team?.tournamentParticipants;

        return (
            <Stack direction="row" spacing={3} alignItems={'flex-start'}>
              {
                participants?.map((p, index) => {

                  const user = p?.user;
                  const firstName = user?.firstName;
                  const lastName = user?.lastName;
                  const rating = user?.rating;

                  const ratingLabel = (() => {
                    if (rating || rating === 0) {
                      return rating.toFixed(2);
                    } else {
                      return 'NA';
                    }
                  })();

                  return (
                      <Stack alignItems={'flex-start'} key={index}>
                        <Typography>
                          {`${firstName || ''} ${lastName || ''}`}
                        </Typography>
                        <ChipRating rating={ratingLabel}/>
                      </Stack>
                  );
                })
              }

            </Stack>
        );
      },
    },
    {
      flex: 1,
      align: 'center',
      label: t('tournaments.scoreboard.table.column.matches'),
      renderCell: (row) => {
        return row?.matchesPlayed;
      },
    },
    {
      flex: 1,
      align: 'center',
      label: t('tournaments.scoreboard.table.column.wins'),
      renderCell: (row) => {
        return row?.matchesWon;
      },
    },
    {
      flex: 1,
      align: 'center',
      label: t('tournaments.scoreboard.table.column.draws'),
      renderCell: (row) => {
        return row?.matchesDraw;
      },
    },
    {
      flex: 1,
      align: 'center',
      label: t('tournaments.scoreboard.table.column.losses'),
      renderCell: (row) => {
        return row?.matchesLost;
      },
    },
    {
      flex: 1,
      align: 'center',
      label: t('tournaments.scoreboard.table.column.diff'),
      renderCell: (row) => {
        const scoreState = row.scoreStat;
        let color;
        let value;

        if (scoreState > 0) {
          color = 'success.main';
          value = `+${scoreState}`;
        }
        if (scoreState < 0) {
          color = 'error.main';
          value = `${scoreState}`;
        }
        if (scoreState === 0) {
          value = 0;
        }

        return (
            <Typography color={color}>
              {value}
            </Typography>
        );
      },
    },
    {
      flex: 1,
      align: 'center',
      label: t('tournaments.scoreboard.table.column.points'),
      renderCell: (row) => {
        return (
            <Typography fontWeight={700} color={'primary.main'}>
              {row?.score}
            </Typography>
        );
      },
    },
    {
      flex: 1,
      label: t('tournaments.scoreboard.table.column.status'),
      renderCell: (row) => {
        const status = row.status;
        return (
            <TournamentParticipantStatusChip status={status}/>
        );
      },
    },
    {
      flex: .5,
      label: t('tournaments.scoreboard.table.column.add'),
      renderCell: (row) => {
        return (
            <ReplaceButton row={row}/>
        );
      },
    },

  ];

  return (
      <TableCustom
          rows={participants}
          loading={loading}
          columns={columns}
          isSearching={!!searchTerm}
          emptySlot={
            <EmptyPlaceholder
                icon={<PersonOffOutlined color={'action'}/>}
                title={
                  <Stack
                      alignItems={'center'}
                      sx={{
                        maxWidth: '426px',
                        textAlign: 'center',
                      }}
                  >
                    <Typography variant={'body1'}>
                      {t('tournaments.matches.replacePlayer.dialog.list.empty')}
                    </Typography>
                  </Stack>
                }
            />
          }
      />
  );

}

function ReplaceButton({row}) {

  const theme = useTheme();
  const target = row?.team?.tournamentParticipants?.[0];

  const {
    setReplaceTarget,
    setShowReplacePlayer,
    setShowConfirmReplace,
  } = useTournamentMatchesContext();

  function handleReplaceClick() {
    setReplaceTarget(target);
    setShowReplacePlayer(false);
    setShowConfirmReplace(true);
  }

  return (
      <IconButton
          size={'small'}
          color={'primary'}
          onClick={handleReplaceClick}
          sx={{
            border: `1px solid ${theme.palette.primary.main}`,
          }}
      >
        <Add/>
      </IconButton>
  );
}

export default DialogTournamentMatchesReplacePlayer;
