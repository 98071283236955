import {LinearProgress} from '@mui/material';

function Progress({percent}) {

  let color = 'warning';

  if (percent <= 0) {
    color=  'error'
  }

  if (percent >= 100) {
    color=  'success'
  }

  return (
      <LinearProgress
          value={percent}
          variant="determinate"
          color={color}
          sx={{
            height:"6px",
            borderRadius: '4px',
            ".MuiLinearProgress-bar":{
              borderRadius: '4px',
            }
      }}
      />
  );
}

export default Progress;
