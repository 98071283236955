import {createContext, useContext, useEffect, useRef, useState} from 'react';
import {tournamentsScoreboardList_GET} from '../../../api/tournaments';

const TournamentsScoreBoardContext = createContext();

function TournamentsScoreBoardProvider({categories, tournamentId, tournamentSport, children}) {

  const [scores, setScores] = useState();
  const [loadingScores, setLoadingScores] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [searchTerm, setSearchTerm] = useState('');
  const searchRef = useRef();

  function fetchScores(categoryId) {

    setLoadingScores(true);

    tournamentsScoreboardList_GET({
      tournamentId,
      categoryId,
      cbSuccess,
      cbFail,
    });

    function cbSuccess(res) {
      setScores(res)
      setLoadingScores(false);
    }

    function cbFail(e) {
      console.log('Fail', e);
      setLoadingScores(false);
    }
  }

  function handleSelectCategory(newCategory) {
    setSelectedCategory(newCategory);
    handleClearSearch();
    fetchScores(newCategory?.id);
  }

  function handleClearSearch() {
    if (searchRef.current) {
      searchRef.current.value = '';
    }
    setSearchTerm('');
  }

  useEffect(() => {
    fetchScores(selectedCategory?.id);
  }, []);

  return (
      <TournamentsScoreBoardContext.Provider value={{
        tournamentId,
        categories,
        tournamentSport,
        scores,
        loadingScores,
        selectedCategory,
        handleSelectCategory,
        searchRef,
        searchTerm,
        setSearchTerm,
        handleClearSearch,
      }}>
        {children}
      </TournamentsScoreBoardContext.Provider>
  );
}

export function useTournamentsScoreboardContext() {
  const {
    tournamentId,
    tournamentSport,
    categories,
    scores,
    loadingScores,
    selectedCategory,
    handleSelectCategory,
    searchRef,
    searchTerm,
    setSearchTerm,
    handleClearSearch,
  } = useContext(TournamentsScoreBoardContext);

  return {
    tournamentId,
    tournamentSport,
    categories,
    scores,
    loadingScores,
    selectedCategory,
    handleSelectCategory,
    searchRef,
    searchTerm,
    setSearchTerm,
    handleClearSearch,
  };
}

export default TournamentsScoreBoardProvider;
