import {Chip} from '@mui/material';
import {useTranslate} from '@tolgee/react';

function TournamentStateChip({state, size = 'small'}) {

  const {t} = useTranslate();

  let label;
  let color;

  if (state === 'not_started') {
    label = t('global.types.status.not_started')
    color = 'default'
  }

  if (state === 'in_progress') {
    label =  t('global.types.status.in_progress');
    color = 'primary'
  }

  if (state === 'completed') {
    label =  t('global.types.status.complete');
    color = 'success'
  }

  return (<Chip
      label={label}
      color={color}
      size={size}
  />);

}

export default TournamentStateChip;
