import {Box, CircularProgress, Grid} from '@mui/material';
import PageHeader from '../../../components/_Global/Page/PageHeader';
import BreadcrumbsStatic
  from '../../../components/_Global/Breadcrumbs/BreadcrumbsStatic';
import {useTranslate} from '@tolgee/react';
import ContainerFixed
  from '../../../components/_Global/Containers/ContainerFixed';
import PageCard from '../../../components/_Global/Page/PageCard/PageCard';
import PageCardContent
  from '../../../components/_Global/Page/PageCard/PageCardContent';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import TournamentsAddEditForm
  from '../../../components/Tournaments/AddEdit/TournamentsAddEditForm';
import TournamentsCourtsAvailabilityListProvider
  from '../../../components/Tournaments/CourtsAvailabilityList/TournamentsCourtsAvailabilityListProvider';
import TournamentsCourtsAvailabilityList
  from '../../../components/Tournaments/CourtsAvailabilityList/TournamentsCourtsAvailabilityList';
import {useSelectedClub} from '../../../hooks/clubs/useSelectedClub';
import ProviderClubRatingsSystem
  from '../../../providers/ProviderClubRatingsSystem';
import {useEffect, useMemo, useState} from 'react';
import {clubRatingsSystem_GET} from '../../../api/club-ratings';
import {useParams} from 'react-router-dom';
import {
  tournamentsBookings_GET,
  tournamentsSingle_GET,
} from '../../../api/tournaments';
import dayjs from 'dayjs';
import {
  recurBookingsValidate_POST,
} from '../../../vendor/redux/actions/bookings-recurring';
import {useDispatch} from 'react-redux';

function PageTournamentsAddEdit({mode = 'add' || 'edit'}) {

  const {t} = useTranslate();
  const dispatch = useDispatch();

  const {tournamentId} = useParams();

  const selectedClub = useSelectedClub();
  const clubId = selectedClub?.id;

  const [courts, setCourts] = useState([]);
  const [isLoadingCourts, setIsLoadingCourts] = useState(false);

  const formProps = useForm({
    defaultValues: {
      clubPageVisibility: '',
      tournamentName: '',
      tournamentType: '',
      description: '',
      tournamentFormat: '',
      scoringFormat: '',
      scoringFormatCustom: '',
      tournamentCategories: [],
      categoriesData: [],
      // participantCount: '',
      // participantPrice: '',
      tournamentOrganizers: [],
      ratingRangeToggled: true,
      ratingRange: null,
      assignCourtsToggled: true,
      sport: '',
      courts: [],
      tournamentStartDate: null,
      tournamentEndDate: null,
      tournamentStartTime: null,
      tournamentEndTime: null,
      registrationDeadlineDate: null,
      registrationDeadlineTime: null,
      internalNotes: '',
      galleryImages: [],
      attachments: [],
    },
  });

  const {watch, reset, control, getValues} = formProps;

  const startDate = watch('tournamentStartDate');
  const endDate = watch('tournamentEndDate');
  const startTime = watch('tournamentStartTime');
  const endTime = watch('tournamentEndTime');
  const selectedCourts = useWatch({name: 'courts', control});

  const [formDefaults, setFormDefaults] = useState(mode === 'add');
  const [tournament, setTournament] = useState();

  const status = tournament?.status;
  const state = tournament?.state;
  const categories = tournament?.tournamentCategories;

  const [clubRatingSystem, setClubRatingSystem] = useState();

  const pageBreadcrumbs = [
    {
      label: t('tournaments.create.breadcrumbs.tournaments'),
      route: '/tournaments',
    },
    {
      label: mode === 'add' ?
          t('tournaments.create.breadcrumbs.create') :
          t('tournaments.create.breadcrumbs.edit')
      ,
    },
  ];

  const fieldRules = useMemo(() => {
    return {
      tournamentType: {
        disabled: (() => {
          if (status === 'active') return true;
        })(),
      },
      sport: {
        disabled: (() => {
          if (status === 'active') return true;
        })(),
      },
      tournamentFormat: {
        disabled: (() => {
          if (status === 'active') return true;
        })(),
      },
      scoringFormat: {
        disabled: (() => {
          if (status === 'active') return true;
        })(),
      },
      scoringFormatCustom: {
        disabled: (() => {
          if (status === 'active') return true;
        })(),
      },
      tournamentCategories: {
        disabled: (() => {
          if (status === 'active') return true;
        })(),
      },
      tournamentStartDate: {
        disabled: (() => {
          if (status === 'active') return true;
        })(),
      },
      tournamentEndDate: {
        disabled: (() => {
          if (status === 'active') return true;
        })(),
      },
      tournamentStartTime: {
        disabled: (() => {
          if (status === 'active') return true;
        })(),
      },
      tournamentEndTime: {
        disabled: (() => {
          if (status === 'active') return true;
        })(),
      },
    };
  }, [mode, status]);

  function fetchClubRatingSystem() {

    clubRatingsSystem_GET({clubId, sports: '', cbFail, cbSuccess});

    function cbSuccess(res) {
      setClubRatingSystem(res);
    }

    function cbFail(e) {
      setClubRatingSystem({});
    }
  }

  function fetchCourtsAvailability(
      startDate, endDate, startTime, endTime, selectedCourts) {

    setIsLoadingCourts(true);

    dispatch(recurBookingsValidate_POST(
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD'),
        startTime.format('HH:mm:ss'),
        endTime.format('HH:mm:ss'),
        selectedCourts.join(','),
        'daily',
        startDate.format('dddd'),
        null,
        '',
        '',
        cbSuccess,
        cbFail,
    ));

    function cbSuccess(res) {
      const courts = res?.data?.data?.dates;
      setCourts(courts);
      setIsLoadingCourts(false);
    }

    function cbFail(e) {
      setCourts([]);
      setIsLoadingCourts(false);
    }
  }

  function fetchExistingCourts() {

    setIsLoadingCourts(true);

    tournamentsBookings_GET({tournamentId, cbSuccess, cbFail});

    function cbSuccess(res) {
      setCourts(res);
      setIsLoadingCourts(false);
    }

    function cbFail(e) {
      setIsLoadingCourts(false);
    }
  }

  function fetchFormDefaults() {

    tournamentsSingle_GET({tournamentId, cbSuccess, cbFail});

    function cbSuccess(res) {

      setTournament(res);

      const {
        id,
        status,
        courtBookings,
        name,
        description,
        startDate,
        endDate,
        startTime,
        endTime,
        sport,
        enablePlayerSide,
        format,
        internalNotes,
        scoringFormat,
        scoringFormatValue,
        registrationDeadlineDate,
        registrationDeadlineTime,
        type,
        tournamentCourts,
        tournamentAssets,
        tournamentCategories,
        tournamentOrganisers,
      } = res;

      //Mark assets as existing
      const existingAssets = tournamentAssets?.map((a) => {
        return {
          ...a,
          existing: true,
        };
      });

      const existingImages = existingAssets?.filter(a => a.type === 'image');

      const existingPdfs = existingAssets?.filter(a => a.type === 'pdf');

      const ratingRangeEnabled = (() => {
        const min = +tournamentCategories?.[0]?.minRating;
        const max = +tournamentCategories?.[0]?.maxRating;
        if (typeof min === 'number' && typeof max === 'number') {
          return true;
        }
      })();

      const ratingRange = (() => {
        const min = +tournamentCategories?.[0]?.minRating;
        const max = +tournamentCategories?.[0]?.maxRating;
        if (typeof min === 'number' && typeof max === 'number') {
          return [min, max];
        }
      })();

      const categories = tournamentCategories?.map((c) => c.type);

      const categoriesData = tournamentCategories?.map((c) => {
        return {
          category: c.type,
          participantCount: c.participantCount,
          participantPrice: c.price,
        };
      });

      const organizers = tournamentOrganisers?.map((o) => {
        const admin = o.clubAdmin;
        return {
          'id': admin?.id,
          'firstName': admin?.firstName,
          'lastName': admin?.lastName,
          'email': admin?.email,
        };
      });

      const assignCourtsEnabled = (() => {
        return tournamentCourts?.length > 0;
      })();

      const courts = tournamentCourts?.map((c) => c?.court?.id);

      const defaults = {
        clubPageVisibility: enablePlayerSide,
        tournamentName: name,
        tournamentType: type,
        description: description,
        tournamentFormat: format,
        scoringFormat: scoringFormat,
        scoringFormatCustom: scoringFormatValue,
        tournamentCategories: categories,
        categoriesData: categoriesData,
        tournamentOrganizers: organizers,
        ratingRangeToggled: ratingRangeEnabled,
        ratingRange: ratingRange,
        assignCourtsToggled: assignCourtsEnabled,
        sport: sport,
        courts: courts,
        tournamentStartDate: dayjs(startDate),
        tournamentEndDate: dayjs(endDate),
        tournamentStartTime: dayjs(startTime, 'HH:mm:ss'),
        tournamentEndTime: dayjs(endTime, 'HH:mm:ss'),
        registrationDeadlineToggled: !!registrationDeadlineDate,
        registrationDeadlineDate: registrationDeadlineDate ?
            dayjs(registrationDeadlineDate) :
            null,
        registrationDeadlineTime: registrationDeadlineTime ?
            dayjs(registrationDeadlineTime, 'HH:mm:ss') :
            null,
        internalNotes: internalNotes,
        galleryImages: existingImages,
        attachments: existingPdfs,
      };

      reset(defaults);
      setFormDefaults(defaults);

    }

    function cbFail(e) {
      console.log('Fail', e);
    }

  }

  // Fetch courts availability in add mode
  useEffect(() => {
    if (mode === 'add') {
      const timeOut = setTimeout(() => {
        const canFetchCourts = startDate && endDate && startTime && endTime &&
            selectedCourts?.length > 0;

        if (canFetchCourts) {
          fetchCourtsAvailability(startDate, endDate, startTime, endTime,
              selectedCourts);
        } else {
          setIsLoadingCourts(false);
          setCourts([]);
        }

      }, 250);
      return () => clearTimeout(timeOut);
    }

  }, [mode, startDate, endDate, startTime, endTime, selectedCourts]);

  // Fetch existing courts in edit mode
  useEffect(() => {
    if (mode === 'edit') {
      fetchExistingCourts();
    }
  }, []);

  useEffect(() => {
    fetchClubRatingSystem();
  }, [clubId]);

  useEffect(() => {
    if (mode === 'edit') {
      fetchFormDefaults();
    }
  }, [mode]);

  if (!formDefaults) {
    return (
        <Box
            sx={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
        >
          <CircularProgress/>
        </Box>
    );
  }

  return (
      <FormProvider
          {...formProps}
          mode={mode}
          status={status}
          state={state}
          fieldRules={fieldRules}
          formDefaults={formDefaults}
          categories={categories}
      >
        <TournamentsCourtsAvailabilityListProvider
            courts={courts}
            isLoadingCourts={isLoadingCourts}
            type={mode}
        >
          <ContainerFixed maxWidth={'1400px'}>
            <PageHeader
                breadcrumbsSlot={<BreadcrumbsStatic
                    options={pageBreadcrumbs}/>}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <PageCard>
                  <ProviderClubRatingsSystem
                      clubRatingsSystem={clubRatingSystem}
                  >
                    <TournamentsAddEditForm/>
                  </ProviderClubRatingsSystem>
                </PageCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <PageCard
                    sx={{
                      position: 'sticky',
                      top: `calc(20px)`,
                    }}
                >
                  <PageCardContent>
                    <TournamentsCourtsAvailabilityList/>
                  </PageCardContent>
                </PageCard>
              </Grid>
            </Grid>
          </ContainerFixed>
        </TournamentsCourtsAvailabilityListProvider>
      </FormProvider>
  );
}

export default PageTournamentsAddEdit;
