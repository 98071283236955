import {Controller, useFormContext} from 'react-hook-form';
import {InputAdornment, TextField} from '@mui/material';
import {LockOutlined} from '@mui/icons-material';
import { useEffect, useRef } from 'react';

function RHFNumberInput({
                          name,
                          label,
                          placeholder,
                          requiredRules = {},
                          validateRules = {},
                          variant = 'outlined' || 'filled' || 'standard',
                          size = 'medium' || 'small',
                          maxNum = 99999999999999,
                          minNum = 0,
                          triggerOnChange = false,
                          numDecimals = 10,
                          InputLabelProps = {},
                          startAdornment,
                          endAdornment,
                          autoFocus = false,
                          disabled = false,
                          onKeyDown = () => {
                          },
                          onBlur = () => {
                          },
                          disallowSpecialChars = false,
                          error,
                        }) {

  const {control, formState: {errors}, trigger} = useFormContext();
  const fieldParts = name.split('.');
  const dynamicError = fieldParts.reduce((obj, key) => obj?.[key], errors);

  const inputRef = useRef(null);

  useEffect(() => {
    const handleWheel = (e) => {
      e.preventDefault();
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  return (
      <Controller
          control={control}
          name={name}
          rules={{
            required: requiredRules,
            validate: validateRules,
          }}
          render={({field, fieldState, formState}) => {
            return (
                <TextField
                  inputRef={inputRef} // Attach the ref to the TextField
                    disabled={disabled}
                    fullWidth
                    type={'number'}
                    placeholder={placeholder}
                    name={name}
                    autoFocus={autoFocus}
                    label={label}
                    variant={variant}
                    onKeyDown={(e) => {
                      onKeyDown(e);
                      if (disallowSpecialChars) {
                        if (
                            ['e', '+', '-'].includes(e.key) ||
                            (e.key === '.' && field.value?.includes('.'))
                        ) {
                          e.preventDefault();
                        }
                      }
                    }}
                    size={size}
                    error={errors && dynamicError}
                    helperText={dynamicError?.message}
                    InputLabelProps={{
                      ...InputLabelProps,
                    }}
                    InputProps={{
                      startAdornment: startAdornment,
                      endAdornment:
                          disabled ?
                              <InputAdornment position="end">
                                <LockOutlined color={'disabled'}/>
                              </InputAdornment> :
                              endAdornment ?
                                  <InputAdornment position="end">
                                    {endAdornment}
                                  </InputAdornment> :
                                  '',
                    }}
                    {...field}
                    onChange={(e) => {

                      const value = e.target.value;

                      const regex = new RegExp(`^\\d*\\.?\\d{0,${numDecimals}}$`);

                      if (regex.test(value)) {

                        const numericValue = parseFloat(value);

                        if (!isNaN(numericValue)) {
                          if (numericValue >= minNum && numericValue <=
                              maxNum) {
                            field.onChange(value);
                          }
                        } else {
                          field.onChange(value);
                        }

                      }

                      if (triggerOnChange) {
                        trigger(name);
                      }

                    }}
                    onBlur={onBlur}
                />
            );
          }}
      />

  );

}

export default RHFNumberInput;
