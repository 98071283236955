import {Box} from '@mui/material';

function PageTournamentsDetailTeams() {
  return (
      <Box>
        Todo Tournaments detail Teams
      </Box>
  );
}

export default PageTournamentsDetailTeams;
