import {useTranslate} from '@tolgee/react';
import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useMemo, useState} from 'react';
import {Box} from '@mui/system';
import PageHeader from '../../../components/_Global/Page/PageHeader';
import {Button, CircularProgress, Stack, Tab, Tabs} from '@mui/material';
import {TabPanel} from '../../../components/_Global/Tabs/TabPanel';
import PageTournamentsDetailTournamentDetail
  from './PageTournamentsDetailTournamentDetail';
import PageTournamentsDetailRegistrations
  from './PageTournamentsDetailRegistrations';
import PageTournamentsDetailMatches from './PageTournamentsDetailMatches';
import PageTournamentsDetailScoreBoard from './PageTournamentsDetailScoreBoard';
import BreadcrumbsStatic
  from '../../../components/_Global/Breadcrumbs/BreadcrumbsStatic';
import {
  tournamentsSingle_GET,
  tournamentsStatusUpdate_PATCH,
} from '../../../api/tournaments';
import TournamentStatusChip
  from '../../../components/Tournaments/TournamentStatusChip';
import DialogConfirmation from '../../../dialogs/DialogConfirmation';
import {useSnackbar} from 'notistack';
import PageTournamentsDetailTeams from './PageTournamentsDetailTeams';
import ContainerFixed
  from '../../../components/_Global/Containers/ContainerFixed';
import {useSelectedClub} from '../../../hooks/clubs/useSelectedClub';
import {ERRORS} from '../../../constants/errors';
import {clubRatingsSystem_GET} from '../../../api/club-ratings';
import TournamentStateChip
  from '../../../components/Tournaments/TournamentStateChip';

function PageTournamentsDetail({initialTabValue}) {

  const {t} = useTranslate();
  const navigateTo = useNavigate();
  const {tournamentId} = useParams();
  const {enqueueSnackbar} = useSnackbar();

  const selectedClub = useSelectedClub();
  const clubId = selectedClub?.id;

  const [tabValue, setTabValue] = useState(initialTabValue);
  const [tournament, setTournament] = useState();
  const [clubRatingSystem, setClubRatingSystem] = useState();

  const playerPublicUrl = process.env.REACT_APP_PLAYER_APP_URL;

  const status = tournament?.status;
  const categories = tournament?.tournamentCategories;
  const state = tournament?.state;

  const loading = !tournament || !clubRatingSystem;

  const breadcrumbs = [
    {
      label: t('tournaments.detail.breadcrumbs.allTournaments'),
      route: `/tournaments`,
    },
    {
      label: tournament?.name,
      slot: <Stack direction={'row'} spacing={1}>
        <TournamentStatusChip status={status}/>
        <TournamentStateChip state={state}/>
      </Stack>,
    },
  ];

  const actions = [
    {
      label: t('tournaments.detail.actions.closeTournament'),
      color: 'error',
      variant: 'outlined',
      visible: status !== 'closed',
      onClick: (setIsLoading, setIsConfirmOpen) => {
        handleStatusUpdate('closed', setIsLoading, setIsConfirmOpen);
      },
      confirmation: {
        title: t('tournaments.detail.actions.close.confirm.title'),
        content: t('tournaments.detail.actions.close.confirm.content'),
      },
    },
    {
      label: t('tournaments.detail.actions.publicView'),
      color: 'inherit',
      variant: 'outlined',
      visible: status === 'active',
      onClick: () => {
        const url = `${playerPublicUrl}/club/${clubId}/tournaments/tournament-detail?tournamentId=${tournamentId}`;
        window.open(url, '_blank');
      },
    },
    {
      label: t('tournaments.detail.actions.makeActive'),
      color: 'inherit',
      variant: 'contained',
      visible: status === 'in_active',
      onClick: (setIsLoading, setIsConfirmOpen) => {
        handleStatusUpdate('active', setIsLoading, setIsConfirmOpen);
      },
      confirmation: {
        title: t('tournaments.detail.actions.makeActive.confirm.title'),
        content: t('tournaments.detail.actions.makeActive.confirm.content'),
      },
    },
    {
      label: t('tournaments.detail.actions.makeInactive'),
      color: 'error',
      variant: 'contained',
      visible: status === 'active',
      disabled: (()=>{

        if(state === 'in_progress') return true;

        if (categories){
          const activeSlots = categories?.reduce((acc, cur) => {
            return acc + cur.activeSlot;
          },0);
          if (activeSlots > 0) {
            return true
          }
        }

      })(),
      onClick: (setIsLoading, setIsConfirmOpen) => {
        handleStatusUpdate('in_active', setIsLoading, setIsConfirmOpen);
      },
      confirmation: {
        title: t('tournaments.detail.actions.makeInactive.confirm.title'),
        content: t('tournaments.detail.actions.makeInactive.confirm.content'),
      },
    },
  ];

  const tabs = useMemo(() => {

    const tabs = [
      {
        label: t('tournaments.detail.tournamentDetail'),
        ViewComponent: <PageTournamentsDetailTournamentDetail
            details={tournament}
            clubRatingSystem={clubRatingSystem}
        />,
        enabled: true,
      },
      {
        label: t('tournaments.detail.registrations'),
        enabled: tournament?.status === 'active',
        ViewComponent: <PageTournamentsDetailRegistrations
            tournamentDetails={tournament}
            fetchTournament={fetchTournament}
        />,

      },
      {
        label: t('tournaments.detail.teams'),
        enabled: false,
        ViewComponent: <PageTournamentsDetailTeams/>,
      },
      {
        label: t('tournaments.detail.matches'),
        enabled: tournament?.status === 'active',
        ViewComponent: <PageTournamentsDetailMatches
            tournamentDetails={tournament}
        />,
      },
      {
        label: t('tournaments.detail.scoreBoard'),
        enabled: tournament?.status === 'active',
        ViewComponent: <PageTournamentsDetailScoreBoard
            tournamentDetails={tournament}
        />,
      },
    ];

    const visibleTabs = tabs.map((tab, index) => {
      if (tab.enabled) {
        return {
          ...tab,
          index: index,
        };
      }
    }).filter(Boolean);

    return visibleTabs;

  }, [tournament]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    const root = `/tournaments/detail/${tournamentId}`;
    switch (newValue) {
      case 0:
        return navigateTo(`${root}/tournament-detail`);
      case 1:
        return navigateTo(`${root}/registrations`);
      case 2:
        return navigateTo(`${root}/teams`);
      case 3:
        return navigateTo(`${root}/matches`);
      case 4:
        return navigateTo(`${root}/scoreboard`);
    }
  };

  function handleStatusUpdate(newStatus, setIsLoading, setIsConfirmOpen) {

    setIsLoading(true);

    tournamentsStatusUpdate_PATCH({
      tournamentId,
      status: newStatus,
      cbSuccess,
      cbFail,
    });

    function cbSuccess(res) {
      // Reset Tab + Route
      setTabValue(0);
      navigateTo(`/tournaments/detail/${tournamentId}/tournament-detail`);
      //Fetch Tournament
      fetchTournament();
      setIsLoading(false);
      setIsConfirmOpen(false);
      enqueueSnackbar('Status updated', {variant: 'success'});
    }

    function cbFail(e) {
      const err = e?.response?.data?.data || ERRORS.NETWORK_ERROR;
      setIsLoading(false);
      enqueueSnackbar(t(`global.errors.${err}`), {variant: 'error'});
    }
  }

  function fetchTournament() {

    tournamentsSingle_GET(
        {tournamentId, includeBooking: false, cbSuccess, cbFail});

    function cbSuccess(res) {
      setTournament(res);
    }

    function cbFail(e) {
    }
  }

  function fetchClubRatingSystem() {

    clubRatingsSystem_GET({clubId, sports: '', cbFail, cbSuccess});

    function cbSuccess(res) {
      setClubRatingSystem(res);
    }

    function cbFail(e) {
      setClubRatingSystem({});
    }
  }

  useEffect(() => {
    fetchClubRatingSystem();
    fetchTournament();
  }, [tournamentId]);

  if (loading) {
    return <Box sx={{
      height: '100vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <CircularProgress/>
    </Box>;
  }

  return (
      <Box>
        <PageHeader
            breadcrumbsSlot={
              <BreadcrumbsStatic
                  options={breadcrumbs}
              />
            }
            actionsSlot={
              actions?.map(
                  (action, index) => {

                    if (!action.visible) return;

                    return (
                        <ActionButton
                            key={index}
                            color={action.color}
                            variant={action.variant}
                            label={action.label}
                            onClick={action.onClick}
                            confirmation={action.confirmation}
                            disabled={action.disabled}
                        />
                    );
                  })
            }
        />
        <Tabs value={tabValue} onChange={handleTabChange} sx={{mb: 3}}>
          {
            tabs?.map((tab) => (
                <Tab
                    key={tab.index}
                    label={tab.label}
                    value={tab.index}
                />
            ))
          }
        </Tabs>
        <ContainerFixed maxWidth={'1400px'}>
          {
            tabs?.map((tab) => (
                <TabPanel key={tab.index} index={tab.index}
                          tabValue={tabValue}>
                  {tab.ViewComponent}
                </TabPanel>
            ))
          }
        </ContainerFixed>
      </Box>

  );
}

function ActionButton({color, variant, label, disabled, confirmation, onClick}) {

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  let loaderColor;

  if (variant === 'contained') {
    loaderColor = '#fff';
  }

  if (variant === 'outlined') {
    loaderColor = color;
  }

  return (
      <>
        <Button
            disabled={disabled}
            color={color}
            variant={variant}
            onClick={() => {
              if (confirmation) {
                return setIsConfirmOpen(true);
              }
              onClick(setIsLoading, () => {
              });
            }}
            startIcon={
                isLoading &&
                <Stack
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{
                      color: loaderColor,
                    }}
                >
                  <CircularProgress color={'inherit'} size={20}/>
                </Stack>
            }
        >
          {label}
        </Button>
        <DialogConfirmation
            isSubmitting={isLoading}
            variant={color}
            dialogTitle={confirmation?.title}
            dialogContent={confirmation?.content}
            confirmButtonLabel={label}
            isOpen={isConfirmOpen}
            handleClose={() => {
              setIsConfirmOpen(false);
            }}
            handleConfirm={() => {
              onClick(setIsLoading, setIsConfirmOpen);
            }}
        />
      </>
  );
}

export default PageTournamentsDetail;
