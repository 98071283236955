import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  Stack,
} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import DialogSection from '../../_Global/Dialogs/DialogSection';
import {
  AccountBalanceWalletOutlined,
  Add,
  CategoryOutlined,
  PeopleAltOutlined,
  RemoveCircleOutlineOutlined,
} from '@mui/icons-material';
import RHFSelectSimple from '../../_Global/Inputs/RHFSelectSimple';
import RHFRadioGroup from '../../_Global/Inputs/RHFRadioGroup';
import useDefaults from '../../../hooks/defaults/useDefaults';
import {getLocalizedNum} from '../../../utils/localization';
import useLocale from '../../../hooks/localization/useLocale';
import RHFPlayerSearch from '../../_Global/Inputs/RHFPlayerSearch';
import {LoadingButton} from '@mui/lab';
import {useSnackbar} from 'notistack';
import {tournamentsRegistrationsAdd_POST} from '../../../api/tournaments';
import {useParams} from 'react-router-dom';
import {useMemo} from 'react';
import {convertToNumber} from '../../../utils/type-coercion';

function RegistrationsAddParticipantDialog({
                                             open,
                                             onClose = () => {
                                             },
                                             onAdd,
                                             categories,
                                             registered,
                                             tournamentSport,
                                           }) {

  return (
      <Dialog open={open} onClose={onClose}>
        <DialogFormProvider
            categories={categories}
        >
          <DialogForm
              onClose={onClose}
              onAdd={onAdd}
              categories={categories}
              registered={registered}
              tournamentSport={tournamentSport}
          />
        </DialogFormProvider>
      </Dialog>

  );
}

function DialogFormProvider({categories, children}) {

  const initialCategory = categories[0]?.id;

  const formProps = useForm({
    defaultValues: {
      category: initialCategory,
      feeType: 'invite',
      teams: [
        {
          participants: [],
        },
      ],
    },
  });

  return (
      <FormProvider {...formProps}>
        {children}
      </FormProvider>
  );
}

function DialogForm({categories, tournamentSport, registered, onAdd, onClose}) {

  const {t} = useTranslate();
  const {watch} = useFormContext();

  const selectedCategoryId = watch('category');
  const selectedCategory = categories?.find((c) => c.id === selectedCategoryId);

  const registrationType = selectedCategory?.registrationType;

  const categoryMaxRegistrations = selectedCategory?.participantCount;

  const categoryRegistrations = useMemo(() => {
    return registered?.filter(
        (r) => r?.tournamentCategory?.type === selectedCategory?.type);
  }, [selectedCategory, registered]);

  const categoryRegistrationsComplete = useMemo(() => {
    return categoryRegistrations?.length >= categoryMaxRegistrations;
  }, [categoryMaxRegistrations, categoryRegistrations]);

  const categoryGender = selectedCategory?.gender;
  const categoryRatingMin = convertToNumber(selectedCategory?.minRating);
  const categoryRatingMax = convertToNumber(selectedCategory?.maxRating);

  return (
      <>
        <DialogTitle>
          {t('tournaments.detail.registrations.addParticipant.dialog.title')}
        </DialogTitle>
        <DialogContent>
          <CategorySelect
              categories={categories}
              categoryRegistrationsComplete={categoryRegistrationsComplete}
          />
          <RegistrationFeeType
              selectedCategory={selectedCategory}
              categoryRegistrationsComplete={categoryRegistrationsComplete}
          />
          <TeamSelect
              registrationType={registrationType}
              categoryRegistrationsComplete={categoryRegistrationsComplete}
              categoryRegistrations={categoryRegistrations}
              categoryMaxRegistrations={categoryMaxRegistrations}
              categoryGender={categoryGender}
              categoryRatingMin={categoryRatingMin}
              categoryRatingMax={categoryRatingMax}
              tournamentSport={tournamentSport}
              registered={registered}

          />
        </DialogContent>
        <DialogActions>
          <FormActions
              registrationType={registrationType}
              categoryRegistrationsComplete={categoryRegistrationsComplete}
              onClose={onClose}
              onAdd={onAdd}
          />
        </DialogActions>
      </>
  );
}

function CategorySelect({categories, categoryRegistrationsComplete}) {

  const {t} = useTranslate();
  const {setValue, clearErrors} = useFormContext();

  const options = categories?.map(category => {
    return {
      value: category.id,
      label: t(`tournaments.types.categories.${category.type}`),
    };
  });

  return (
      <DialogSection
          icon={CategoryOutlined}
          label={t('tournaments.detail.registrations.addParticipant.category')}
      >
        <Stack spacing={1}>
          <RHFSelectSimple
              name={'category'}
              label={t(
                  'tournaments.detail.registrations.addParticipant.selectCategory')}
              options={options}
              requiredRules={{
                value: true,
                message: t(
                    'tournaments.detail.registrations.addParticipant.selectCategory.required'),
              }}
              onChangeCallback={() => {
                const resetValue = [{participants: []}];
                setValue('teams', resetValue);
                clearErrors();
              }}

          />
          {
              categoryRegistrationsComplete &&
              <FormHelperText error>
                {t('tournaments.registrations.add.categoryRegistrations.complete')}
              </FormHelperText>
          }
        </Stack>

      </DialogSection>
  );
}

function RegistrationFeeType({
                               selectedCategory,
                               categoryRegistrationsComplete,
                             }) {

  const {t} = useTranslate();
  const {defaultCurrencySymbol} = useDefaults();
  const {locale} = useLocale();

  const price = (() => {
    let p = 0;
    if (selectedCategory) {
      p = +selectedCategory.price;
    }
    return p;
  })();

  const options = [
    {
      value: 'invite',
      label: `${defaultCurrencySymbol} ${getLocalizedNum(locale, price)}`,
    },
    {
      value: 'free',
      label: t('tournaments.registrations.fee.free'),
    },
  ];

  return (
      <DialogSection
          icon={AccountBalanceWalletOutlined}
          label={t('tournaments.detail.registrations.addParticipant.fee')}
      >
        <RHFRadioGroup
            name={'feeType'}
            options={options}
            disabled={!selectedCategory || categoryRegistrationsComplete}
        />
      </DialogSection>
  );
}

function TeamSelect({
                      registrationType,
                      categoryRegistrationsComplete,
                      categoryRegistrations,
                      categoryMaxRegistrations,
                      categoryGender,
                      categoryRatingMin,
                      categoryRatingMax,
                      registered,
                      tournamentSport,
                    }) {

  const {t} = useTranslate();
  const {watch, formState: {errors}} = useFormContext();

  const teams = watch('teams');

  const selectedParticipantIds = teams?.flatMap(
      t => t?.participants?.map(p => p.userId));

  const registeredUserIds = useMemo(() => {
    const registeredIds = (registered || [])?.map((r) => r.user?.id);
    return [...registeredIds];
  }, [registered]);

  const filteredUserIds = [
    ...(selectedParticipantIds || []),
    ...(registeredUserIds || []),
  ];

  const sectionLabel = (() => {

    let singlesLabel = t(
        'tournaments.detail.registrations.addParticipant.participants');

    let doublesLabel = t(
        'tournaments.detail.registrations.addParticipant.teams');

    let l = singlesLabel;

    if (registrationType === 'double') {
      l = doublesLabel;
    }

    return l;

  })();

  const remainingRegistrationsCount = Math.max(
      0,
      categoryMaxRegistrations -
      (categoryRegistrations?.length + selectedParticipantIds?.length),
  );

  const maxParticipants = remainingRegistrationsCount +
      selectedParticipantIds?.length;

  const allowMultipleTeams = registrationType === 'double';

  const {fields, append, remove} = useFieldArray({
    name: 'teams',
    rules: {
      required: {
        value: true,
        message: t('tournaments.registrations.teams.required'),
      },
    },
  });

  const validateRules = (() => {

    if (allowMultipleTeams) {
      return {
        required: {
          value: true,
          message: t('tournaments.addParticipants.min2Required'),
        },
        validate: {
          min2Participants: (value) => {
            if (value?.length >= 2) {
              return true;
            } else {
              return t('tournaments.addParticipants.min2Required');
            }
          },
        },
      };
    } else {
      return {
        required: {
          value: true,
          message: t('tournaments.addParticipants.required'),
        },
        validate: {},
      };
    }

  })();

  const noTeamsError = errors?.teams?.root?.message;

  return (
      <DialogSection
          icon={PeopleAltOutlined}
          label={sectionLabel}
          isLastChild={true}
      >
        <Stack rowGap={0} mb={1}>
          {
            fields?.map((field, i) => {
              return (
                  <Stack
                      direction="row"
                      alignItems={'flex-start'}
                      key={field.id}
                      columnGap={1}
                  >
                    <RHFPlayerSearch
                        name={`teams.${i}.participants`}
                        disabled={categoryRegistrationsComplete}
                        placeholder={t(
                            'tournaments.addParticipants.placeholder')}
                        maxParticipants={maxParticipants}
                        filterUserIds={filteredUserIds}
                        rules={validateRules}
                        gender={categoryGender}
                        minRating={categoryRatingMin}
                        maxRating={categoryRatingMax}
                        ratingSport={tournamentSport?.toLowerCase()}
                    />
                    {
                        allowMultipleTeams &&
                        <IconButton
                            size={'small'}
                            onClick={() => {
                              remove(i);
                            }}
                            sx={{
                              mt: 1.25,
                            }}
                        >
                          <RemoveCircleOutlineOutlined/>
                        </IconButton>
                    }
                  </Stack>

              );
            })
          }
          {
              !categoryRegistrationsComplete &&
              <>
                {/*{*/}
                {/*    remainingRegistrationsCount !== 0 &&*/}
                {/*    <FormHelperText>*/}
                {/*      {`${remainingRegistrationsCount} of ${categoryMaxRegistrations} registrations remaining`}*/}
                {/*    </FormHelperText>*/}
                {/*}*/}
                {
                    remainingRegistrationsCount === 0 &&
                    <FormHelperText error>
                      {t('tournaments.registrations.add.categoryRegistrations.complete')}
                    </FormHelperText>
                }
              </>
          }

        </Stack>
        {
            noTeamsError &&
            <FormHelperText error={true}>
              {noTeamsError}
            </FormHelperText>
        }
        {
            allowMultipleTeams &&
            <Stack direction={'row'} justifyContent={'center'}>
              <Button
                  size={'small'}
                  startIcon={<Add/>}
                  onClick={() => {
                    append({participants: []});
                  }}
              >
                Team
              </Button>
            </Stack>
        }
      </DialogSection>
  );
}

function FormActions({
                       registrationType,
                       categoryRegistrationsComplete,
                       onClose,
                       onAdd,
                     }) {

  const {enqueueSnackbar} = useSnackbar();
  const {tournamentId} = useParams();
  const {t} = useTranslate();

  const {
    handleSubmit,
    setFocus,
    formState: {isSubmitting},
  } = useFormContext();

  const submitLabel = (() => {
    let l = t('tournaments.registrations.add.addParticipants');
    if (registrationType === 'double') {
      l = t('tournaments.registrations.add.addTeams');
    }
    return l;
  })();

  function handleSubmitForm() {
    return handleSubmit(handleSubmitOnValid, handleSubmitOnInvalid)();
  }

  function handleSubmitOnValid(data) {

    const body = {
      'categoryId': data.category,
      'type': data.feeType,
      'sendEmail': true,
    };

    if (registrationType === 'double') {
      body.teams = data?.teams?.map((t) => {
        return {
          'playerA': t.participants[0].email,
          'playerB': t.participants[1].email,
        };
      });
    }

    if (registrationType === 'single') {
      body.players = data?.teams[0]?.participants?.map((p) => {
        return p.email;
      });
    }

    return tournamentsRegistrationsAdd_POST({
      tournamentId,
      body,
      cbSuccess,
      cbFail,
    });

    function cbSuccess(res) {
      onAdd();
      onClose();
      enqueueSnackbar(
          t('tournaments.registrations.addParticipants.success'),
          {variant: 'success'},
      );
    }

    function cbFail(e) {
      const err =e?.response?.data?.data;
      enqueueSnackbar(
          t(err || 'tournaments.registrations.addParticipants.fail'),
          {variant: 'error'},
      );
    }

  }

  function handleSubmitOnInvalid(errors) {
    enqueueSnackbar(t('global.validation.fail'), {
      variant: 'error',
    });
    const firstErrorField = Object.keys(errors)[0];
    if (firstErrorField) {
      setFocus(firstErrorField);
    }
  }

  return (
      <Stack direction={'row'} spacing={1}>
        <Button
            variant="outlined"
            onClick={onClose}
        >
          {t('global.buttons.actions.close')}
        </Button>
        <LoadingButton
            variant="contained"
            onClick={handleSubmitForm}
            loading={isSubmitting}
            disabled={categoryRegistrationsComplete}
        >
          <span>{submitLabel}</span>
        </LoadingButton>
      </Stack>
  );
}

export default RegistrationsAddParticipantDialog;
