import {Box, Stack} from '@mui/system';
import {useTranslate} from '@tolgee/react';
import {
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  useTournamentsCourtsAvailabilityListContext,
} from './TournamentsCourtsAvailabilityListProvider';
import {
  AddCircleOutline,
  CheckCircleOutline,
  ErrorOutline,
  HighlightOffOutlined,
  TopicRounded,
} from '@mui/icons-material';
import dayjs from 'dayjs';
import DialogConfirmation from '../../../dialogs/DialogConfirmation';
import BookingCancelNotificationFields
  from '../../Bookings/Details/BookingCancelNotificationFields';
import DialogAddBookingToExistingRecurring
  from '../../../dialogs/DialogAddBookingToExistingRecurring';
import {useState} from 'react';

function TournamentsCourtsAvailabilityList() {

  const {t} = useTranslate();

  const {
    tournament,
    courtsCount,
    setCancelBookingId,
    cancelBookingIndex,
    setCancelBookingIndex,
    showCancelBookingDialog,
    setShowCancelBookingDialog,
    cancelNotes,
    setCancelNotes,
    cancelNotifyCustomer,
    setCancelNotifyCustomer,
    isLoadingCancelBooking,
    handleCancelBooking,
  } = useTournamentsCourtsAvailabilityListContext();

  const tournamentName = tournament?.name;

  return (
      <>
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              rowGap: 2,
            }}
        >
          <CourtsAvailabilityListHeader/>
          <CourtsAvailabilityListTable/>
        </Box>
        <DialogConfirmation
            variant={'error'}
            isSubmitting={isLoadingCancelBooking}
            isOpen={showCancelBookingDialog}
            dialogTitle={'Confirm Cancel Booking'}
            dialogContent={
              <Stack>{
                  t('bookings.recurring.single.detail.confirmCancel.p1') +
                  ` ${dayjs().
                      set('date', cancelBookingIndex + 1).
                      format('Do')} ` +
                  t('bookings.recurring.single.detail.confirmCancel.p2') +
                  ` ${courtsCount} ` +
                  t('bookings.recurring.single.detail.confirmCancel.p3') +
                  ` "${tournamentName || 'Tournament name'}"`}
                {
                  <BookingCancelNotificationFields
                      setNotes={setCancelNotes}
                      sendEmail={cancelNotifyCustomer}
                      setSendEmail={setCancelNotifyCustomer}
                  />
                }
              </Stack>
            }
            handleConfirm={handleCancelBooking}
            confirmButtonLabel={'Cancel Booking'}
            handleClose={() => {
              if (!isLoadingCancelBooking) {
                setShowCancelBookingDialog(false);
              }
            }}
            handleAfterExitAnimation={() => {
              setCancelBookingId(null);
              setCancelBookingIndex(null);
            }}
        />
      </>
  );
}

function CourtsAvailabilityListHeader() {

  const {t} = useTranslate();

  let {
    type,
    tournament,
    courtsCount,
    conflictingCourtsCount,
    onAddBookingSuccess,
  } = useTournamentsCourtsAvailabilityListContext();

  const [showAddDialog, setShowAddDialog] = useState();

  let countValue;
  let conflictsValue;

  if (courtsCount !== undefined) {
    countValue = `${courtsCount}`.padStart(courtsCount > 0 ? 2 : 0, 0);
  }

  if (conflictingCourtsCount !== undefined) {
    conflictsValue = `${conflictingCourtsCount}`.padStart(
        conflictingCourtsCount > 0 ? 2 : 0, 0, 0);
  }

  return (
      <>

        <Stack direction={'row'} justifyContent={'space-between'}
               alignItems={'center'}>
          <Typography
              variant={'subtitle1'}
          >
            {t('event.totalCourts')} {countValue && `(${countValue})`}
          </Typography>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            {
                (type === 'add' || type === 'edit') &&
                <Typography
                    variant={'subtitle1'}
                    color={'error.main'}
                >
                  {t('bookings.recurring.addEdit.slots.header.conflicts')} {conflictsValue &&
                    `(${conflictsValue})`}
                </Typography>
            }
            {
                type === 'detail' &&
                <>
                  <Button
                      variant={'text'}
                      onClick={() => {
                        setShowAddDialog(true);
                      }}
                      size="large"
                      startIcon={<AddCircleOutline/>}
                  >
                    {t('bookingAdd.buttonSave')}
                  </Button>
                  <DialogAddBookingToExistingRecurring
                      open={showAddDialog}
                      handleCloseModal={() => setShowAddDialog(false)}
                      fetchRecurringBooking={onAddBookingSuccess}
                      type={'tournament'}
                      tournamentId={tournament?.id}
                      showEmail={tournament?.status === 'active'}
                      sport={tournament?.sport}
                  />
                </>
            }

          </Stack>
        </Stack>
      </>

  );
}

function CourtsAvailabilityListTable() {

  const {t} = useTranslate();
  const {type} = useTournamentsCourtsAvailabilityListContext();

  const columns = [
    {
      label: t('courtsAvailabilityList.table.column.court'),
      renderCell: (row) => {
        return row?.court?.name;
      },
    },
    {
      label: t('courtsAvailabilityList.table.column.date'),
      renderCell: (row) => {
        return dayjs(row?.date, 'YYYY-MM-DD').format('ddd MMM D, YYYY');
      },
    },
    {
      label: t('courtsAvailabilityList.table.column.time'),
      renderCell: (row) => {
        const sTime = row?.startTime;
        const eTime = row?.endTime;
        if (sTime && eTime) {
          return `${dayjs(sTime, 'HH:mm:ss').format('LT')} - ${dayjs(eTime,
              'HH:mm:ss').format('LT')}`;
        }
      },
    },
    {
      label: t('courtsAvailabilityList.table.column.status'),
      renderCell: (row) => {
        return <CourtsAvailabilityListStatusCell slot={row}/>;
      },
    },
    ...(type === 'detail' ? [
      {
        renderCell: (row, rowIndex) => {
          return <CourtsAvailabilityListCancelBookingCell
              row={row}
              rowIndex={rowIndex}
          />;
        },
      },
    ] : []),

  ];

  const {
    courts,
    isLoadingCourts,
  } = useTournamentsCourtsAvailabilityListContext();

  return (
      <Stack>
        <Table sx={{height: '100%', width: '100%'}}>
          <CourtsAvailabilityListTableHeader columns={columns}/>
          {
              !isLoadingCourts && courts?.length > 0 &&
              <CourtsAvailabilityListTableBody columns={columns}/>
          }
        </Table>
        {
            isLoadingCourts &&
            <CourtsAvailabilityListTableLoading/>
        }
        {
            !isLoadingCourts && (!courts || courts?.length === 0) &&
            <CourtsAvailabilityListTableEmpty/>
        }
      </Stack>

  );
}

function CourtsAvailabilityListTableHeader({columns}) {
  return (
      <TableHead>
        <TableRow>
          {
            columns?.map((column, index) => {
              return (
                  <TableCell
                      key={index}
                      sx={{
                        py: 2,
                        px: 2,
                        fontWeight: 500,
                        fontSize: '14px',
                      }}
                  >
                    {column.label}
                  </TableCell>
              );
            })
          }
        </TableRow>
      </TableHead>
  );
}

function CourtsAvailabilityListTableBody({columns}) {

  let {courts} = useTournamentsCourtsAvailabilityListContext();

  return (
      <TableBody>
        {
          courts?.map((row, rowIndex) => {
            return (
                <TableRow key={rowIndex}>
                  {
                    columns?.map((column, columnIndex) => {
                      const renderCell = column?.renderCell;
                      return (
                          <TableCell
                              key={columnIndex}
                              sx={{px: 2, py: 2}}
                          >
                            {renderCell && renderCell(row, rowIndex)}
                          </TableCell>
                      );
                    })
                  }
                </TableRow>
            );
          })
        }
      </TableBody>
  );
}

function CourtsAvailabilityListTableEmpty() {

  const theme = useTheme();
  const {t} = useTranslate();
  let {type} = useTournamentsCourtsAvailabilityListContext();

  return (
      <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            paddingTop: '56px',
            paddingBottom: '80px',
          }}
      >
        <Stack spacing={1} alignItems={'center'}>
          <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                padding: '12px',
                borderRadius: '100%',
                backgroundColor: theme.palette.grey[300],
              }}
          >
            <TopicRounded color={'disabled'}/>
          </Box>
          {
              (type === 'detail' || type === 'edit') &&
              <Typography
                  color={'text.secondary'}
              >
                No Court Bookings
              </Typography>
          }
          {
              type === 'add' &&
              <Typography
                  textAlign={'center'}
                  color={'text.secondary'}
                  maxWidth={'392px'}
              >
                {t('event.courtMessage')}
                <strong> {t('bookingAdd.court')}</strong>, <strong>{t(
                  'bookings.list.table.column.label.startDate')}
              </strong>, <strong>{t(
                  'addEvent.field.startTime')}</strong>, <strong>{t(
                  'bookings.list.table.column.label.endDate')}</strong> & <strong>{t(
                  'addEvent.field.endTime')}</strong>
              </Typography>
          }
        </Stack>

      </Box>
  );
}

function CourtsAvailabilityListTableLoading() {
  return (
      <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            paddingTop: '56px',
            paddingBottom: '80px',
          }}
      >
        <CircularProgress/>
      </Box>
  );

}

function CourtsAvailabilityListStatusCell({slot}) {

  const theme = useTheme();
  const {status, msg} = slot;

  let label;

  if (status === 'fail') label = 'Conflict';
  if (status === 'pass') label = 'Available';

  return (
      <Tooltip
          disableHoverListener={status !== 'fail'}
          title={msg}
          placement={'top'}
          arrow
          sx={{
            cursor: status === 'fail' ? 'pointer' : '',
          }}
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: theme.palette.error.main,
                color: '#ffffff',
                fontSize: '13px',
                padding: '6px 12px',
                '.MuiTooltip-arrow': {
                  '&:before': {
                    backgroundColor: theme.palette.error.main,
                  },
                },
              },
            },
          }}
      >
        <Stack direction={'row'} spacing={.5} alignItems={'center'}>
          {status === 'fail' &&
              <ErrorOutline color={'error'} fontSize={'small'}/>}
          {status === 'pass' &&
              <CheckCircleOutline color={'success'} fontSize={'small'}/>}
          <Typography
              variant={'body2'}
              color={'text.primary'}
              textTransform={'capitalize'}
          >
            {label}
          </Typography>
        </Stack>
      </Tooltip>
  );
}

function CourtsAvailabilityListCancelBookingCell({row, rowIndex}) {

  const {
    setShowCancelBookingDialog,
    setCancelBookingIndex,
    setCancelBookingId,
  } = useTournamentsCourtsAvailabilityListContext();

  return (
      <IconButton
          size="small"
          onClick={() => {
            setCancelBookingId(row.id);
            setCancelBookingIndex(rowIndex);
            setShowCancelBookingDialog(true);
          }}
      >
        <HighlightOffOutlined/>
      </IconButton>
  );
}

export default TournamentsCourtsAvailabilityList;



