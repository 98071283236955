import {Stack, Typography} from '@mui/material';
import {TimePicker} from './TimePicker';
import dayjs from 'dayjs';
import {useTranslate} from '@tolgee/react';

function TimeRangePicker({
  startTime,
  endTime,
  minTime = dayjs().startOf('day'),
  maxTime = dayjs().endOf('day'),
  minInterval = 30,
  timeInterval = 30,
  startTimeLabel,
  endTimeLabel,
  onChangeStartTime,
  onChangeEndTime,
  isDisabled = false,
  startTimeError,
  endTimeError,
  isEditBooking = false,
}) {

  const {t} = useTranslate();

  if (!startTimeLabel) {
    startTimeLabel = t('global.components.timeRangePicker.label.startTime');
  }

  if (!endTimeLabel) {
    endTimeLabel = t('global.components.timeRangePicker.label.endTime');
  }

  const minStart = minTime;

  let maxStart; // maxtime - 1 interval
  if (maxTime.format('HH:mm') === '23:59' && minInterval === 60) {
    maxStart = maxTime.subtract(59, 'minute');
  } else if (maxTime.format('HH:mm') === '23:59' && minInterval === 45) {
    maxStart = maxTime.subtract(44, 'minute');
  } else {
    maxStart = maxTime.subtract(minInterval, 'minute');
  }

  let minEnd; // startTime + 1 interval
  if (startTime) {
    if (startTime.format('HH:mm') === '23:30' ) {
      minEnd = startTime.add(29, 'minute');
    } else if (startTime.format('HH:mm') === '23:00' ) {
      minEnd = startTime.add(30, 'minute');
    }  else if (startTime.format('HH:mm') === '23:00' && minInterval === 15 ) {
      minEnd = startTime.add(45, 'minute');
    }else if (startTime.format('HH:mm') === '23:15'  ) {
      minEnd = startTime.add(44, 'minute');
    } else {
      minEnd = startTime.add(minInterval, 'minute');
    }
  } else {
    minEnd = minTime.add(minInterval, 'minute');
  }

  const maxEnd = maxTime;

  function handleChangeStartTime(newStartTime) {
    onChangeStartTime(newStartTime);
    
    if (endTime) {

      const isSame = newStartTime.isSame(endTime);
      const isAfter = newStartTime.isAfter(endTime);
      const bookingInterval = endTime.diff(startTime, 'minutes');
     
      if (isSame || isAfter) {
        
        if (newStartTime.format('HH:mm') === '23:00' && timeInterval === 15) {
          onChangeEndTime(newStartTime.add(45, 'minute'));
        }
        else if (newStartTime.format('HH:mm') === '23:00' && timeInterval === 30) {
          onChangeEndTime(newStartTime.add(30, 'minute'));
        }
        else if (newStartTime.format('HH:mm') === '23:30' && timeInterval === 30 ) {
          onChangeEndTime(newStartTime.add(29, 'minute'));
        }
        else if (newStartTime.format('HH:mm') === '23:15' && timeInterval === 15) {
          onChangeEndTime(newStartTime.add(30, 'minute'));
        }
        else if (newStartTime.format('HH:mm') === '23:45' && timeInterval === 15) {

          onChangeEndTime(newStartTime.add(14, 'minute'));
        }
        else if(isEditBooking){
           onChangeEndTime(newStartTime.add(timeInterval, 'minute'));
        }
        // else if(!isRecurrAndBookings) {
        //   onChangeEndTime(newStartTime.add(minInterval, 'minute'));
        // }
        else {
          onChangeEndTime(newStartTime.add(30, 'minute'));

        }
      }
      if (
          newStartTime.add(timeInterval,'minute').isSame(endTime) ||
          newStartTime.add(timeInterval,'minute').isAfter(endTime)
      ){
         
        if (newStartTime.format('HH:mm') === '23:30' && timeInterval === 30 ) {
          onChangeEndTime(newStartTime.add(29, 'minute'));
        }
        else if (newStartTime.format('HH:mm') === '23:45' && timeInterval === 15) {
          onChangeEndTime(newStartTime.add(14, 'minute'));
        }
        else if (newStartTime.format('HH:mm') === '23:15' && timeInterval === 15) {
          onChangeEndTime(newStartTime.add(44, 'minute'));
        }
        else if (newStartTime.format('HH:mm') !== '23:00' && isEditBooking) {

          onChangeEndTime(newStartTime.add(bookingInterval, 'minute')); 
        }

      }else if(isEditBooking) { 
        onChangeEndTime(newStartTime.add(bookingInterval, 'minute'));
      }

    }
  }

  function handleChangeEndTime(date) {
    onChangeEndTime(date);
  }

  function getColor() {
    if (isDisabled) {
      return 'text.disabled';
    }
    if (startTimeError || endTimeError) {
      return 'error.main';
    }

    return 'primary.main';
  }

return <Stack direction={'row'} alignItems={'center'} spacing={.5}>
    <TimePicker
        minTime={minStart}
        maxTime={maxStart}
        disabled={isDisabled}
        label={startTimeLabel}
        time={startTime}
        onChange={handleChangeStartTime}
        error={startTimeError}
        showErrorMsg={false}
        timeInterval={timeInterval}
    />
    <Typography color={getColor()}>-</Typography>
    <TimePicker
        minTime={minEnd}
        maxTime={maxEnd}
        disabled={isDisabled}
        time={endTime}
        label={endTimeLabel}
        onChange={handleChangeEndTime}
        error={endTimeError}
        showErrorMsg={false}
        timeInterval={timeInterval}
    />
  </Stack>;

}

export default TimeRangePicker;
