import {Grid} from '@mui/material';
import PageCard from '../../../components/_Global/Page/PageCard/PageCard';
import PageCardContent
  from '../../../components/_Global/Page/PageCard/PageCardContent';
import TournamentsCourtsAvailabilityListProvider
  from '../../../components/Tournaments/CourtsAvailabilityList/TournamentsCourtsAvailabilityListProvider';
import TournamentsCourtsAvailabilityList
  from '../../../components/Tournaments/CourtsAvailabilityList/TournamentsCourtsAvailabilityList';
import TournamentDetailDetails
  from '../../../components/Tournaments/Detail/TournamentDetailDetails';
import PageCardActions
  from '../../../components/_Global/Page/PageCard/PageCardActions';
import TournamentDetailActions
  from '../../../components/Tournaments/Detail/TournamentDetailActions';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {tournamentsBookings_GET} from '../../../api/tournaments';

function PageTournamentsDetailTournamentDetail({details,clubRatingSystem}) {


  const {tournamentId} = useParams();

  const [loadingBookings, setLoadingBookings] = useState(true);
  const [bookings, setBookings] = useState();

  function fetchBookings(showLoading = true) {

    if (showLoading) {
      setLoadingBookings(true);
    }

    tournamentsBookings_GET({tournamentId, cbSuccess, cbFail});

    function cbSuccess(res) {
      setBookings(res);
      setLoadingBookings(false);
    }

    function cbFail(e) {
      setLoadingBookings(false);
    }
  }

  useEffect(() => {
    fetchBookings(true);
  }, [tournamentId]);

  return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PageCard>
            <PageCardContent>
              <TournamentDetailDetails
                  details={details}
                  clubRatingSystem={clubRatingSystem}
              />
            </PageCardContent>
            <PageCardActions>
              <TournamentDetailActions details={details}/>
            </PageCardActions>
          </PageCard>
        </Grid>
        <Grid item xs={6}>
          <PageCard sx={{
            position: 'sticky',
            top: '20px',
          }}>
            <PageCardContent>
              <TournamentsCourtsAvailabilityListProvider
                  type={'detail'}
                  tournament={details}
                  isLoadingCourts={loadingBookings}
                  courts={bookings}
                  onCancelBookingSuccess={() => {
                    fetchBookings(false);
                  }}
                  onAddBookingSuccess={() => {
                    fetchBookings(false);
                  }}
              >
                <TournamentsCourtsAvailabilityList/>
              </TournamentsCourtsAvailabilityListProvider>
            </PageCardContent>
          </PageCard>
        </Grid>
      </Grid>
  );
}

export default PageTournamentsDetailTournamentDetail;
