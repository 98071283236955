import {useEffect, useState} from 'react';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import {useParams} from 'react-router-dom';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {useSnackbar} from 'notistack';
import {useDispatch} from 'react-redux';
import {LoadingButton} from '@mui/lab';
import DialogSection from '../components/_Global/Dialogs/DialogSection';
import {
  AccessTimeTwoTone,
  StickyNote2TwoTone,
  SurroundSoundTwoTone,
} from '@mui/icons-material';
import BookingsCourtDetails
  from '../components/Bookings/Details/BookingsCourtDetails';
import {useTranslate} from '@tolgee/react';
import {useSelectedClub} from '../hooks/clubs/useSelectedClub';
import {InternalNotes} from '../components/Bookings/Inputs/InternalNotes';
import {StartTimeSelect} from '../components/Bookings/Inputs/StartTimeSelect';
import dayjs from 'dayjs';
import {clubsTimingsList_GET} from '../vendor/redux/actions/clubs-timings';
import {courtsList_GET} from '../vendor/redux/actions/courts';
import {
  addBookingToRecurring_POST,
  recurBookingsValidate_POST,
} from '../vendor/redux/actions/bookings-recurring';
import DialogConfirmation from './DialogConfirmation';
import {callGTMEvent} from '../utils/gtmEvents';
import {addBookingToTraining_POST} from '../vendor/redux/actions/training';
import {tournamentsAddBooking_POST} from '../api/tournaments';

function DialogAddBookingToExistingRecurring({
                                               open,
                                               handleCloseModal,
                                               fetchRecurringBooking,
                                               type = 'recurr',
                                               sport = '',
                                               trainingId = null,
                                               tournamentId = null,
                                               showEmail = true,
                                             }) {

  const {t} = useTranslate();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const {id} = useParams();
  const selectedClub = useSelectedClub();
  const selectedClubId = selectedClub?.id;

  const formMethods = useForm();

  const {
    formState,
    watch,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    setError,
    control,
    reset: resetForm,
  } = formMethods;
  const errors = formState.errors;
  const watched = watch();

  const [minMaxTimes, setMinMaxTimes] = useState();
  const [clubTimings, setClubTimings] = useState(null);
  const [isBookingCreation, setIsBookingCreation] = useState(false);
  const [courts, setCourts] = useState([]);
  const [sendEmail, setSendEmail] = useState(false);
  const [showCreateConfirmation, setShowCreateConfirmation] = useState(false);

  const COURTS = watched?.court ? JSON.parse(watched.court)?.courtId : [];
  const RECURRENCE_TYPE = 'daily';
  const START_DATE = watched?.startDate;
  const START_TIME = watched?.startTime;
  const END_TIME = watched?.endTime;
  const END_DATE = watched?.startDate;
  const DAY = START_DATE &&
      dayjs(START_DATE, 'YYYY-MM-DD').locale('en').format('dddd');

  const CAN_FETCH_SLOTS =
      (!!COURTS && COURTS.length != 0) &&
      !!RECURRENCE_TYPE &&
      !!START_DATE &&
      !!START_TIME &&
      !!END_TIME &&
      !!END_DATE;

  let IS_LOADING = false;

  const handleClose = () => {
    setShowCreateConfirmation(false);
    handleCloseModal();
  };

  function handleFormSubmit() {
    return handleSubmit(handleSubmitOnValid, handleSubmitOnFail)();
  }

  function handleSubmitOnValid(data) {
    console.log('Form Validation Passed', data);
    fetchSlots(true);
  }

  function handleSubmitOnFail(errors) {
    console.log('Form Validation Failed', errors);
    enqueueSnackbar(t('global.validation.fail'), {variant: 'error'});
  }

  function handleFormSubmitOnValid() {

    if (type === 'tournament') {
      return addBookingToTournament();
    }

    if (type === 'recurr') {
      addBookingToRecurring();
    } else {
      addBookingToTraining();
    }
  }

  function addBookingToRecurring() {
    setIsBookingCreation(true);
    let data = getValues();
    const body = {
      sendEmail: sendEmail,
      date: data.startDate,
      startTime: data.startTime,
      endTime: data.endTime,
      notes: data.notes,
    };

    const courtId = JSON.parse(data.court)?.courtId;
    return dispatch(
        addBookingToRecurring_POST(id, courtId, body, onSuccess, onFail));

    function onSuccess(res) {
      console.log('bookingsCreate_POST onSuccess', res);
      callGTMEvent('bookingRecurring',
          'bookingAddedToExistingRecurringBooking');
      enqueueSnackbar(t('booking.created'), {variant: 'success'});
      fetchRecurringBooking();
      handleClose();
      setIsBookingCreation(false);
    }

    function onFail(e) {
      console.log('bookingsCreate_POST onFail', e);
      enqueueSnackbar(t(t(e?.response?.data?.data)) ||
          t('networkError'), {variant: 'error'});

      setIsBookingCreation(false);
      setShowCreateConfirmation(false);
    }
  }

  function addBookingToTraining() {
    setIsBookingCreation(true);
    let data = getValues();
    const courtId = JSON.parse(data.court)?.courtId;
    const body = {
      sendEmail: sendEmail,
      date: data.startDate,
      startTime: data.startTime,
      endTime: data.endTime,
      notes: data.notes,
      courtId: courtId,
    };

    return dispatch(
        addBookingToTraining_POST(trainingId, body, onSuccess, onFail));

    function onSuccess(res) {
      console.log('bookingsCreate_POST onSuccess', res);
      enqueueSnackbar(t('booking.created'), {variant: 'success'});
      fetchRecurringBooking();
      handleClose();
      setIsBookingCreation(false);
    }

    function onFail(e) {
      console.log('bookingsCreate_POST onFail', e);
      enqueueSnackbar(t(t(e?.response?.data?.data)) ||
          t('networkError'), {variant: 'error'});

      setIsBookingCreation(false);
      setShowCreateConfirmation(false);
    }
  }

  function addBookingToTournament() {

    setIsBookingCreation(true);

    let data = getValues();
    const courtId = JSON.parse(data.court)?.courtId;
    const body = {
      sendEmail: sendEmail,
      date: data.startDate,
      startTime: data.startTime,
      endTime: data.endTime,
      notes: data.notes,
      courtId: courtId,
    };

    return tournamentsAddBooking_POST({
      tournamentId,
      body,
      cbSuccess,
      cbFail,
    });

    function cbSuccess(res) {
      enqueueSnackbar(t('booking.created'), {variant: 'success'});
      fetchRecurringBooking();
      handleClose();
      setIsBookingCreation(false);
    }

    function cbFail(e) {
      enqueueSnackbar(t(t(e?.response?.data?.data)) ||
          t('networkError'), {variant: 'error'});
      setIsBookingCreation(false);
      setShowCreateConfirmation(false);
    }
  }

  function fetchClubTimings() {

    dispatch(clubsTimingsList_GET(selectedClubId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('clubTimingsList_GET Success', res);
      setClubTimings(res.data.data);
    }

    function cbFail(e) {
      console.log('clubTimingsList_GET Fail', e);
    }
  }

  function fetchCourts() {

    dispatch(courtsList_GET(selectedClubId, 1000, 0, sport, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('courts list Success', res);
      setCourts(res.data.data.rows);
    }

    function cbFail(e) {
      console.log('clubTimingsList_GET Fail', e);
    }

  }

  const clearApiError = () => clearErrors('apiError');

  function fetchSlots(isOpen) {

    setValue('isLoadingSlots', true);

    dispatch(recurBookingsValidate_POST(
        START_DATE,
        END_DATE,
        START_TIME,
        END_TIME,
        COURTS,
        RECURRENCE_TYPE,
        DAY,
        '',
        '',
        '',
        cbSuccess,
        cbFail,
    ));

    function cbSuccess(res) {
      if (res?.data?.data?.dates[0]?.status === 'fail') {
        setError('apiError', {type: 'manual', message: 'Error'}); // Set the error mes
      } else if (isOpen) {
        setShowCreateConfirmation(true);
      }
      setValue('isLoadingSlots', false);
    }

    function cbFail(e) {
      setValue('isLoadingSlots', false);
    }
  }

  // Fetch Slots
  useEffect(() => {
    if (CAN_FETCH_SLOTS) {
      fetchSlots();
    }
  }, [
    COURTS,
    START_DATE,
    START_TIME,
    END_TIME,
    END_DATE]);

  useEffect(() => {
    if (selectedClubId) {
      fetchClubTimings();
      fetchCourts();
    }
  }, [selectedClubId]);

  //Set Day Min Max Times
  useEffect(() => {
    if (clubTimings) {
      let dayTimings;
      if (watched?.startDate) {
        const day = dayjs(watched?.startDate, 'YYYY-MM-DD').
            locale('en').
            format('dddd');
        dayTimings = clubTimings.find((timing) => {
          return timing.day === day;
        });
      }
      if (!watched?.startDate) {
        const todayDay = dayjs().locale('en').format('dddd');
        dayTimings = clubTimings.find((timing) => {
          return timing.day === todayDay;
        });
      }
      setMinMaxTimes({
        min: dayTimings.startTime,
        max: dayTimings.endTime,
      });
    }
  }, [clubTimings, watched?.startDate]);

  return (
      <FormProvider {...formMethods} >
        <Dialog
            open={open}
            onClose={handleClose}
            scroll={'body'}
            TransitionProps={{
              onExited: () => {
                resetForm();
              },
            }}

        >
          <DialogTitle>
            {
                IS_LOADING && <Skeleton width={'195px'}/>
            }
            {
                !IS_LOADING &&
                t('bookingAdd.heading')
            }
          </DialogTitle>
          <DialogContent
              dividers={!IS_LOADING}
              sx={{overflowX: 'hidden', overflowY: 'hidden'}}
          >
            {
                IS_LOADING && <Skeleton width={'100%'} height={'2px'}/>
            }
            <Box>
              <DialogSection
                  label={t('bookingAdd.court')}
                  icon={SurroundSoundTwoTone}
                  isLoading={IS_LOADING}
              >
                <BookingsCourtDetails
                    // courtName={locationState.court.name}
                    showCourtDetails={false}
                    isLoading={IS_LOADING}
                />
                <RenderSelect
                    name={'court'}
                    errorName={t('pricing.error.court')}
                    options={courts}
                    value={[]}
                    label={t(
                        'global.components.courtMultiSelect.placeholder')}
                    errors={errors}
                    control={control}
                    //   onChange={handleOnCourtChange}
                    placeholder={t(
                        'global.components.courtMultiSelect.placeholder')}
                    clearApiError={clearApiError}
                />
              </DialogSection>
              <DialogSection
                  label={t('bookingAdd.time')}
                  icon={AccessTimeTwoTone}
                  isLoading={IS_LOADING}
              >
                <StartTimeSelect
                    minMaxTimes={minMaxTimes}
                    minDate={dayjs().add(1, 'day')}
                    minInterval={60}
                    onTimeChange={clearApiError}
                    onDateChange={clearApiError}
                />

                {errors?.apiError && <Alert severity="error">
                  {JSON.parse(watched?.court)?.name} {t(
                    'recurring.court.notAvailable')} {dayjs(watched?.startTime,
                    'H:mm').format('h:mm A')} - {dayjs(watched?.endTime,
                    'HH:mm').format('h:mm A')}
                </Alert>}
              </DialogSection>


              <DialogSection
                  isLoading={IS_LOADING}
                  label={t('bookings.addEdit.section.label.internalNotes')}
                  icon={StickyNote2TwoTone}
              >
                <InternalNotes isLoading={IS_LOADING}/>
              </DialogSection>

            </Box>
            {
                IS_LOADING && <Skeleton width={'100%'} height={'2px'}/>
            }
          </DialogContent>
          <DialogActions>
            {
                IS_LOADING && <Skeleton height={'42.25px'} width={'100%'}/>
            }
            {
                !IS_LOADING &&
                <Button
                    variant="outlined"
                    onClick={handleClose}
                    disabled={isBookingCreation}
                >
                  {t('bookingAdd.buttonClose')}
                </Button>
            }
            {
                !IS_LOADING &&
                <LoadingButton
                    disabled={isBookingCreation || errors?.apiError}
                    loading={watched?.isLoadingSlots}
                    variant="contained"
                    onClick={handleFormSubmit}
                >
                  <span>{t('bookingAdd.buttonSave')}</span>
                </LoadingButton>
            }
          </DialogActions>
        </Dialog>
        <DialogConfirmation
            variant={'primary'}
            handleConfirm={handleFormSubmitOnValid}
            confirmButtonLabel={t('bookings.addEdit.actions.confirmBooking')}
            isSubmitting={isBookingCreation}
            dialogTitle={t('booking.recurring.addEdit.confirm.dialog.title')}
            dialogContent={
              <Stack>

                {
                  t('add.booking.confirmation')
                }

                {/*{*/}
                {/*    showEmail &&*/}
                {/*    t(*/}
                {/*        type === 'recurr' ?*/}
                {/*            'recurring.existingBooking.notify.player' :*/}
                {/*            type === 'tournament' ?*/}
                {/*                'tournament.existingBooking.notify.player' :*/}
                {/*                'training.existingBooking.notify.player',*/}
                {/*    )*/}
                {/*}*/}

                {
                    showEmail && <RecurringchangesNotification
                        t={t}
                        sendEmail={sendEmail}
                        setSendEmail={setSendEmail}
                    />
                }
              </Stack>
            }
            DialogContentComponent={Box}
            isOpen={showCreateConfirmation}
            handleClose={() => {
              setSendEmail(false);
              setShowCreateConfirmation(false);
            }}
        />
      </FormProvider>
  );

}

export default DialogAddBookingToExistingRecurring;

function RecurringchangesNotification({t, sendEmail, setSendEmail}) {

  return (
      <Stack mt={2} direction={'row'} justifyContent={'space-between'}>
        <Typography color={'black'} variant="subtitle1">{t(
            'recurring.notify.player')}</Typography>
        {/* <FormGroup>
            <FormControlLabel sx={{mr: 0}} control={<Switch onChange={(check) => {
              setSendEmail(check.target.checked);
            }} checked={sendEmail}/>}/>
          </FormGroup> */}
        <FormControl>
          <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              sx={{flexDirection: 'row'}}
          >
            <FormControlLabel sx={{color: 'black'}} value="" control={<Radio
                onChange={check => setSendEmail(check.target.checked)}
                checked={sendEmail}/>} label={t('global.types.yesNo.yes')}/>
            <FormControlLabel sx={{color: 'black'}} value="" control={<Radio
                onChange={check => setSendEmail(!check.target.checked)}
                checked={!sendEmail}/>} label={t('global.types.yesNo.no')}/>
          </RadioGroup>
        </FormControl>
      </Stack>
  );
}

function RenderSelect({
                        control,
                        errors,
                        name,
                        placeholder,
                        errorName,
                        t,
                        disabled,
                        options,
                        clearApiError,
                      }) {
  return (
      <FormControl fullWidth
                   size="medium">
        {/* <InputLabel error={!!errors[name]}>{label}</InputLabel> */}
        <Controller
            name={name}
            control={control}
            rules={{
              required: {
                value: true, message: errorName,
              },
            }}
            defaultValue={''}
            render={({field}) => {
              return (<Select
                  error={!!errors[name]}
                  placeholder={placeholder}
                  //   label={label}
                  disabled={disabled}
                  variant={'standard'}
                  // value={role}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    clearApiError();
                  }}
              >
                {
                    options && options.map(opt => (
                        <MenuItem key={opt.courtId}
                                  value={JSON.stringify(opt)}>{opt.name}</MenuItem>
                    ))
                }

              </Select>);
            }}/>
        {!!errors[name] &&
            <FormHelperText error>{errors[name].message}</FormHelperText>}
      </FormControl>
  );
}
