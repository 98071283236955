import axios from 'axios';

export const addTraining_POST = (body,cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/training/create`,body);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
};

export const editTraining_PATCH = (id,body,cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      const response = await axios.patch(`/training/update/${id}`,body);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
};

export const trainingsList_GET = (
  clubId,type,cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/training/list?clubId=${clubId}&type=${type}`);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
};

export const trainingRegistration_GET = (trainingId,type, cbCallBack) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
          `training/registered-users/${trainingId}?type=${type}`);
      cbCallBack(response);
    } catch (e) {
      cbCallBack(e);
    }
  };
};


export const trainingAddUserToTraining_POST = (trainingId,body, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`training/add-user/${trainingId}`, body);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
};

export const trainingValidateCustomer_POST = (trainingId,body, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`training/validate-user/${trainingId}`, body);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      console.log(e);
    }
  };
};

export const trainingInvites_GET = (trainerId, cbSuccess) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`training/invited-users/${trainerId}`);
      cbSuccess(response);
    } catch (e) {
      cbSuccess(e);
    }
  };
};

export const cancelTrainingRegistration_POST = (id, body, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`training/cancel-user/${id}`, body);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      console.log(e);
    }
  };
};

export const addBookingToTraining_POST = (trainingId, body, cbSuccess,
  cbFailure) => {
return async (dispatch) => {
  try {
    const response = await axios.post(`training/add-booking/${trainingId}`, body);
    cbSuccess(response);
  } catch (e) {
    cbFailure(e);
  }
};
};

export const trainingsSingle_GET = (
  id,cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {

      const response = await axios.get(`/training/single/${id}`);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
};


export const clubsTrainersList_GET = (
    clubId,cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/training/trainers/list/${clubId}`);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
  };

export const customerTrainingsList_GET = (
  userId,clubId,page,sport,type,cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/training/customer-trainings/${userId}?clubId=${clubId}&page=${page}&sport=${sport}&type=${type}`);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
};

export const sendMessageByTrainer_POST = (
  trainingId,message,cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/training/trainer-message/${trainingId}`,{message});
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
};
