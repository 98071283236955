import {Skeleton, Stack, Typography, useTheme} from '@mui/material';
import {Circle} from '@mui/icons-material';

function BookingsCourtDetails({
  titleComponent,
  route,
  courtName,
  courtSport,
  courtType,
  courtFeature,
  courtSize,
  showCourtDetails = true,
  isLoading,
  altLabelSlot
}) {
  const theme = useTheme();
  return <>
    {
        isLoading && <Skeleton width={'256px'} height={'24px'}/>
    }
    {
        !isLoading &&
        <Stack>
          <Stack
          direction={'row'}
          justifyContent={'space-between'}
          >
            <Typography
                variant={'body1'}
                component={titleComponent ?? 'span'}
                to={route}
                sx={{userSelect: 'none',textDecoration:'none',color:'inherit'}}
            >
              {courtName}
            </Typography>
            {altLabelSlot ?? ''}
          </Stack>
          {
              showCourtDetails &&
              <Stack direction={'row'} spacing={'4px'} alignItems={'center'}>
                <Typography variant={'body2'} sx={{textTransform:'capitalize'}}>{courtSport}</Typography>
                {
                    courtSize &&
                    <>
                      <Circle
                          sx={{
                            fill: theme.palette.text.secondary,
                            width: '4px',
                          }}/>
                      <Typography variant={'body2'} sx={{textTransform:'capitalize'}}>{courtSize}</Typography>
                    </>
                }
                {
                    courtType &&
                    <>
                      <Circle
                          sx={{
                            fill: theme.palette.text.secondary,
                            width: '4px',
                          }}/>
                      <Typography variant={'body2'} sx={{textTransform:'capitalize'}}>{courtType}</Typography>
                    </>
                }

                {
                    courtFeature && <>
                      <Circle
                          sx={{fill: theme.palette.text.secondary, width: '4px'}}/>
                      <Typography variant={'body2'} sx={{textTransform:'capitalize'}}>{courtFeature}</Typography>
                    </>
                }
              </Stack>
          }
        </Stack>
    }
  </>;
}

export default BookingsCourtDetails;
;
