import {Stack} from '@mui/system';
import {
  alpha,
  Autocomplete,
  Box,
  Button,
  debounce,
  Divider,
  Fab,
  LinearProgress,
  TextField,
  Typography,
  useTheme,
  Zoom,
} from '@mui/material';
import {useEffect, useMemo, useState} from 'react';
import {useTournamentMatchesContext} from './TournamentMatchesProvider';
import {Autorenew, SearchOff, SportsTennis} from '@mui/icons-material';
import EmptyPlaceholder from '../../_Global/Empty/EmptyPlaceholder';
import ChipRating from '../../_Global/Chips/ChipRating';
import DialogConfirmation from '../../../dialogs/DialogConfirmation';
import {
  tournamentsMatchScoreUpdate_PATCH,
  tournamentsStartMatches_POST,
} from '../../../api/tournaments';
import {useSnackbar} from 'notistack';
import {useTranslate} from '@tolgee/react';
import {ERRORS} from '../../../constants/errors';
import useComponentDidUpdate
  from '../../../hooks/lifecycle/useComponentDidUpdate';

function TournamentsMatchesMatchListBody() {

  const {matches, loadingMatches, searchTerm} = useTournamentMatchesContext();

  const filtered = useMemo(() => {
    if (!matches) return;
    if (!searchTerm) return matches;
    return filterMatches(matches, searchTerm);
  }, [searchTerm, matches]);

  function filterMatches(matches, searchTerm) {

    if (!searchTerm) return matches;

    searchTerm = searchTerm.toLowerCase().trim();

    return matches.filter(match => {
      return [match.teamA, match.teamB].some(team => {
        return team.tournamentParticipants.some(participant => {
          const {firstName, lastName} = participant.user;
          const fullName = `${firstName} ${lastName}`.trim();

          return firstName.toLowerCase().includes(searchTerm) ||
              lastName.toLowerCase().includes(searchTerm) ||
              fullName.toLowerCase().includes(searchTerm);
        });
      });
    });
  }

  return (
      <Stack sx={{position: 'relative'}}>
        {loadingMatches && <LoadingIndicator/>}
        {loadingMatches && matches && <LoadingOverlay/>}
        {loadingMatches && !matches && <Box sx={{height: '400px'}}/>}
        {
            !loadingMatches && (!matches || matches?.length === 0) &&
            <MatchesEmpty/>
        }
        {
            matches?.length > 0 && filtered?.length > 0 &&
            <Stack divider={<Divider sx={{borderStyle: 'dashed'}}/>}>
              {
                filtered.map((match) => {
                  return <Match key={match.id} match={match}/>;
                })
              }
            </Stack>
        }
        {
            matches?.length > 0 && filtered?.length === 0 &&
            <NoSearchResults/>
        }
      </Stack>

  );
}

export default TournamentsMatchesMatchListBody;

function Match({match}) {

  const {
    scoringFormat,
  } = useTournamentMatchesContext();

  return (
      <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
      >
        <Box
            sx={{
              width: '100%',
              maxWidth: '800px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              columnGap: 2,
              py: 4,
              px: 4,
            }}
        >
          {/*Left*/}
          <Box sx={{flex: 1, display: 'flex'}}>
            <MatchTeam
                team={match.teamA}
                className={'left'}
                matchId={match?.id}
            />
          </Box>

          {/*Center*/}
          <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
          >
            {
                (
                    scoringFormat === 'custom' ||
                    scoringFormat === 'classic' ||
                    scoringFormat === '16' ||
                    scoringFormat === '21' ||
                    scoringFormat === '24' ||
                    scoringFormat === '32'
                ) &&
                <ScoresOptionsSelect
                    matchId={match.id}
                    teamOneScore={match.teamAScore}
                    teamTwoScore={match.teamBScore}
                />
            }
            {
                scoringFormat === 'standard' &&
                <ScoresStandard
                    matchId={match.id}
                    teamOneScore={match.teamAScore}
                    teamTwoScore={match.teamBScore}
                />
            }

          </Box>

          {/*Right*/}
          <Box
              sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
          >
            <MatchTeam
                team={match.teamB}
                className={'right'}
                matchId={match?.id}
            />
          </Box>
        </Box>
      </Box>

  );
}

function MatchTeam({team, className, matchId}) {

  // give columns equal width
  useEffect(() => {

    const elements = document.querySelectorAll(`.${className}`);

    let maxWidth = 0;

    // Find the max width
    elements.forEach(el => {
      const width = el.offsetWidth;
      if (width > maxWidth) {
        maxWidth = width;
      }
    });

    // Apply the max width to all elements
    elements.forEach(el => {
      el.style.minWidth = `${maxWidth}px`;
    });

  }, []);

  return (
      <Stack
          className={className}
          direction="row"
          spacing={6}
      >
        {
          team.tournamentParticipants?.map((p, i) => {
            return <MatchTeamParticipant key={i} participant={p}
                                         matchId={matchId}/>;
          })
        }
      </Stack>
  );
}

function MatchTeamParticipant({participant, matchId}) {

  const theme = useTheme();
  const {
    setReplaceSource,
    setShowReplacePlayer,
    setReplaceMatchId,
  } = useTournamentMatchesContext();

  const [hover, setHover] = useState(false);

  const user = participant?.user;

  const name = `${user?.firstName || ''} ${user?.lastName || ''}`;
  const rating = user?.rating;

  const ratingLabel = (() => {
    if (rating || rating === 0) {
      return rating.toFixed(2);
    } else {
      return 'NA';
    }
  })();

  return (
      <Stack
          alignItems="flex-start"
          rowGap={.5}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          sx={{
            position: 'relative',
            borderRadius: '8px',
            p: 1,
            pr: 2,
            ...(hover && {
              backgroundColor: theme.palette.grey[200],
            }),
          }}
      >
        {
          <Zoom in={hover}>
            <Fab
                onClick={() => {
                  setReplaceSource(participant);
                  setReplaceMatchId(matchId);
                  setShowReplacePlayer(true);
                }}
                sx={{
                  width: '34px',
                  height: '34px',
                  minHeight: 'unset',
                  position: 'absolute',
                  top: '-18px',
                  right: '-18px',
                  backgroundColor: theme.palette.grey[50],
                  border: `1px solid ${theme.palette.divider}`,
                }}
            >
              <Autorenew fontSize={'small'}/>
            </Fab>
          </Zoom>

        }
        <Typography fontWeight={600} lineHeight={1.1}>
          {name}
        </Typography>
        <ChipRating rating={ratingLabel}/>
      </Stack>
  );
}

function ScoresOptionsSelect({matchId, teamOneScore, teamTwoScore}) {

  const {
    scoringFormat,
    scoringFormatCustomValue,
    loadingMatches,
    handleMatchScoreChange,
    tournamentId,
    selectedCategory,
  } = useTournamentMatchesContext();

  const maxScore = (() => {

    if (scoringFormat === 'custom') {
      return scoringFormatCustomValue;
    }

    if (scoringFormat === 'classic') {
      return '';
    }

    return +scoringFormat;

  })();

  const winner = (() => {
    if (typeof teamOneScore === 'number' && typeof teamTwoScore === 'number') {
      if (teamOneScore > teamTwoScore) return 'teamOne';
      if (teamOneScore < teamTwoScore) return 'teamTwo';
    }
  })();

  const options = useMemo(() => {

    if (scoringFormat === 'classic') {
      return [0, 15, 30, 40, 45];
    }

    return generateNumberArray(0, maxScore);

  }, [scoringFormat]);

  function handleChangeTeamOneScore(newScore) {

    if (scoringFormat === 'classic') {
      handleMatchScoreChange(matchId, newScore, teamTwoScore, teamOneScore,
          teamTwoScore, tournamentId, selectedCategory?.id);
      return;
    }

    const remainder = maxScore - newScore;
    handleMatchScoreChange(matchId, newScore, remainder, teamOneScore,
        teamTwoScore, tournamentId, selectedCategory?.id);
  }

  function handleChangeTeamTwoScore(newScore) {

    if (scoringFormat === 'classic') {
      handleMatchScoreChange(matchId, teamOneScore, newScore, teamOneScore,
          teamTwoScore, tournamentId, selectedCategory?.id);
      return;
    }

    const remainder = maxScore - newScore;
    handleMatchScoreChange(matchId, remainder, newScore, teamOneScore,
        teamTwoScore, tournamentId, selectedCategory?.id);
  }

  return (
      <Stack
          justifyContent="center"
          direction="row"
          spacing={1}
          alignItems="center"
      >
        <ScoresOptionsSelectInput
            value={teamOneScore}
            onChange={handleChangeTeamOneScore}
            options={options}
            isWinner={winner === 'teamOne'}
            disabled={loadingMatches}
        />
        <Typography color={'text.secondary'}>–</Typography>
        <ScoresOptionsSelectInput
            value={teamTwoScore}
            onChange={handleChangeTeamTwoScore}
            options={options}
            isWinner={winner === 'teamTwo'}
            disabled={loadingMatches}
        />
      </Stack>
  );
}

function ScoresOptionsSelectInput({
                                    value,
                                    onChange,
                                    options,
                                    isWinner,
                                    disabled,
                                  }) {

  const theme = useTheme();

  return (
      <Autocomplete
          value={value}
          disableClearable
          disabled={disabled}
          autoHighlight
          options={options}
          onChange={(event, newValue) => {
            onChange(newValue);
          }}
          getOptionLabel={(option) => String(option)}
          sx={{
            '.MuiAutocomplete-popupIndicator': {
              display: 'none',
            },
          }}
          renderInput={(params) => (
              <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      textAlign: 'center',
                    },
                    endAdornment: null,
                  }}
                  inputProps={{
                    ...(params.inputProps),
                    style: {
                      ...params.inputProps.style,
                      textAlign: 'center',
                    },
                  }}
                  onInput={(event) => {
                    //allow only numeric value
                    event.target.value = event.target.value.replace(/\D/g, '');
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      textAlign: 'center',
                      height: '48px',
                      padding: '0px 16px !important',
                      backgroundColor: isWinner ?
                          alpha(theme.palette.success.main, .1) :
                          theme.palette.grey[100], // Background inside the input
                    },
                  }}
              />
          )}
          renderOption={(props, option, index) => (
              <Box
                  {...props}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center !important',
                    alignItems: 'center !important',
                    margin: 0.5,
                    borderRadius: '4px',
                    cursor: 'pointer',
                    px: 1,
                    py: 2,
                    backgroundColor: alpha(theme.palette.success.main, .1),
                    color: theme.palette.success.dark,
                    '&:hover, &.Mui-focused': {
                      outline: `1px solid ${theme.palette.success.dark}`,
                      backgroundColor: `${alpha(theme.palette.success.main,
                          .1)} !important`,
                    },
                    '&[aria-selected=true]': {
                      backgroundColor: `${theme.palette.success.main} !important`,
                      color: '#fff',
                    },
                  }}
              >
                {option}
              </Box>
          )}

          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 8], // Adds 10px space between input and dropdown
                  },
                },
              ],
            },
            paper: {
              sx: {
                boxShadow: 5,
                width: '400px',
                border: `1px solid ${theme.palette.divider}`,
                '.MuiAutocomplete-listbox': {
                  padding: '16px',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(5, 1fr)', // 5 items per row
                  gap: .75,
                },
              },
            },
          }}

      />
  );

}

function ScoresStandard({matchId, teamOneScore, teamTwoScore}) {

  const {
    setMatches,
    fetchRounds,
    tournamentId,
    selectedCategory,
  } = useTournamentMatchesContext();

  const [scoreOne, setScoreOne] = useState(initializeTeamOneScore);
  const [scoreOnePrev, setScoreOnePrev] = useState(initializeTeamOneScore);

  const [scoreTwo, setScoreTwo] = useState(initializeTeamTwoScore);
  const [scoreTwoPrev, setScoreTwoPrev] = useState(initializeTeamTwoScore);

  function initializeTeamOneScore() {
    if (teamOneScore || teamOneScore === 0) {
      return `${teamOneScore}`;
    } else {
      return '';
    }
  }

  function initializeTeamTwoScore() {
    if (teamTwoScore || teamTwoScore === 0) {
      return `${teamTwoScore}`;
    } else {
      return '';
    }
  }

  function handleSaveScoreOne(scoreOneNew, scoreOnePrev, scoreTwo) {

    tournamentsMatchScoreUpdate_PATCH({
      matchId: matchId,
      teamAScore: (+scoreOneNew || +scoreOneNew === 0) ? scoreOneNew : null,
      teamBScore: (+scoreTwo || +scoreTwo === 0) ? scoreTwo : null,
      cbSuccess,
      cbFail,
    });

    function cbSuccess(res) {
      setScoreOnePrev(scoreOneNew);
      handleUpdateScoreState(matchId, scoreOneNew, scoreTwo);
      fetchRounds(tournamentId, selectedCategory?.id);
    }

    function cbFail(e) {
      setScoreOne(scoreOnePrev);
    }

  }

  function handleSaveScoreTwo(scoreTwoNew, scoreTwoPrev, scoreOne) {

    tournamentsMatchScoreUpdate_PATCH({
      matchId: matchId,
      teamAScore: (+scoreOne || +scoreOne === 0) ? scoreOne : null,
      teamBScore: (scoreTwoNew || +scoreTwoNew === 0) ? scoreTwoNew : null,
      cbSuccess,
      cbFail,
    });

    function cbSuccess(res) {
      setScoreTwoPrev(scoreTwoNew);
      handleUpdateScoreState(matchId, scoreOne, scoreTwoNew);
      fetchRounds(tournamentId, selectedCategory?.id);
    }

    function cbFail(e) {
      setScoreTwo(scoreTwoPrev);
    }

  }

  function handleUpdateScoreState(matchId, teamOneScore, teamTwoScore) {
    setMatches((prevState) => {
      const clone = [...prevState];
      const matchIdx = clone.findIndex(match => match.id === matchId);
      const match = clone[matchIdx];
      match.teamAScore = teamOneScore;
      match.teamBScore = teamTwoScore;
      return clone;
    });
  }

  const debouncedSaveScoreOne = useMemo(() => {
    return debounce(handleSaveScoreOne, 500);
  }, []);

  const debouncedSaveScoreTwo = useMemo(() => {
    return debounce(handleSaveScoreTwo, 500);
  }, []);

  useComponentDidUpdate(
      () => {
        debouncedSaveScoreOne(scoreOne, scoreOnePrev, scoreTwo);
      }
      , [scoreOne]);

  useComponentDidUpdate(
      () => {
        debouncedSaveScoreTwo(scoreTwo, scoreTwoPrev, scoreOne);
      }
      , [scoreTwo]);

  return (
      <Stack
          justifyContent="center"
          direction="row"
          spacing={1}
          alignItems="center"
      >
        <ScoresStandardInput
            value={scoreOne}
            setValue={setScoreOne}
        />
        <Typography color={'text.secondary'}>–</Typography>
        <ScoresStandardInput
            value={scoreTwo}
            setValue={setScoreTwo}
        />
      </Stack>
  );

}

function ScoresStandardInput({value, setValue}) {

  return (
      <TextField
          value={value}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          inputProps={{
            style: {
              textAlign: 'center',
            },
          }}
          onInput={(event) => {
            //allow only numeric value
            event.target.value = event.target.value.replace(/\D/g, '');
          }}
          sx={{
            width: '72px',
            '& .MuiInputBase-root': {
              textAlign: 'center',
              height: '48px',
              // backgroundColor: isWinner ?
              //     alpha(theme.palette.success.main, .1) :
              //     theme.palette.grey[100], // Background inside the input
            },
          }}
      />
  );
}

function LoadingIndicator() {
  return (<LinearProgress sx={{
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 2,
  }}/>);
}

function LoadingOverlay() {
  return (
      <Box sx={{
        pointerEvents: 'none',
        zIndex: 1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: alpha('#fff', .6),
      }}/>
  );
}

function MatchesEmpty() {

  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslate();

  const {
    tournamentId,
    selectedCategory,
    fetchRoundsAndMatches,
  } = useTournamentMatchesContext();

  const [showStartTournamentConfirm, setShowStartTournamentConfirm] = useState(
      false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleStartTournament() {

    setIsSubmitting(true);

    tournamentsStartMatches_POST({
      tournamentId,
      categoryId: null,
      cbSuccess,
      cbFail,
    });

    function cbSuccess(res) {
      fetchRoundsAndMatches(tournamentId, selectedCategory?.id);
      setShowStartTournamentConfirm(false);
      setIsSubmitting(false);
    }

    function cbFail(e) {
      const errorMsg = e?.response?.data?.data?.error;
      enqueueSnackbar(t(errorMsg || ERRORS.NETWORK_ERROR), {variant: 'error'});
      setIsSubmitting(false);
    }

  }

  return (
      <Box
          sx={{
            height: '400px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
      >
        <EmptyPlaceholder
            icon={<SportsTennis color={'action'}/>}
            title={
              <Stack
                  alignItems={'center'}
                  sx={{
                    maxWidth: '426px',
                    textAlign: 'center',
                  }}
              >
                <Typography variant={'body1'}>
                  No Matches Yet
                </Typography>
                <Typography variant={'body2'}>
                  Once the tournament starts, you’ll see tournament rounds and
                  matches details here.
                </Typography>
              </Stack>
            }
            actionSlot={
              <Button
                  variant={'contained'}
                  size={'medium'}
                  onClick={() => {
                    setShowStartTournamentConfirm(true);
                  }}
              >
                Start Tournament
              </Button>
            }
        />
        <DialogConfirmation
            variant={'primary'}
            isOpen={showStartTournamentConfirm}
            isSubmitting={isSubmitting}
            dialogTitle={'Confirm Start Tournament'}
            dialogContent={'Are you sure you want to start the tournament?'}
            closeButtonText={'Cancel'}
            confirmButtonLabel={'Start Tournament'}
            handleClose={() => {
              setShowStartTournamentConfirm(false);
            }}
            handleConfirm={handleStartTournament}
        />
      </Box>
  );
}

function NoSearchResults() {
  return (
      <Box
          sx={{
            height: '400px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
      >
        <EmptyPlaceholder
            icon={<SearchOff color={'action'}/>}
            title={'No Search Results'}
        />
      </Box>
  );
}

function generateNumberArray(min, max) {
  return Array.from({length: max - min + 1}, (_, i) => min + i);
}

