import {useState} from 'react';
import {Box, Button, FormHelperText, Popover} from '@mui/material';
import {DateCalendar} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import useLocale from '../../../hooks/localization/useLocale';
import { useSelector } from 'react-redux';

export function DatePicker({
  date,
  onChange,
  minDate,
  label,
  disabled,
  error,
  showErrorMessage = true,
}) {

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const IS_OPEN_POPOVER = Boolean(popoverAnchorEl);
  const {locale} = useLocale();
  const polices = useSelector(state => state.app.policies);
  const startOFWeek = polices?.club?.company?.CompanyLocalize?.startOfWeek

  dayjs.updateLocale(locale, {
    weekStart: startOFWeek,
  });

  function handleOpenPopover(event) {
    setPopoverAnchorEl(event.currentTarget);
  }

  function handleClosePopOver() {
    setPopoverAnchorEl(null);
  }

  function handleChangeDate(date) {
    onChange(date);
    handleClosePopOver();
  }

  let minDateProp = {minDate: null};

  if (minDate) {
    minDateProp.minDate = minDate;
  }

  return (
      <>
        <Box>
          <Button
              color={!!error ? 'error' : 'primary'}
              disabled={disabled}
              variant="text"
              onClick={handleOpenPopover}
              sx={{
                textTransform: 'unset',
                fontWeight: '400',
                fontSize: '16px',
              }}
          >
            {!!date ? date.format('dddd, D MMMM, YYYY') : label}
          </Button>
          {
            error && showErrorMessage &&
            <FormHelperText error>
            {error.message}
          </FormHelperText>
          }
        </Box>
        <Popover
            open={IS_OPEN_POPOVER}
            anchorEl={popoverAnchorEl}
            onClose={handleClosePopOver}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
        >
          <DateCalendar
              onChange={handleChangeDate}
              value={date || minDate}
              {...minDateProp}
          />
        </Popover>
      </>
  );
}
