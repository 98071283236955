import {alpha, Box, Stack, useTheme} from '@mui/material';
import {useTournamentMatchesContext} from './TournamentMatchesProvider';
import {Fragment} from 'react';

function TournamentsMatchesRoundSelector() {

  const theme = useTheme();

  const {
    loadingRounds,
    rounds,
    selectedRound,
    handleSelectRound,
  } = useTournamentMatchesContext();

  function handleChange(newRoundId) {
    const currentRoundId = selectedRound.id;
    if (newRoundId !== currentRoundId) {
      const newRound = rounds.find((round) => round.id === newRoundId);
      handleSelectRound(newRound);
    }
  }

  return (
      <Stack alignItems="center" width={'104px'} sx={{p: 3, mt: 7}}>
        {
            (!loadingRounds && selectedRound && rounds) &&

            rounds?.map((round, idx) => {

              const isLastChild = idx === rounds.length - 1;
              const isSelected = selectedRound.id === round.id;
              const isComplete = round.status === 'completed';

              const classes = [
                  isSelected && 'selected',
                  isComplete && 'complete',
              ].filter(Boolean).join(' ');

              return (
                  <Fragment key={idx}>
                    <Box
                        className={classes}
                        sx={{
                          backgroundColor: 'white',
                          width: '56px',
                          height: '56px',
                          borderRadius: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: '2px solid transparent',
                          cursor: 'pointer',
                          fontSize: '20px',
                          "&.selected":{
                            borderColor: theme.palette.primary.main,
                            boxShadow: 10,
                            fontWeight: 800,
                          },
                          "&.complete":{
                            backgroundColor: "rgba(90, 255, 160, 1)",
                            "&.selected":{
                              borderColor: theme.palette.success.dark,
                            }
                          }

                        }}
                        onClick={() => {
                          handleChange(round.id);
                        }}
                    >
                      {idx + 1}
                    </Box>
                    {/*Separator*/}
                    {
                        !isLastChild &&
                        <Box
                            sx={{
                              height: '28px',
                              borderLeft: `1px dashed ${theme.palette.grey[400]}`,
                            }}
                        />
                    }
                  </Fragment>
              );
            })
        }
      </Stack>
  );

}

export default TournamentsMatchesRoundSelector;
