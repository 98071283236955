import { fireGAEvent } from "./googleAnalytics";

export const callGTMEvent = (eventObj, eventName, params) => {
  const eventsObjs = {
    bookingUpcoming: bookingUpcomingEvents,
    bookingRecurring: recurringBookingTabEvents,
    bookingPrevious: previousBookingTabEvents,
    events: pageEventsCustomEvents,
    wallet: walletTabEvents,
    finances: financesEvents,
    customers: customerEvents,
    login: loginEvents,
    schedule: scheduleEvents,
    packages: packagesEvents,
    courts: courtsCustomEvents,
    settings: settingsCustomEvents,
    headers: headersCustomEvents,
    extras: extrasCustomEvents,
    pricing: pricingCustomEvents,
    holidays: holidaysCustomEvents,
    policies: policiesCustomEvents,
    company: companyCustomEvents,
    clubs: clubCustomEvents,
    admins: adminCustomEvents,
    integrations: integrationEvents,
  };

  const eventFunction = eventsObjs[eventObj]
    ? eventsObjs[eventObj][eventName]
    : undefined;

  if (eventFunction) {
    eventFunction(params ?? {});
  }
};

/////////// BOOKINGS //////////////////

export const confirmCancelBookingEvent = (type) => {
  const obj = {
    reservation: "confirmCancelBooking",
    upcomingRecurring: "confirmCancelRecurringBooking",
    totalRecurring: "clickConfirmCancelRecurringBooking",
  };

  return obj[type];
};

export const confirmCancelUnpaidBookingEvent = (type) => {
  const obj = {
    reservation: "confirmCancellationOfUnpaidBooking",
  };

  return obj[type];
};

export const clickCancelBookingEvent = (type) => {
  const obj = {
    reservation: "clickOnCancelBooking",
    maintenance: "cancelMaintenanceBooking",
    closed: "cancelClosedBooking",
    training: "cancelTrainingBooking",
    tournament: "cancelTournamentBooking",
  };

  return obj[type];
};

export const nonReservationBookingsEvents = (type) => {
  const obj = {
    maintenance: "clickOnMaintenanceBooking",
    closed: "clickOnClosedBooking",
    training: "clickOnTrainingBooking",
    tournament: "clickOnTournamentBooking",
  };

  return obj[type];
};

const bookingUpcomingEvents = {
  //Upcoming Tab
  clicksOnUpcomingTab: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming",
      "Bookings - Upcoming",
      "User clicks on upcoming bookings tab",
      {...params}
    ),

  //reservation
  // paid
  clickOnReservation: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_reservation",
      "Bookings - Upcoming",
      "User clicks on upcoming Reservation bookings",
      {...params}
    ),
  clickOnEdit: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_reservation_edit_booking",
      "Bookings - Upcoming",
      "User clicks on Edit Booking",
      {...params}
    ),
  clickOnSave: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_reservation_save",
      "Bookings - Upcoming",
      "User clicks on Save Booking",
      {...params}
    ),
  updatedSuccessfully: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_reservation_saved",
      "Bookings - Upcoming",
      "Booking updated successfully",
      {...params}
    ),
  clickOnBack: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_reservation_back",
      "Bookings - Upcoming",
      "User clicks on Back btn",
      {...params}
    ),
  clickOnCancelBooking: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_reservation_cancel_booking",
      "Bookings - Upcoming",
      "User clicks on Cancel Booking",
      {...params}
    ),
  clickOnRefundPayment: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_reservation_cancel_refund_payment",
      "Bookings - Upcoming",
      "User enables refund payment for cancel booking",
      {...params}
    ),
  enablesEmailToCustomer: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_reservation_cancel_send_email",
      "Bookings - Upcoming",
      "User allows to send an email to customer",
      {...params}
    ),
  confirmCancelBooking: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_reservation_cancel_confirmed",
      "Bookings - Upcoming",
      "User clicks on confirm Cancel Booking",
      {...params}
    ),

  // unpaid
  clickOnUnpaidReservation: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_unpaid_reservation",
      "Bookings - Upcoming",
      "User click on upcoming unpaid booking",
      {...params}
    ),
  notifyCustomerForCancellationOfUnpaidBooking: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_unpaid_reservation_cancel_booking_notify_customer",
      "Bookings - Upcoming",
      "User click on notify customer for unpaid booking",
      {...params}
    ),
  confirmCancellationOfUnpaidBooking: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_unpaid_reservation_confirms_cancellation",
      "Bookings - Upcoming",
      "Confirm cancellation of unpaid booking",
      {...params}
    ),
  clickOnSendPaymentReminderOfUnpaidBooking: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_unpaid_reservation_send_payment_reminder_btn_clicked",
      "Bookings - Upcoming",
      "Click on send payment reminder btn of unpaid booking",
      {...params}
    ),
  paymentReminderSentOfUnpaidBooking: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_unpaid_reservation_send_payment_reminder_sent",
      "Bookings - Upcoming",
      "Payment reminder sent for unpaid booking",
      {...params}
    ),

  //recurring
  clickOnRecurringBooking: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_recurring",
      "Bookings - Upcoming",
      "User clicks on paid Recurring booking",
      {...params}
    ),
  clickOnCancelRecurringBooking: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_recurring_cancel_booking",
      "Bookings - Upcoming",
      "User clicks on Cancel Recurring Booking",
      {...params}
    ),
  confirmCancelRecurringBooking: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_recurring_cancel_booking_confirmed",
      "Bookings - Upcoming",
      "User confirms to cancel recurring booking",
      {...params}
    ),
  notifyCustomerForCancellation: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_recurring_cancel_notify_customer",
      "Bookings - Upcoming",
      "User allows to send an email to customer for recurring cancel",
      {...params}
    ),
  clickOnBackBtn: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_recurring_back",
      "Bookings - Upcoming",
      "User clicks on Back btn from recurring booking",
      {...params}
    ),

  // event
  clickOnEventBooking: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_events",
      "Bookings - Upcoming",
      "User clicks on Event Booking",
      {...params}
    ),

  // maintenance
  clickOnMaintenanceBooking: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_maintenance",
      "Bookings - Upcoming",
      "User clicks on Maintenance Booking",
      {...params}
    ),
  cancelMaintenanceBooking: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_maintenance_cancel",
      "Bookings - Upcoming",
      "User clicks on Cancel Maintenance Booking",
      {...params}
    ),

  // training
  clickOnTrainingBooking: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_training",
      "Bookings - Upcoming",
      "User clicks on Training Booking",
      {...params}
    ),
  cancelTrainingBooking: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_training_cancel",
      "Bookings - Upcoming",
      "User clicks on Cancel Training Booking",
      {...params}
    ),

  // tournament
  clickOnTournamentBooking: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_tournament",
      "Bookings - Upcoming",
      "User clicks on Tournament Booking",
      {...params}
    ),
  cancelTournamentBooking: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_tournament_cancel",
      "Bookings - Upcoming",
      "User clicks on Cancel Tournament Booking",
      {...params}
    ),

  // closed
  clickOnClosedBooking: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_closed",
      "Bookings - Upcoming",
      "User clicks on Closed Booking",
      {...params}
    ),
  cancelClosedBooking: (params) =>
    fireGAEvent(
      "ml_bookings_upcoming_closed_cancel",
      "Bookings - Upcoming",
      "User clicks on Cancel Closed Booking",
      {...params}
    ),
};

const recurringBookingTabEvents = {
  //Recurring Tab
  clicksOnRecurringBookingTab: (params) =>
    fireGAEvent(
      "ml_bookings_recurring",
      "Bookings - Recurring",
      "User clicks on recurring tab",
      {...params}
    ),

  clicksOnRecurringBooking: (params) =>
    fireGAEvent(
      "ml_bookings_open_recurring",
      "Bookings - Recurring",
      "User clicks on Booking from recurring tab",
      {...params}
    ),
  clicksOnPaidRecurringBooking: (params) =>
    fireGAEvent(
      "ml_bookings_open_individual_recurring",
      "Bookings - Recurring",
      "User clicks on Booking from recurring tab",
      {...params}
    ),
  clickOnUnpaidRecurringBooking: (params) =>
    fireGAEvent(
      "ml_bookings_open_individual_unpaid_recurring",
      "Bookings - Upcoming",
      "User clicks on unpaid Recurring booking",
      {...params}
    ),
  clickOnCancelledRecurringBooking: (params) =>
    fireGAEvent(
      "ml_bookings_open_individual_cancelled_recurring",
      "Bookings - Upcoming",
      "User clicks on Cancelled Recurring booking",
      {...params}
    ),
  cancelAllRecurringBookings: (params) =>
    fireGAEvent(
      "ml_bookings_recurring_individual_recurring_cancel_booking_btn_clicked",
      "Bookings - Recurring",
      "User clicks on Cancel All Recurring Booking from recurring tab",
      {...params}
    ),
  confirmCancelAllRecurringBookings: (params) =>
    fireGAEvent(
      "ml_bookings_recurring_individual_recurring_cancelled_booking",
      "Bookings - Recurring",
      "User confirms on Cancel All Recurring Booking from recurring tab",
      {...params}
    ),
  sendPaymentReminderForRecurringbooking: (params) =>
    fireGAEvent(
      "ml_bookings_recurring_individual_recurring_send_payment_reminder_btn_clicked",
      "Bookings - Recurring",
      "User clicks on send payment reminder for All Recurring Booking from recurring tab",
      {...params}
    ),
  paymentReminderConfirm: (params) =>
    fireGAEvent(
      "ml_bookings_recurring_individual_recurring_send_payment_reminder_confirm",
      "Bookings - Recurring",
      "Payemtn reminder sent for All Recurring Booking from recurring tab",
      {...params}
    ),
  // booking from recurring details
  clickOnSingleBookingFromRecurringDetails: (params) =>
    fireGAEvent(
      "ml_bookings_recurring_individual_recurring_cancel_btn_clicked",
      "Bookings - Recurring",
      "User clicks on Single Booking from recurring detail",
      {...params}
    ),
  cancelRecurringBooking: (params) =>
    fireGAEvent(
      "ml_bookings_recurring_individual_recurring_cancel_tb",
      "Bookings - Recurring",
      "User clicks on Cancel Booking from recurring tab",
      {...params}
    ),
  notifyCustomerForCancelRecurringBooking: (params) =>
    fireGAEvent(
      "ml_bookings_recurring_individual_recurring_notify_customers",
      "Bookings - Recurring",
      "User clicks on notify customer from recurring tab while cancellation",
      {...params}
    ),
  clickConfirmCancelRecurringBooking: (params) =>
    fireGAEvent(
      "ml_bookings_recurring_individual_recurring_confirms_cancellation",
      "Bookings - Recurring",
      "User Confirm Cancellation from recurring tab",
      {...params}
    ),
  addBookingToExistingRecurringBooking: (params) =>
    fireGAEvent(
      "ml_bookings_recurring_individual_recurring_add_booking_btn_clicked",
      "Bookings - Recurring",
      "User Click on Add Booking to existing recurring",
      {...params}
    ),
  bookingAddedToExistingRecurringBooking: (params) =>
    fireGAEvent(
      "ml_bookings_recurring_individual_recurring_booking_added",
      "Bookings - Recurring",
      "User Adds Booking to existing recurring",
      {...params}
    ),
  /////////////////////////

  clickOnEditRecurringBooking: (params) =>
    fireGAEvent(
      "ml_bookings_recurring_individual_recurring_edit",
      "Bookings - Recurring",
      "User Click on edit booking from recurring tab",
      {...params}
    ),
  clickOnSaveEditRecurringBooking: (params) =>
    fireGAEvent(
      "ml_bookings_recurring_individual_recurring_edit_save changes",
      "Bookings - Recurring",
      "User Click on save edit booking from recurring tab",
      {...params}
    ),
  sendBookingDetails: (params) =>
    fireGAEvent(
      "ml_bookings_recurring_individual_recurring_send_booking_details",
      "Bookings - Recurring",
      "User Click on Send Booking details from recurring tab",
      {...params}
    ),
  confirmSendBookingDetails: (params) =>
    fireGAEvent(
      "ml_bookings_recurring_individual_recurring_send_booking_details_confirm ",
      "Bookings - Recurring",
      "User Click on Confirm Send Booking details from recurring tab",
      {...params}
    ),
  clickOnAddRecrringBooking: () => {
    fireGAEvent(
      "ml_bookings_recurring_add_recurring_booking_btn_clicked",
      "Bookings - Recurring",
      "User Click on Add Recurring Booking"
    );
    fireGAEvent(
      "ml_bookings_recurring_add_recurring_booking_page_opened",
      "Bookings - Recurring",
      "Add Recurring Booking screen opens"
    );
  },
  clickOnConfirmBooking: (params) =>
    fireGAEvent(
      "ml_bookings_recurring_confirm_recurring_booking",
      "Bookings - Recurring",
      "User Click on confirm Recurring Booking",
      {...params}
    ),
  bookingConfirmed: (params) =>
    fireGAEvent(
      "ml_bookings_recurring_individual_recurring_booking_added",
      "Bookings - Recurring",
      "Recurring Booking Confirmed",
      {...params}
    ),
};

const previousBookingTabEvents = {
  //previous bookings tab

  clicksOnPreviousBookingTab: (params) =>
    fireGAEvent(
      "ml_bookings_previous",
      "Bookings - Previous",
      "Previous Bookings tab clicked",
      {...params}
    ),
  clickOnBooking: (params) =>
    fireGAEvent(
      "ml_bookings_open_previous",
      "Bookings - Previous",
      "Click on individual Previous Booking",
      {...params}
    ),
};

/////////// EVENTS //////////////////

const pageEventsCustomEvents = {
  pageLoaded: (params) =>
    fireGAEvent(
      "ml_events_page_loaded",
      "Events",
      "Events Page loaded",
      {...params}
    ),
  activeEventsTabClicked: (params) =>
    fireGAEvent(
      "ml_events_active_events",
      "Events",
      "Active Events Tab clicked",
      {...params}
    ),
  activeEventRowClicked: (params) =>
    fireGAEvent(
      "ml_events_active_individual_event",
      "Events",
      "Any Active Event Row clicked",
      {...params}
    ),
  clickOnDetailTab: (params) =>
    fireGAEvent(
      "ml_events_active_individual_event_event_detail",
      "Events",
      "Click on event detail tab",
      {...params}
    ),
  clickOnCloseEvent: (params) =>
    fireGAEvent(
      "ml_events_active_individual_event_close_event_btn",
      "Events",
      "Click on close event",
      {...params}
    ),
  confirmCloseEvent: (params) =>
    fireGAEvent(
      "ml_events_active_individual_event_event_closes",
      "Events",
      "Confirm close event",
      {...params}
    ),
  clickOnPublicView: (params) =>
    fireGAEvent(
      "ml_events_active_individual_event_public_view",
      "Events",
      "Click on public view",
      {...params}
    ),
  clickOnUnpublishEvent: (params) =>
    fireGAEvent(
      "ml_events_active_individual_event_unpublish",
      "Events",
      "Click on unpublish event",
      {...params}
    ),
  confirmUnpublishEvent: (params) =>
    fireGAEvent(
      "ml_events_active_individual_event_unpublished",
      "Events",
      "Confirm unpublish event",
      {...params}
    ),
  changesSavedForEdit: (params) =>
    fireGAEvent(
      "ml_events_active_individual _event_changes_saved",
      "Events",
      "Edit Changes saved",
      {...params}
    ),
  clickOnRegistrationTab: (params) =>
    fireGAEvent(
      "ml_events_active_individual_event_registrations",
      "Events",
      "Click on registration tab from event details",
      {...params}
    ),
  clickOnIndividualRegisteredUser: (params) =>
    fireGAEvent(
      "ml_events_active_individual_event_individual_registered_user",
      "Events",
      "Click on registered user",
      {...params}
    ),
  openMenuOfRegisteredUser: (params) =>
    fireGAEvent(
      "ml_events_active_individual_event_individual_registered_user_kebab_menu",
      "Events",
      "Click on registered user Menu",
      {...params}
    ),
  clickOnCancelledRegistration: (params) =>
    fireGAEvent(
      "ml_events_active_individual_event_individual_cancelled_user",
      "Events",
      "Click on cancel registeration from user menu",
      {...params}
    ),
  confirmsAddUser: (params) =>
    fireGAEvent(
      "ml_events_active_individual_event_add_user_confirmed",
      "Events",
      "Confirms Add user",
      {...params}
    ),
};

///////// WALLET /////////

const walletTabEvents = {
  pageLoaded: (params) =>
    fireGAEvent(
      "ml_wallets_page_loaded",
      "Wallet",
      "Wallet Tab loaded",
      {...params}
    ),
  transactionTabClicked: (params) =>
    fireGAEvent(
      "ml_wallets_transactions_tab_btn_clicked",
      "Wallet",
      "Transaction Tab Opened",
      {...params}
    ),
  individualTransactionClicked: (params) =>
    fireGAEvent(
      "ml_wallets_individual_transaction_clicked",
      "Wallet",
      "Individual Transaction clicked",
      {...params}
    ),
  transactionPageLoaded: (params) =>
    fireGAEvent(
      "ml_wallets_individual_transaction_page_loaded",
      "Wallet",
      "Individual Transaction page loaded",
      {...params}
    ),
  walletTabOpened: (params) =>
    fireGAEvent(
      "ml_wallets_wallets_tab_btn_clicked",
      "Wallet",
      "Wallet tab in wallet clicked",
      {...params}
    ),
  offerTabOpened: (params) =>
    fireGAEvent(
      "ml_wallets_offers_tab_btn_clicked",
      "Wallet",
      "Offer tab in wallet clicked",
      {...params}
    ),
  individualOfferClicked: (params) =>
    fireGAEvent(
      "ml_wallets_offers_individual_offer opened",
      "Wallet",
      "Click on single offer",
      {...params}
    ),
  walletOfferEditChangesSaved: (params) =>
    fireGAEvent(
      "ml_wallets_changes_saved",
      "Wallet",
      "Wallet Edit Changes Saved",
      {...params}
    ),
  walletOfferCreateSaveBtnClick: (params) =>
    fireGAEvent(
      "ml_wallets_create_offer_save_changes",
      "Wallet",
      "Wallet Save btn clicked",
      {...params}
    ),
  walletOfferCreateSaved: (params) =>
    fireGAEvent(
      "ml_wallets_create_offer_changes_saved",
      "Wallet",
      "Wallet Create Changes saved",
      {...params}
    ),
};

//////// FINANCES //////////////

const financesEvents = {
  pageLoaded: (params) =>
    fireGAEvent(
      "ml_finances_page_loaded",
      "Finances",
      "Finances page loaded",
      {...params}
    ),
  startDateEntered: (params) =>
    fireGAEvent(
      "ml_finances_start_date_entered",
      "Finances",
      "Select start date",
      {...params}
    ),
  endDateEntered: (params) =>
    fireGAEvent(
      "ml_finances_end_date_entered",
      "Finances",
      "Select end date",
      {...params}
    ),
  resetClicked: (params) =>
    fireGAEvent(
      "ml_finances_reset_btn_clicked",
      "Finances",
      "Reset btn clicked",
      {...params}
    ),
};

/////// CUSTOMERS ///////////////

const customerEvents = {
  pageLoaded: (params) =>
    fireGAEvent(
      "ml_customers_page_loaded",
      "Customer",
      "Customer Page Loaded",
      {...params}
    ),
  customerAdded: (params) =>
    fireGAEvent(
      "ml_add_customer_account_created",
      "Customer",
      "Customer Sucessfully Added",
      {...params}
    ),
  customerNotAdded: (params) =>
    fireGAEvent(
      "ml_add_customer_account_not_created",
      "Customer",
      "Customer Adding Failed",
      {...params}
    ),
  editChangesSaved: (params) =>
    fireGAEvent(
      "ml_customer_changes_successfully_saved",
      "Customer",
      "Customer Edit changes saved",
      {...params}
    ),
  editChangesFailed: (params) =>
    fireGAEvent(
      "ml_customer_changes_not_saved",
      "Customer",
      "Customer Edit changes failed",
      {...params}
    ),
  viewProfileOfAnyCustomer: (params) =>
    fireGAEvent(
      "ml_customer_individual_profile_clicked",
      "Customer",
      "View Customer Profile",
      {...params}
    ),
  clickOnDeductWallet: () => {
    fireGAEvent(
      "ml_customer_deduct_from_wallet_btn_clicked",
      "Customer",
      "Deduct Amount from Wallet"
    );
    fireGAEvent(
      "ml_customer_deduct_from_wallet_pop_up_open",
      "Customer",
      "Deduct Amount from Wallet pop up open"
    );
  },
  enterDeductAmount: (params) =>
    fireGAEvent(
      "ml_customer_deduct_amount_btn_clicked",
      "Customer",
      "Enter Deduct Amount from pop up",
      {...params}
    ),
  amountSuccessfullyDeducted: (params) =>
    fireGAEvent(
      "ml_customer_deduct_amount_successful",
      "Customer",
      "Amount successfully deducted",
      {...params}
    ),
  clickOnTopupWallet: () => {
    fireGAEvent(
      "ml_customer_top_up_wallet_btn_clicked",
      "Customer",
      "Topup Amount to Wallet"
    );
    fireGAEvent(
      "ml_customer_top_up_pop_up_open",
      "Customer",
      "Topup Amount to Wallet pop up open"
    );
  },
  enterAmountToTopup: (params) =>
    fireGAEvent(
      "ml_customer_add_amount_btn_clicked",
      "Customer",
      "Enter Amount to top up",
      {...params}
    ),
  topupAmountSuccessfullyDeducted: (params) =>
    fireGAEvent(
      "ml_customer_amount_added",
      "Customer",
      "Topup Amount successfully deducted",
      {...params}
    ),

  clickOnUpcomingBooking: (params) =>
    fireGAEvent(
      "ml_customer_bookings_individual_upcoming_booking",
      "Customer",
      "Click on upcoming booking",
      {...params}
    ),
  clickOnPreviousBooking: (params) =>
    fireGAEvent(
      "ml_customer_bookings_individual_previous_booking",
      "Customer",
      "Click on previous booking",
      {...params}
    ),
  clickOnActivePkg: (params) =>
    fireGAEvent(
      "ml_customer_packages_individual_active_package",
      "Customer",
      "Click on active pkg",
      {...params}
    ),
  clickOnCancelPkg: (params) =>
    fireGAEvent(
      "ml_cutomer_packages_individual_active_package_cancel_btn_clicked",
      "Customer",
      "Click on cancel pkg",
      {...params}
    ),
  emailToCustomerOfCancel: (params) =>
    fireGAEvent(
      "ml_customer_package_cancel_send_email",
      "Customer",
      "Email to customer for cancel pkg",
      {...params}
    ),
  confirmCancelPkg: (params) =>
    fireGAEvent(
      "ml_cutomer_packages_individual_active_package_confirms_cancellation",
      "Customer",
      "Confirm cancel pkg",
      {...params}
    ),
  clickOnExpiredPkg: (params) =>
    fireGAEvent(
      "ml_customer_packages_individual_expired_booking",
      "Customer",
      "Click on expired pkg",
      {...params}
    ),
};

const loginEvents = {
  loginOpen: (params) =>
    fireGAEvent("ml_login_open", "Login", "Login screen opens", {...params}),
  loginSuccess: (params) =>
    fireGAEvent("ml_logon_success", "Login", "Login success", {...params}),
  loginFailed: (params) =>
    fireGAEvent("ml_logon_failure", "Login", "Login failure", {...params}),
};

///// SCHEDULE /////

const scheduleEvents = {
  pageLoads: (params) =>
    fireGAEvent(
      "ml_schedule_add ml_schedule_page_loads",
      "Schedule",
      "Schedule page loads",
      {...params}
    ),
  makeBookerOnAddBooking: (params) =>
    fireGAEvent(
      "ml_schedule_add booking_players_make_booker",
      "Schedule",
      "Make Booker option selected while creating booking",
      {...params}
    ),
  clickOnSingleSlot: (params) =>
    fireGAEvent(
      "ml_schedule_individual_slot_clicked",
      "Schedule",
      "Click on single slot",
      {...params}
    ),

  clickOnSearchByName: (params) =>
    fireGAEvent(
      "ml_schedule_header_search_by_name",
      "Schedule",
      "Click on search by name",
      {...params}
    ),
  clickOnSportsDropdown: (params) =>
    fireGAEvent(
      "ml_schedule_header_sport_dropdown_selected",
      "Schedule",
      "Click on sports dropdown",
      {...params}
    ),
  selectSport: (params) =>
    fireGAEvent(
      "ml_schedule_header_sport_selected",
      "Schedule",
      "Select a sport",
      {...params}
    ),
  clickOnTypeDropdown: (params) =>
    fireGAEvent(
      "ml_schedule_header_type_dropdown_clicked",
      "Schedule",
      "Click on type dropdown",
      {...params}
    ),
  selectType: (params) =>
    fireGAEvent(
      "ml_schedule_header_type_selected",
      "Schedule",
      "Select type from dropdown",
      {...params}
    ),
  clicksOnInfoIcon: (params) =>
    fireGAEvent(
      "ml_schedule_header_i_icon_selected",
      "Schedule",
      "Clicks on info icon",
      {...params}
    ),
  clickOnToday: (params) =>
    fireGAEvent(
      "ml_schedule_header_today_clicked",
      "Schedule",
      "Click on today",
      {...params}
    ),
  selectsDate: (params) =>
    fireGAEvent(
      "ml_schedule_header_today_date_selected",
      "Schedule",
      "Selects Date",
      {...params}
    ),
  clicksOnSidebar: (params) =>
    fireGAEvent(
      "ml_schedule_header_toggle_side_bar",
      "Schedule",
      "Clicks on sidebar",
      {...params}
    ),
  clicksOnUpcomingTab: (params) =>
    fireGAEvent(
      "ml_schedule_header_toggle_bar_upcoming_tab_clicked",
      "Schedule",
      "Clicks on upcoming tab",
      {...params}
    ),
  clicksOnLateTab: (params) =>
    fireGAEvent(
      "ml_schedule_header_toggle_bar_late_tab_clicked",
      "Schedule",
      "Clicks on late tab",
      {...params}
    ),
  clicksOnSingleBooking: (params) =>
    fireGAEvent(
      "ml_schedule_header_toggle_bar_upcoming_individual_booking_clicked",
      "Schedule",
      "Clicks on single booking",
      {...params}
    ),
  cliskOnCheckedIn: (params) =>
    fireGAEvent(
      "ml_schedule_header_toggle_bar_upcoming_user_checked_in",
      "Schedule",
      "Clicks on Checkin",
      {...params}
    ),
  addBookingPopupOpen: (params) =>
    fireGAEvent(
      "ml_schedule_add_booking_pop_up_opens",
      "Schedule",
      "Add booking pop up opens",
      {...params}
    ),
  toggleFreeBooking: (params) =>
    fireGAEvent(
      "ml_schedule_add_booking_free_booking_toggled_on",
      "Schedule",
      "Toggle free booking",
      {...params}
    ),
  userSelectType: (params) =>
    fireGAEvent(
      "ml_schedule_add_booking_slot_type_selected",
      "Schedule",
      "User select type on new booking",
      {...params}
    ),
  changePrice: (params) =>
    fireGAEvent(
      "ml_schedule_add_booking_change_price_btn_clicked",
      "Schedule",
      "Change court price",
      {...params}
    ),
  changePriceSaved: (params) =>
    fireGAEvent(
      "ml_schedule_add_booking_save_btn_clicked",
      "Schedule",
      "Changes Saved",
      {...params}
    ),
  changePaymentType: (params) =>
    fireGAEvent(
      "ml_schedule_add_booking_select_payment_method",
      "Schedule",
      "Changes payment type",
      {...params}
    ),
  addsPlayer: (params) =>
    fireGAEvent(
      "ml_schedule_add_booking_add_player",
      "Schedule",
      "Adds player",
      {...params}
    ),
  addBookingBtnClicked: (params) =>
    fireGAEvent(
      "ml_schedule_add_booking_add_booking_btn_clicked",
      "Schedule",
      "Add booking btn clicked",
      {...params}
    ),
  bookingCreatedSuccessfully: (params) =>
    fireGAEvent(
      "ml_schedule_add_booking_booking_created",
      "Schedule",
      "Booking created successfully",
      {...params}
    ),
  clicksOnIndividualBookingFromScheule: (params) =>
    fireGAEvent(
      "ml_schedule_individual_booking_clicked",
      "Schedule",
      "Clicks on single booking from schedule",
      {...params}
    ),
  clickOnCheckInBtn: (params) =>
    fireGAEvent(
      "ml_schedule_individula_booking_check_in_clicked",
      "Schedule",
      "Click on check-in btn",
      {...params}
    ),
};

///// PACKAGES /////

export const eventOnPkgType = {
  active: "clickedOnActivePkg",
  inactive: "clickedOnInactivePkg",
  expired: "clickedOnExpiredPkg",
};

const packagesEvents = {
  btnClickedToSaveNewPackage: (params) =>
    fireGAEvent(
      "ml_packages_create_new_package_save_btn_clicked",
      "Packages",
      "Save btn click to create new pkg",
      {...params}
    ),
  pkgCreatedSuccessfully: (params) =>
    fireGAEvent(
      "ml_packages_create_new_package_created",
      "Packages",
      "New package created successfully",
      {...params}
    ),
  pkgUpdateBtnClicked: (params) =>
    fireGAEvent(
      "ml_packages_individual_package_edit_package_save_changes",
      "Packages",
      "Package updated successfully",
      {...params}
    ),
  pkgUpdatedSuccessfully: (params) =>
    fireGAEvent(
      "ml_packages_individual_package_successfully_edited",
      "Packages",
      "Package updated successfully",
      {...params}
    ),
  clickedOnActivePkg: (params) =>
    fireGAEvent(
      "ml_package_individual_active_package",
      "Packages",
      "Clicked on active pkg",
      {...params}
    ),
  clickedOnInactivePkg: (params) =>
    fireGAEvent(
      "ml_packages_individual_inactive_package",
      "Packages",
      "Clicked on inactive pkg",
      {...params}
    ),
  clickedOnExpiredPkg: (params) =>
    fireGAEvent(
      "ml_package_individual_expired_package",
      "Packages",
      "Clicked on expired pkg",
      {...params}
    ),
  pkgSuccessfullyActivated: (params) =>
    fireGAEvent(
      "ml_packages_individual_inactive_package_activated_successfully",
      "Packages",
      "Package successfully activated",
      {...params}
    ),
  pkgSuccessfullyInactivated: (params) =>
    fireGAEvent(
      "ml_packages_individual_active_package_inactivated_successfully",
      "Packages",
      "Package successfully In-activated",
      {...params}
    ),
  clickOnInfoTab: (params) =>
    fireGAEvent(
      "ml_packages_individual_package_info_tab_clicked",
      "Packages",
      "Click on info tab",
      {...params}
    ),
  clickOnCustomersTab: (params) =>
    fireGAEvent(
      "ml_packages_individual_package_customers_tab_clicked",
      "Packages",
      "Click on customers tab",
      {...params}
    ),
  clickOnAddCustomers: (params) =>
    fireGAEvent(
      "ml_packages_individual_package_add_customer_btn_clicked",
      "Packages",
      "Click on Add customers Btn",
      {...params}
    ),
  customerAddedSuccessfully: (params) =>
    fireGAEvent(
      "ml_packages_individual_package_successfully_adds_customer",
      "Packages",
      "Customer added successfully",
      {...params}
    ),
  clickOnRegisteredCustomer: (params) =>
    fireGAEvent(
      "ml_packages_individual_package_individual_customer_selected",
      "Packages",
      "Click on registered customer",
      {...params}
    ),
  clickOnExpiredCustomer: (params) =>
    fireGAEvent(
      "ml_packages_individual_package_individual_expired_customer_selected",
      "Packages",
      "Click on expired customer",
      {...params}
    ),
};

///// COURTS /////

const courtsCustomEvents = {
  pageLoaded: (params) =>
    fireGAEvent(
      "ml_court_page_loaded",
      "Courts",
      "Courts page loaded",
      {...params}
    ),
  clickOnAnyCourt: (params) =>
    fireGAEvent(
      "ml_court_individual_court_selected",
      "Courts",
      "Clicked on any court",
      {...params}
    ),
  clickOnEditBtn: (params) =>
    fireGAEvent(
      "ml_court_individual_court_edit_btn_clicked",
      "Courts",
      "Clicked on edit btn",
      {...params}
    ),
  clickOnSaveBtnToUpdate: (params) =>
    fireGAEvent(
      "ml_court_individual_court_save changes_btn_clicked",
      "Courts",
      "Clicked on save btn to update",
      {...params}
    ),
  updatedSuccessfully: (params) =>
    fireGAEvent(
      "ml_court_individual_court_changes_saved",
      "Courts",
      "Updated successfully ",
      {...params}
    ),
  clickOnSaveBtnToCreate: (params) =>
    fireGAEvent(
      "ml_court_individual_court_add_court_save_changes_btn_clicked",
      "Courts",
      "Clicked on save btn to create",
      {...params}
    ),
  createdSuccessfully: (params) =>
    fireGAEvent(
      "ml_court_individual_court_add_court_changes_saved",
      "Courts",
      "Created successfully ",
      {...params}
    ),
};

////// SETTINGS /////

const settingsCustomEvents = {
  clicksOnCourtsFromDropown: (params) =>
    fireGAEvent(
      "ml_settings_dropdown_courts_btn_clicked",
      "Settings",
      "User clicks on Courts from dropdown",
      {...params}
    ),
  clicksOnCourtsFromSettingPage: (params) =>
    fireGAEvent(
      "ml_settings_courts",
      "Settings",
      "User clicks on Courts from Setting page",
      {...params}
    ),
  clicksOnExtrasFromDropdown: (params) =>
    fireGAEvent(
      "ml_settings_dropdown_extras_btn_clicked",
      "Settings",
      "User clicks on Extras from Setting dropdown",
      {...params}
    ),
  clicksOnExtrasFromSettingPage: (params) =>
    fireGAEvent(
      "ml_settings_extras",
      "Settings",
      "User clicks on Extras from Setting page",
      {...params}
    ),
  clicksOnPricingFromDropdown: (params) =>
    fireGAEvent(
      "ml_settings_dropdown_pricing_btn_clicked",
      "Settings",
      "User clicks on Pricing from Setting dropdown",
      {...params}
    ),
  clicksOnPricingFromSettingPage: (params) =>
    fireGAEvent(
      "ml_settings_pricing",
      "Settings",
      "User clicks on Pricing from Setting page",
      {...params}
    ),
  clicksOnHolidayFromDropdown: (params) =>
    fireGAEvent(
      "ml_settings_dropdown_holidays_btn_clicked",
      "Settings",
      "User clicks on Holidays from Setting dropdown",
      {...params}
    ),
  clicksOnHolidaysFromSettingPage: (params) =>
    fireGAEvent(
      "ml_settings_holidays",
      "Settings",
      "User clicks on Holidays from Setting page",
      {...params}
    ),
  clicksOnPoliciesFromDropdown: (params) =>
    fireGAEvent(
      "ml_settings_dropdown_policies_btn_clicked",
      "Settings",
      "User clicks on Policies from Setting dropdown",
      {...params}
    ),
  clicksOnPoliciesFromSettingPage: (params) =>
    fireGAEvent(
      "ml_settings_policies",
      "Settings",
      "User clicks on Policies from Setting page",
      {...params}
    ),

  clicksOnCompanyFromDropdown: (params) =>
    fireGAEvent(
      "ml_settings_dropdown_company_btn_clicked",
      "Settings",
      "User clicks on Company from Setting dropdown",
      {...params}
    ),
  clicksOnCompanyFromSettingPage: (params) =>
    fireGAEvent(
      "ml_settings_company",
      "Settings",
      "User clicks on Company from Setting page",
      {...params}
    ),
  clicksOnClubsFromDropdown: (params) =>
    fireGAEvent(
      "ml_settings_dropdown_clubs_btn_clicked",
      "Settings",
      "User clicks on Clubs from Setting dropdown",
      {...params}
    ),
  clicksOnClubsFromSettingPage: (params) =>
    fireGAEvent(
      "ml_settings_clubs",
      "Settings",
      "User clicks on Clubs from Setting page",
      {...params}
    ),
  clicksOnAdminsFromDropdown: (params) =>
    fireGAEvent(
      "ml_settings_dropdown_manage_admins_btn_clicked",
      "Settings",
      "User clicks on Admins from Setting dropdown",
      {...params}
    ),
  clicksOnAdminsFromSettingPage: (params) =>
    fireGAEvent(
      "ml_settings_manage_admins",
      "Settings",
      "User clicks on Admins from Setting page",
      {...params}
    ),
  clicksOnIntegrationsFromDropdown: (params) =>
    fireGAEvent(
      "ml_settings_dropdown_integration",
      "Settings",
      "User clicks on Integrations from Setting dropdown",
      {...params}
    ),
  clicksOnIntegrationsFromSettingPage: (params) =>
    fireGAEvent(
      "ml_settings_integration",
      "Settings",
      "User clicks on Integrations from Setting page",
      {...params}
    ),
  clicksOnVatFromSettingPage: (params) =>
    fireGAEvent(
      "ml_settings_vat",
      "Settings",
      "User clicks on Vat from Setting page",
      {...params}
    ),
  clicksOnProfileFromSettingPage: (params) =>
    fireGAEvent(
      "ml_settings_profile",
      "Settings",
      "User clicks on Profile from Setting page",
      {...params}
    ),
};

const headersCustomEvents = {
  clickOnSchedule: (params) =>
    fireGAEvent(
      "ml_schedule_btn_clicked",
      "Headers",
      "Clicks on Schedule",
      {...params}
    ),
  clickOnBookings: (params) =>
    fireGAEvent(
      "ml_bookings_btn_clicked",
      "Headers",
      "Clicks on Bookings",
      {...params}
    ),
  clickOnEvents: (params) =>
    fireGAEvent(
      "ml_events_btn_clicked",
      "Headers",
      "Clicks on Events",
      {...params}
    ),
  clickOnWallets: (params) =>
    fireGAEvent(
      "ml_wallets_btn_clicked",
      "Headers",
      "Clicks on Wallets",
      {...params}
    ),
  clickOnFinances: (params) =>
    fireGAEvent(
      "ml_finances_btn_clicked",
      "Headers",
      "Clicks on Finances",
      {...params}
    ),
  clickOnCustomers: (params) =>
    fireGAEvent(
      "ml_customers_btn_clicked",
      "Headers",
      "Clicks on Customers",
      {...params}
    ),
  clickOnReports: (params) =>
    fireGAEvent(
      "ml_reports_btn_clicked",
      "Headers",
      "Clicks on Reports",
      {...params}
    ),
  clickOnPackages: (params) =>
    fireGAEvent(
      "ml_packages_btn_clicked",
      "Headers",
      "Clicks on Packages",
      {...params}
    ),
  clickOnSettings: (params) =>
    fireGAEvent(
      "ml_settings_btn_clicked",
      "Headers",
      "Clicks on Settings",
      {...params}
    ),
};

const extrasCustomEvents = {
  singleExtraAdded: (params) =>
    fireGAEvent(
      "ml_extras_changes_saved",
      "Extras",
      "Individual Extra added successfully",
      {...params}
    ),
  singleExtraUpdated: (params) =>
    fireGAEvent(
      "ml_extras_individual_extra_changes_saved",
      "Extras",
      "Individual Extra updated successfully",
      {...params}
    ),
  pageLoads: (params) =>
    fireGAEvent("ml_extras_page_loaded", "Extras", "Page Loaded", {...params}),
  singleExtraClicked: (params) =>
    fireGAEvent(
      "ml_extras_individual_extra_selected",
      "Extras",
      "Individual Extra selected",
      {...params}
    ),
};

const pricingCustomEvents = {
  pageLoads: (params) =>
    fireGAEvent(
      "ml_pricing_page_loaded",
      "Pricing",
      "Pricing page loads",
      {...params}
    ),
  monthPageLoads: (params) =>
    fireGAEvent(
      "ml_pricing_month_page_loads",
      "Pricing",
      "Pricing Month page loads",
      {...params}
    ),
  weekPageLoads: (params) =>
    fireGAEvent(
      "ml_pricing_week_page_loads",
      "Pricing",
      "Pricing Week page loads",
      {...params}
    ),
  agendaPageLoads: (params) =>
    fireGAEvent(
      "ml_pricing_agenda_page_loads",
      "Pricing",
      "Pricing Agenda page loads",
      {...params}
    ),
  slotClickedInMonthPage: (params) =>
    fireGAEvent(
      "ml_pricing_month_individual_slot_clicked",
      "Pricing",
      "User Clicks on single slot in month page",
      {...params}
    ),
  slotUpdated: (params) =>
    fireGAEvent(
      "ml_pricing_month_individual_slot_edit_successfully_updated",
      "Pricing",
      "Single slot updated successfully in month page",
      {...params}
    ),
  slotClickedInWeekPage: (params) =>
    fireGAEvent(
      "ml_pricing_week_individual_slot_clicked",
      "Pricing",
      "User Clicks on Single slot in week page",
      {...params}
    ),
  slotClickedInAgendaPage: (params) =>
    fireGAEvent(
      "ml_pricing_agenda_individual_slot_clicked",
      "Pricing",
      "User Clicks on Single slot in agenda page",
      {...params}
    ),
  clicksOnAddPrice: (params) =>
    fireGAEvent(
      "ml_pricing_add_price_add_price_btn_clicked",
      "Pricing",
      "User Clicks on Add Price",
      {...params}
    ),
  priceAddedSucessfully: (params) =>
    fireGAEvent(
      "ml_pricing_price_successfully_added",
      "Pricing",
      "Price successfully added",
      {...params}
    ),
};

const holidaysCustomEvents = {
  pageLoads: (params) =>
    fireGAEvent(
      "ml_holidays_page_loaded",
      "Holidays",
      "Holidays Page loaded",
      {...params}
    ),
  singleHolidayClicked: (params) =>
    fireGAEvent(
      "ml_holidays_individual_holiday_selected",
      "Holidays",
      "Single Holiday Selected",
      {...params}
    ),
  singleHolidayUpdated: (params) =>
    fireGAEvent(
      "ml_holidays_individual_holiday_changes_saved",
      "Holidays",
      "Single Holiday Updated",
      {...params}
    ),
  confirmCancelHolidays: (params) =>
    fireGAEvent(
      "ml_holidays_individual_holiday_cancel_holiday_confirmed",
      "Holidays",
      "Comfirm cancel holidays",
      {...params}
    ),
  clickOnAddHoliday: (params) =>
    fireGAEvent(
      "ml_holidays_add_holiday_add_holiday_btn_clicked",
      "Holidays",
      "Click on Add Holiday",
      {...params}
    ),
  holidayAdded: (params) =>
    fireGAEvent(
      "ml_holidays _holiday_added",
      "Holidays",
      "Holiday Added Successfully",
      {...params}
    ),
};

const policiesCustomEvents = {
  pageLoads: (params) =>
    fireGAEvent(
      "ml_policies_page_loaded",
      "Policies",
      "Policies page loaded",
      {...params}
    ),
  updatedSuccessfully: (params) =>
    fireGAEvent(
      "ml_policies_changes_saved",
      "Policies",
      "Policies successfully updated",
      {...params}
    ),
};

const companyCustomEvents = {
  pageLoads: (params) =>
    fireGAEvent(
      "ml_company_page_loads",
      "Company",
      "Company page loaded",
      {...params}
    ),
  updatedSuccessfully: (params) =>
    fireGAEvent(
      "ml_company_page_changes_saved",
      "Company",
      "Company successfully updated",
      {...params}
    ),
};

const clubCustomEvents = {
  pageLoads: (params) =>
    fireGAEvent("ml_clubs_page_loads", "Club", "Club page loaded", {...params}),
  clickOnSingleClub: (params) =>
    fireGAEvent(
      "ml_clubs_individual_club_selected",
      "Club",
      "Club page loaded",
      {...params}
    ),
  updatedSuccessfully: (params) =>
    fireGAEvent(
      "ml_clubs_individual_club_changes_saved",
      "Club",
      "Club successfully updated",
      {...params}
    ),
};

const adminCustomEvents = {
  pageLoads: (params) =>
    fireGAEvent(
      "ml_manage_admin_page_loads",
      "Manage Admins",
      "Manage Admins page loaded",
      {...params}
    ),
  clickOnSingle: (params) =>
    fireGAEvent(
      "ml_manage_admin_individual_admin_clicked",
      "Manage Admins",
      "Click on Single Manage Admins",
      {...params}
    ),
  addedSuccessfully: (params) =>
    fireGAEvent(
      "ml_manage_admin_changes_saved",
      "Manage Admins",
      "Manage Admins page loaded",
      {...params}
    ),
  updatedSuccessfully: (params) =>
    fireGAEvent(
      "ml_manage_admin_changes_saved",
      "Manage Admins",
      "Manage Admins successfully updated",
      {...params}
    ),
};

// INTEGRATIONS

const integrationEvents = {
  pageLoads: (params) =>
    fireGAEvent(
      "ml_manage_admin_integration_page_loads",
      "Integrations",
      "Integrations page load",
      {...params}
    ),
  clickSingleEntry: (params) =>
    fireGAEvent(
      "ml_manage_admin_individual_entry_clicked",
      "Integrations",
      "Integrations Single entry clicked",
      {...params}
    ),
  updatedSuccessfully: (params) =>
    fireGAEvent(
      "ml_manage_admin_changes_saved",
      "Integrations",
      "Integrations Single entry updated success",
      {...params}
    ),
  integrationRemoved: (params) =>
    fireGAEvent(
      "ml_manage_admin_individual_entry_confirms_integration_removal",
      "Integrations",
      "Integrations Removed",
      {...params}
    ),
};
