import dayjs from 'dayjs';
import moment from 'moment-timezone';
import {toFixedNoRounding} from './math';
import { bookingsRegularEditCourtsList, bookingsRegularEdit_PATCH, bookingsRegularPaidAtCounter_PATCH, bookingsRegularPaidByWallet_PATCH } from '../vendor/redux/actions/bookings';
import { callGTMEvent } from './gtmEvents';

export function getExtrasBillingEntries(billing){
  if (billing){
    if (billing?.extras?.length > 0){
      return billing?.extras.map((extra)=>{
        return {
          label: `${extra.name} x ${extra.quantity}`,
          amount: toFixedNoRounding(extra.totalPrice,2)
        }
      })
    }else{
      return []
    }
  }else {
    return []
  }
}

export function getCourtBookingLength(startTime, endTime) {
  if (startTime && endTime) {
    const startTimeHM = startTime.split(':');
    const endTimeHM = endTime.split(':');
    const start = new Date();
    start.setHours(startTimeHM[0]);
    start.setMinutes(startTimeHM[1]);
    const end = new Date();
    end.setHours(endTimeHM[0]);
    end.setMinutes(endTimeHM[1]);
    return Math.abs(dayjs(start).diff(dayjs(end), 'minute')) / 60;
  } else {
    return 0;
  }
}

export function getCourtBookingLengthInMinutes(startTime, endTime) {
  if (startTime && endTime) {
    const start = dayjs(startTime, 'HH:mm');
      const end = dayjs(endTime, 'HH:mm');
    const differenceInMinutes = end.diff(start, 'minute');

    return differenceInMinutes
  } else {
    return 0;
  }
}

export function getBookingTimeHourSuffix(bookingTimeHours) {
  if (typeof bookingTimeHours === 'number'){
    if (bookingTimeHours <= 1) {
      return '';
    } else {
      return 's';
    }
  }
}

export function getIsBookingPast(
    bookingDate, bookingStartTime, bookingTimezone) {
  const bookingDateLocal = moment.tz(`${bookingDate} ${bookingStartTime}`,
      bookingTimezone);
  const nowLocal = moment();
  return nowLocal.isAfter(bookingDateLocal);
}

export function getBooker(participants) {
  return participants?.find((el) => {
    return el.owner;
  });
}

export function getIsBookingComplete(
    bookingDate, bookingStartTime, bookingTimezone, bookingStatus) {
  const isPast = getIsBookingPast(bookingDate, bookingStartTime,
      bookingTimezone);
  if (isPast && bookingStatus === 'paid') {
    return true;
  }
}

export function getIsBookingClosed(
    bookingDate, bookingStartTime, bookingTimezone, bookingStatus) {
  const isPast = getIsBookingPast(bookingDate, bookingStartTime,
      bookingTimezone);
  if (isPast && bookingStatus === 'unpaid') {
    return true;
  }
}

export function getExtrasArrayInApiFormat(stateExtras) {
  const extras = [];
  stateExtras.forEach((stateExtra) => {
    const extra = {};
    extra.extrasId = stateExtra.id;
    extra.quantity = stateExtra.count;
    extras.push(extra);
  });
  return extras;
}

export function getExtrasArrayInRecurringApiFormat(stateExtras) {
  const extras = [];
  stateExtras.forEach((stateExtra) => {
    const extra = {};
    extra.id = stateExtra.id;
    extra.quantity = stateExtra.count;
    extra.name = stateExtra.name;
    extras.push(extra);
  });
  return extras;
}

export function getUsersArrayInApiFormat(players, clubID) {
  const users = [];
  players.forEach((statePlayer) => {
    let player = {};
    player.clubId = clubID;
    player.userId = statePlayer?.id;
    player.firstName = statePlayer?.firstName;
    player.lastName = statePlayer?.lastName;
    player.email = statePlayer?.email;
    player.owner = statePlayer.isOrganizer;
    player.type = 'player';
    player.paidAtCounter = statePlayer?.paidAtCounter;
    player.walletPayment = statePlayer?.walletPayment;
    users.push(player);
  });

  return users;
}

export function getIsBooker(user, participants) {
  if (user && participants) {
    const currentParticipant = getCurrentParticipant(user?.userObj.id,
        participants);
    return !!currentParticipant?.owner;
  }
}

export function getHasParticipantPaid(user, participants) {
  if (user && participants) {
    const currentParticipant = getCurrentParticipant(user?.userObj.id,
        participants);
    if (currentParticipant){
      return currentParticipant.paymentStatus === 'paid';
    }
    return false
  }
}

export function getCurrentParticipant(userId, participants) {
  return participants.find((participant) => {
    return participant?.user?.userId === userId;
  });
}





const putCourts=(clubArr)=>{

  const copyArr = {...clubArr}

    let obj
    obj={...copyArr}

    delete obj.club

    return JSON.stringify(obj)

}

export const regularBookingEditInputsInfo=(props)=>{

  const {formData,setValue} = props


  let dataToReturn={

      'notes':{value:'',disabled:false},
      'paymentType':{value:'',disabled:false},
      'bookingPrice':{value:'',disabled:false},
      'startDate':{value:'',disabled:false},
      'startTime':{value:'',disabled:false},
      'endTime':{value:'',disabled:false},
      'court':{value:'',disabled:false,custom:false},
      'players':{value:'',disabled:false,custom:false},

  }


  if(formData){

      dataToReturn={

          'notes':{value:formData?.internalNotes,disabled:false},
          'paymentType':{value:formData?.paymentType,disabled:true},
          'bookingPrice':{value:formData?.billing?.originalPrice,disabled:true},
          'startDate':{value:formData?.date,disabled:false},
          'startTime':{value:formData?.startTime,disabled:false},
          'endTime':{value:formData?.endTime,disabled:false},
          'court':{value:putCourts(formData?.court),disabled:false,custom:false},
          'extras':{value:[],disabled:true,custom:false},
          'bookingType':{value:JSON.stringify(formData?.bookingType),disabled:true,custom:false},
          'players':{value:formData?.participants?.map(x=>({...x,...x.user}))??[],disabled:true,custom:false},


      }


  }

  return dataToReturn

}

export const getFormDefaults=(booking)=>{

  const formDefaults = regularBookingEditInputsInfo({formData:booking})

  let obj={}

  for (const key in formDefaults) {
      if (Object.hasOwnProperty.call(formDefaults, key)) {

          const element = formDefaults[key];

          if(!element.custom)
          obj[key] = element.value

          continue;
      }
  }

  return obj
}



export const getAvailableCourtsForEditRegularBooking=(props)=>{

  const {payload,setCourtLoading,setCourtOptions, sport, isAllCourts} = props

   return dispatch=>{

    setCourtLoading(true)


    dispatch(bookingsRegularEditCourtsList(payload, cbSuccess, cbFailure))

    function cbSuccess(res){

      const response = res?.data?.data
      setCourtOptions(response
        .filter(x => sport === 'Squash' && !isAllCourts ? x.sport === 'Squash' : x)
        .map(x => ({ id: x.id, value: x.id, label: x.name, sport: x.sport,courtFeature:x.court_feature,courtSize:x.court_size })))
      setCourtLoading(false)
    }

    function cbFailure(res){

      console.log(' !! ERR on getting available courts for edit regular booking',res)
      setCourtLoading(false)
    }

   }

}

export const submitEditHandler=props=>{


  const {data,setLoading,enqueueSnackbar,t,navigateTo,fetchBooking} = props

  const {
    court,
    startDate,
    startTime,
    endTime,
    notes,
    bookingId
  } = data

  const payload={


    "courtId": court,
    "date": startDate,
    "startTime": startTime,
    "endTime": endTime,
    "internalNotes": notes,
    bookingId

  }

  setLoading(true)

  function cbSuccess(res){

    const response = res?.data?.data
    callGTMEvent('bookingUpcoming','updatedSuccessfully');
    setLoading(false)
    navigateTo(-1)
    enqueueSnackbar(t('booking.regular.edit.successMessage'),{variant:'success'})
    fetchBooking(bookingId);
  }

  function cbFailure(e){

    console.log(' !! ERR on edit regular booking',e?.response?.data?.data)
    setLoading(false)
    enqueueSnackbar(t(t(e?.response?.data?.data)) ||
          t('networkError'), {variant: 'error'})
  }

return dispatch=>dispatch(bookingsRegularEdit_PATCH(payload,cbSuccess,cbFailure))




}

export const isDateInPast = (dateString, timezone) => {
  // Get current date in the specified timezone
  const currentDate = moment().tz(timezone).startOf('day').valueOf();

  // Parse the provided date string
  const providedDate = moment.tz(dateString, timezone).startOf('day').valueOf();

  // Compare the dates
  return providedDate<currentDate;
}

export const isFutureTime = (dateString, timezone) => {
  // Get current date in the specified timezone
  const currentDate = moment().tz(timezone).startOf('day').valueOf();

  // Parse the provided date string
  const providedDate = moment.tz(dateString, timezone).startOf('day').valueOf();

  // Compare the dates
  return providedDate>currentDate;
}


export const isAllowedToEdit=(startDate,providedTime,timezone)=>{

  if(!isDateInPast(startDate,timezone)){

    if(isFutureTime(startDate,timezone))
    return true

      // Get current time
      const currentTime = moment().tz(timezone).format('YYYY-MM-DD HH:mm:ss');

      // Parse the provided time string
      const providedTimeParts = providedTime.split(':');
      const providedHours = parseInt(providedTimeParts[0]);
      const providedMinutes = parseInt(providedTimeParts[1]);

      // Set the provided time to today
      const providedTimeToday = dayjs()
          .set('hour', providedHours)
          .set('minute', providedMinutes)
          .set('second', 0);

      // Compare with current time
      return providedTimeToday.isAfter(currentTime);
  }else{

    return false

  }
}


export const cancelRefundChannelOption=[
  {value:'bank',id:1,label:'Bank'},
  {value:'wallet',id:2,label:'Wallet'}
]

export function getInitials(name) {
  // Split the name into words
  const words = name.split(" ");

  // Map each word to its first letter and capitalize it
  const initials = words.map(word => word.charAt(0).toUpperCase()).slice(0,2);

  // Join the initials together
  return initials.join("");
}

export const confirmPaidAtCounter=(props)=>{

  const {payload,setLoading,setIsOpenConfirmDialog,setRecallBooking,t,enqueueSnackbar,walletPayment} = props

  function cbSuccess(res){

    const response = res?.data?.data

    setRecallBooking(true);
    setLoading(false);
    setIsOpenConfirmDialog(false);
  }

  function cbFailure(e){

    setLoading(false)
    enqueueSnackbar(t(t(e?.response?.data?.data)) ||
          t('networkError'), {variant: 'error'})
  }

  setLoading(true)
  return dispatch=>{

    if(walletPayment){
      dispatch(bookingsRegularPaidByWallet_PATCH(payload,cbSuccess,cbFailure))
    }else{
      dispatch(bookingsRegularPaidAtCounter_PATCH(payload,cbSuccess,cbFailure))
    }

  }

}

export const getBookerForSchedule = (participants) => {
  const booker = participants[0]?.user;
  const totalLength = booker?.firstName?.length + booker?.lastName?.length;
  return totalLength > 14
      ? `${booker?.firstName?.substring(0, 1)}.${booker?.lastName || ''}`
      : `${booker?.firstName} ${booker?.lastName || ''}`;
}
