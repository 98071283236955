import {Controller, useFormContext} from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import SelectLinearProgress from '../Loaders/SelectLinearProgress';
import {LockOutlined} from '@mui/icons-material';

function RHFSelectSimple({
                           name,
                           label,
                           requiredRules = {},
                           validateRules = {},
                           variant = 'outlined' || 'filled' || 'standard',
                           size = 'medium' || 'small',
                           options = [],
                           loading,
                           defaultValue,
                           disabled,
                           renderOptions,
                           onChangeCallback = () => {
                           },
                         }) {

  const {control, formState: {errors}} = useFormContext();

  const fieldParts = name.split('.');
  const dynamicError = fieldParts.reduce((obj, key) => obj?.[key], errors);

  return (
      <FormControl fullWidth size={size} variant={variant}>
        {
            label &&
            <InputLabel error={!!dynamicError} disabled={disabled}>
              {label}
            </InputLabel>
        }
        <Controller
            name={name}
            control={control}
            rules={{
              required: {...requiredRules},
              validate: {...validateRules},
            }}
            defaultValue={defaultValue}
            render={({field: {ref, ...field}}) => {
              return (<Select
                  {...field}
                  inputRef={ref}
                  onChange={(event, child) => {
                    onChangeCallback(event, child);
                    field.onChange(event, child);
                  }}
                  variant={variant}
                  error={!!dynamicError}
                  label={label}
                  disabled={disabled}
                  sx={{
                    '& .MuiSelect-icon': {
                      ...(disabled && {
                        display: 'none',
                      }),
                    },
                  }}
                  endAdornment={
                      disabled?
                      <InputAdornment
                          position="end"
                      >
                        <LockOutlined color={'disabled'}/>
                      </InputAdornment>:''
                  }
              >
                {
                  renderOptions ? renderOptions() :
                      options.map((option) => {
                        return <MenuItem
                            key={option.value}
                            value={option.value}
                            disabled={option.disabled}
                        >
                          {option.label}
                        </MenuItem>;
                      })
                }
              </Select>);
            }}/>
        {
            !!dynamicError &&
            <FormHelperText error>{dynamicError?.message}</FormHelperText>
        }
        {
            loading &&
            <SelectLinearProgress/>
        }
      </FormControl>
  );

}

export default RHFSelectSimple;
