import {statusError, statusLoading, statusSuccess} from './status';
import {
  GET_CLUB_TIMING_FAILURE,
  GET_CLUB_TIMING_LOADING,
  GET_CLUB_TIMING_SUCCESS,
} from '../constants';
import axios from 'axios';

export const clubsTimingsList_GET = (clubId, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(GET_CLUB_TIMING_LOADING));
      const url = `clubs/timings/list?clubId=${clubId}`;
      const response = await axios.get(url);
      dispatch(statusSuccess(GET_CLUB_TIMING_SUCCESS, response.data.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(GET_CLUB_TIMING_FAILURE, e));
    }
  };
};
