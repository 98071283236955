import {Box, Typography} from '@mui/material';

function ChipRating({rating, variant = 'dark' || 'light',sx={}}) {
  return (
      <Box
          sx={{
            ...(variant === 'dark' && {
              background: 'linear-gradient(101.83deg, #4994EC 12.31%, #A895F2 84.41%)',
            }),
            ...(variant === 'light' && {
              background: 'linear-gradient(101.83deg, rgba(73, 148, 236, 0.16) 12.31%, rgba(168, 149, 242, 0.16) 84.41%)',
            }),
            borderRadius: '8px',
            lineHeight: 1,
            py: .25,
            px: 1,
            ...sx,
          }}
      >
        <Typography
            component='span'
            fontSize={'13px'}
            fontWeight={700}
            sx={{
              color: '#fff',
              ...(variant === 'light' && {
                backgroundImage: 'linear-gradient(101.83deg, #4994EC 12.31%, #A895F2 84.41%)',
                color: 'transparent',
                backgroundClip: 'text',
              }),
            }}
        >
          {rating}
        </Typography>
      </Box>
  );
}

export default ChipRating;
