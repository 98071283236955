import {useState} from 'react';
import {Button, FormHelperText, Popover} from '@mui/material';
import DigitalClockCustom from './DigitalClockCustom';

export function TimePicker({
                             time,
                             onChange,
                             minTime,
                             maxTime,
                             label,
                             disabled,
                             shouldDisableTimeOption = () => false,
                             error,
                             showErrorMsg,
                            //  minInterval,
                             timeInterval = 30
                           }) {

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const IS_OPEN_POPOVER = Boolean(popoverAnchorEl);

  function handleOpenPopover(event) {
    setPopoverAnchorEl(event.currentTarget);
  }

  function handleClosePopOver() {
    setPopoverAnchorEl(null);
  }

  function handleChangeTime(time) {
    onChange(time);
    handleClosePopOver();
  }

  return (
      <>
        <Button
            color={!!error ? 'error' : 'primary'}
            disabled={disabled}
            variant="text"
            onClick={handleOpenPopover}
            sx={{
              textTransform: 'unset',
              fontWeight: '400',
              fontSize: '16px',
            }}
        >
          {!!time ? time.format('LT') : label}
        </Button>

        {
            !!error && !!showErrorMsg &&
            <FormHelperText>
              {error?.message}
            </FormHelperText>
        }
        <Popover
            open={IS_OPEN_POPOVER}
            anchorEl={popoverAnchorEl}
            onClose={handleClosePopOver}
            // keepMounted={true}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            sx={{
              maxHeight:"300px"
            }}
        >
          <DigitalClockCustom
              value={time}
              onChange={handleChangeTime}
              minTime={minTime}
              maxTime={maxTime}
              shouldDisableTime={shouldDisableTimeOption}
              interval={timeInterval}
          />
        </Popover>
      </>
  );
}
