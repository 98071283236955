import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {useEffect, useState} from 'react';
import {tournamentsParticipantMatches_GET} from '../../api/tournaments';
import EmptyPlaceholder from '../../components/_Global/Empty/EmptyPlaceholder';
import {getInitials} from '../../utils/bookings';
import {roundToTwoDecimal} from '../../utils/math';
import {ScoreboardOutlined} from '@mui/icons-material';
import ChipRating from '../../components/_Global/Chips/ChipRating';
import {
  useTournamentsScoreboardContext,
} from '../../components/Tournaments/ScoreBoard/TournamentsScoreBoardProvider';

function DialogTournamentsScoreboardParticipantDetail({
                                                        open,
                                                        onClose = () => {
                                                        },
                                                        participantId,
                                                        participantName,
                                                      }) {

  const {t} = useTranslate();

  const [matches, setMatches] = useState();
  const [loading, setLoading] = useState(true);

  function fetchMatches() {

    setLoading(true);

    tournamentsParticipantMatches_GET({
      participantId,
      cbSuccess,
      cbFail,
    });

    function cbSuccess(res) {
      setMatches(res?.data?.data);
      setLoading(false);
    }

    function cbFail(e) {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (open && participantId) {
      fetchMatches();
    }
  }, [participantId, open]);

  return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {
            `${t(
                'tournaments.scoreboard.playerScoreCard')} (${participantName})`
          }
        </DialogTitle>
        <DialogContent>
          <MatchDetails
              isLoading={loading}
              matches={matches}
          />
        </DialogContent>
        <DialogActions>
          <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'flex-start'}
          >
            <Button
                variant={'outlined'}
                onClick={() => {
                  onClose();
                }}
            >
              {t('global.buttons.actions.close')}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
  );
}

export default DialogTournamentsScoreboardParticipantDetail;

function MatchDetails({isLoading, matches}) {

  const {t} = useTranslate();
  const {tournamentSport} = useTournamentsScoreboardContext();
  const sport = tournamentSport;

  const disabledCss = {
    backgroundColor: 'rgba(238, 238, 238, 1)',
    color: 'rgba(158, 158, 158, 1)',
  };

  const scoreCss = {
    width: '36px',
    height: '36px',
    padding: '8px 12px',
    backgroundColor: '#26B55D',
    borderRadius: '6px',
    textAlign: 'center',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  if (isLoading) {
    return (
        <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '300px',
            }}
        >
          <CircularProgress/>
        </Box>
    );
  }

  return (
      <Box mt={2}>
          {
              !isLoading && matches?.length === 0 &&
              <Box sx={{py:3}}>
                <EmptyPlaceholder
                    icon={<ScoreboardOutlined color={'action'}/>}
                    title={
                      <Stack
                          alignItems={'center'}
                          sx={{
                            maxWidth: '426px',
                            textAlign: 'center',
                          }}
                      >
                        <Typography variant={'body1'}>
                          {t('tournament.noMatches')}
                        </Typography>
                        <Typography variant={'body2'}>
                          {t('tournament.noMatches.detail')}
                        </Typography>
                      </Stack>
                    }
                />
              </Box>
          }
          {
              !isLoading && matches?.length > 0 &&
              matches.map(match => (
                  <Stack sx={{
                    border: '1px solid #DCDDEB',
                    borderRadius: '12px',
                    mb: 2,
                  }}>
                    <Box
                        sx={{
                          textAlign: 'center', color: '#fff',
                          padding: 1.5,
                          borderTopLeftRadius: '12px',
                          borderTopRightRadius: '12px',
                          background: 'radial-gradient(circle at 2.56% 16.25%, #8F7DD8 3.5%, #6F84D7 41%, #2B609F 100%)',
                        }}>
                      <Typography sx={{fontWeight: 600}}>{t(
                          'tournament.round.heading')} {match?.orderNumber}</Typography>
                    </Box>
                    {
                      match?.tournamentMatches?.map((tournamentMatch, i) => (
                          < >
                            <Stack
                                flexDirection={'row'}
                                padding={'16px 32px'}
                                alignItems={'end'}
                                justifyContent={'space-between'}
                            >
                              {
                                tournamentMatch?.teamA?.tournamentParticipants?.map(
                                    prt => (
                                        <PlayerContainer
                                            teamSize={tournamentMatch?.teamA?.tournamentParticipants?.length}
                                            gender={prt?.user?.gender}
                                            flexDirection={'row'}
                                            justifyContent={'space-between'}
                                            alignItems={'center'}
                                            width={'100%'}
                                        >
                                          <Player
                                              player={prt?.user}
                                              sport={sport}
                                          />
                                        </PlayerContainer>
                                    ))
                              }
                              <Box sx={{
                                ...scoreCss,
                                ...((!tournamentMatch?.teamAScore ||
                                        tournamentMatch?.teamBScore >
                                        tournamentMatch?.teamAScore) &&
                                    disabledCss
                                ),
                              }}>{tournamentMatch?.teamAScore || '-'}</Box>
                            </Stack>
                            <Divider sx={{margin: '4px 24px'}}/>

                            <Stack
                                flexDirection={'row'}
                                padding={'16px 32px'}
                                alignItems={'end'}
                                justifyContent={'space-between'}
                            >
                              {
                                tournamentMatch?.teamB?.tournamentParticipants?.map(
                                    prt => (
                                        <PlayerContainer
                                            teamSize={tournamentMatch?.teamB?.tournamentParticipants?.length}
                                            flexDirection={'row'}
                                            justifyContent={'space-between'}
                                            alignItems={'center'}
                                            width={'100%'}
                                        >
                                          <Player
                                              player={prt?.user}
                                              sport={sport}
                                          />
                                        </PlayerContainer>
                                    ))}
                              <Box
                                  sx={{
                                    ...scoreCss,
                                    ...((!tournamentMatch?.teamBScore ||
                                            tournamentMatch?.teamBScore <
                                            tournamentMatch?.teamAScore) &&
                                        disabledCss
                                    ),
                                  }}>{tournamentMatch?.teamBScore || '-'}</Box>

                            </Stack>
                          </>
                      ))
                    }
                  </Stack>
              ))
          }
      </Box>
  );
}

function Player({player, isMobile, sport}) {

  const name = `${player?.firstName} ${player?.lastName}`;
  const gender = player?.gender;

  return (
      <Stack
          alignItems={'self-end'}
          flexDirection={'row'}
          spacing={2}
      >
        <Avatar
            sx={{
              color: 'text.primary',
              background: (() => {
                if (gender === 'male') {
                  return 'linear-gradient(197.38deg, #C7EBFF 18.67%, #D0DBFF 88.08%)';
                }
                if (gender === 'female') {
                  return 'linear-gradient(197.38deg, #FFD4EE 18.67%, #D0DBFF 88.08%)';
                }
              })(),
            }}
        >
          {getInitials(name)}
        </Avatar>
        <Stack
            className="nameLevel"
            alignItems={'baseline'}
            ml={'8px !important'}
            rowGap={0.5}
        >
          <Tooltip
              title={name?.length > 14 ? name : ''}
              placement={'top'}
              arrow
          >
            <ClippedText
                textAlign={'center'}
                isMobile={isMobile}
                fontSize={'14px'}
            >
              {name?.length > 14 ? `${name?.slice(0, 11)}...` : name}
            </ClippedText>
          </Tooltip>
          <ChipRating
              rating={player?.rating?.[sport?.toLowerCase?.()]
                  ? roundToTwoDecimal(player.rating[sport.toLowerCase()])
                  : 'NA'}
          />

        </Stack>
      </Stack>
  );

}

const PlayerContainer = styled(Stack)(({
                                         teamSize,
                                         alignItems = null,
                                         isMobile,
                                         justifyContent = 'center',
                                       }) => {

  return {
    justifyContent,
    alignItems: alignItems || (teamSize === 1 || isMobile) ?
        'center' :
        'flex-start',
  };
});

const ClippedText = styled(Typography)(
    ({theme, clampLine, isMobile, fontSize = null}) => {

      return {
        textAlign: isMobile ? 'center' : 'left',
        fontSize: fontSize || isMobile ? '13px' : '16px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': clampLine ?? '1',
        '-webkit-box-orient': 'vertical',
        width: '100%',
      };
    });



