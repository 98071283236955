import {Divider, Stack} from '@mui/material';
import TournamentsMatchesMatchListHeader
  from './TournamentsMatchesMatchListHeader';
import TournamentsMatchesMatchListBody from './TournamentsMatchesMatchListBody';
import TournamentMatchesMatchListResting
  from './TournamentMatchesMatchListResting';
import {useTournamentMatchesContext} from './TournamentMatchesProvider';
import DialogTournamentMatchesReplacePlayer
  from '../../../dialogs/Tournaments/DialogTournamentMatchesReplacePlayer';
import DialogConfirmation from '../../../dialogs/DialogConfirmation';
import {useTranslate} from '@tolgee/react';
import {useState} from 'react';
import {tournamentsMatchesReplacePlayer_PATCH} from '../../../api/tournaments';
import {useSnackbar} from 'notistack';
import {ERRORS} from '../../../constants/errors';

function TournamentsMatchesMatchList() {

  const {t} = useTranslate();
  const {enqueueSnackbar} = useSnackbar();

  const {
    showReplacePlayer,
    showConfirmReplace,
    setShowReplacePlayer,
    setShowConfirmReplace,
    replaceSource,
    replaceTarget,
    replaceMatchId,
    onParticipantReplace,
  } = useTournamentMatchesContext();

  const [isSubmitting, setIsSubmitting] = useState();

  function handleSubmitReplacePlayer() {

    setIsSubmitting(true);

    tournamentsMatchesReplacePlayer_PATCH({
      matchId: replaceMatchId,
      sourceParticipantId: replaceSource?.id,
      targetParticipantEmail: replaceTarget?.user?.email,
      cbSuccess,
      cbFail,
    });

    function cbSuccess(res) {
      onParticipantReplace();
      enqueueSnackbar(t('tournaments.matches.replacePlayer.success'),
          {variant: 'success'});
      setIsSubmitting(false);
      setShowConfirmReplace(false);
    }

    function cbFail(e) {
      const err = e?.response?.data?.data;
      enqueueSnackbar(t(err || ERRORS.NETWORK_ERROR), {variant: 'error'});
      setIsSubmitting(false);
    }

  }

  return (
      <Stack
          divider={<Divider/>}
          sx={{
            backgroundColor: 'white',
            width: '100%',
          }}
      >
        <TournamentsMatchesMatchListHeader/>
        <TournamentsMatchesMatchListBody/>
        <TournamentMatchesMatchListResting/>
        <DialogTournamentMatchesReplacePlayer
            replaceSource={replaceSource}
            open={showReplacePlayer}
            onClose={() => setShowReplacePlayer(false)}
            replaceMatchId={replaceMatchId}
        />
        <DialogConfirmation
            dialogTitle={t('tournaments.matches.replacePlayer.confirm.title')}
            dialogContent={
              <ConfirmReplaceDialogContent
                  replaceSource={replaceSource}
                  replaceTarget={replaceTarget}
              />
            }
            isOpen={showConfirmReplace}
            handleClose={() => {
              setShowConfirmReplace(false);
            }}
            handleConfirm={handleSubmitReplacePlayer}
            isSubmitting={isSubmitting}
            confirmButtonLabel={t('global.buttons.actions.confirm')}
        />
      </Stack>
  );
}

function ConfirmReplaceDialogContent({replaceSource, replaceTarget}) {

  const {t} = useTranslate();

  if (replaceSource && replaceTarget) {

    const sourceName = (() => {
      return `${replaceSource?.user?.firstName ||
      ''} ${replaceSource?.user?.lastName || ''}`;
    })();

    const targetName = (() => {
      return `${replaceTarget?.user?.firstName ||
      ''} ${replaceTarget?.user?.lastName || ''}`;
    })();

    return (t('tournaments.matches.replacePlayer.confirm.description',{
      source: sourceName,
      target: targetName,
    }));

  }

}

export default TournamentsMatchesMatchList;
