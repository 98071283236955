import {
  MODULE_OPEN_MATCHES,
  MODULE_TOURNAMENTS,
  MODULE_TRAINING, MODULE_VAT,
} from './modules';

export const FEATURES = {
  [MODULE_OPEN_MATCHES]: {alias: 'open_match'},
  [MODULE_TRAINING]: {alias: 'trainings'},
  [MODULE_TOURNAMENTS]: {alias: 'tournaments'},
  [MODULE_VAT]: {alias: 'vat'},
};
