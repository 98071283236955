import {
  Box,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import {useTranslate} from '@tolgee/react';

function BookingCancelNotificationFields({
                                           setNotes,
                                           sendEmail,
                                           setSendEmail,
                                           onNotifyChange = () => {
                                           },
                                         }) {

  const {t} = useTranslate();

  return (
      <Box mt={2}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography color={'black'} variant="subtitle1">{t(
              'booking.cancel.notify')}</Typography>
          <FormGroup>
            <FormControlLabel sx={{mr: 0}}
                              control={<Switch onChange={(check) => {
                                setSendEmail(check.target.checked);
                                onNotifyChange(check.target.checked);
                              }} checked={sendEmail}/>}/>
          </FormGroup>
        </Stack>
        {sendEmail &&
            <TextField
                fullWidth
                multiline
                label={t('booking.cancellation.notes')}
                onChange={e => setNotes(e.target.value)}
                sx={{
                  mt: 2,
                }}
                placeholder={t('booking.cancellation.notesPlaceholder')}
            />}
      </Box>

  );
}

export default BookingCancelNotificationFields;
