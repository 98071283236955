import TournamentsScoreBoard
  from '../../../components/Tournaments/ScoreBoard/TournamentsScoreBoard';

function PageTournamentsDetailScoreBoard({tournamentDetails}) {

  const categories = tournamentDetails?.tournamentCategories;
  const tournamentId = tournamentDetails?.id;
  const tournamentSport = tournamentDetails?.sport?.toLowerCase();

  return (
      <TournamentsScoreBoard
          categories={categories}
          tournamentId={tournamentId}
          tournamentSport={tournamentSport}
      />
  );
}

export default PageTournamentsDetailScoreBoard;
