import {Controller, useFormContext} from 'react-hook-form';
import {Skeleton, TextField} from '@mui/material';
import {useTranslate} from '@tolgee/react';

export function InternalNotes({isLoading,disabled=false}) {
  const {t} = useTranslate();
  const {control} = useFormContext();


  if (isLoading){
    return (
        <Skeleton width={'230px'}/>
    )
  }

  if (!isLoading){
    return (
        <Controller
            name={'notes'}
            control={control}
            disabled={disabled}
            render={({field}) => {
              return (
                  <TextField
                      {...field}
                      variant={'standard'}
                      size={'small'}
                      multiline
                      placeholder={t('global.components.internalNotes.placeholder')}
                  />
              );
            }}
        />
    );
  }

}
