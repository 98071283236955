import {useTournamentMatchesContext} from './TournamentMatchesProvider';
import {Box, Stack, Typography} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import ChipRating from '../../_Global/Chips/ChipRating';
import {convertToNumber} from '../../../utils/type-coercion';

function TournamentMatchesMatchListResting() {

  const {t} = useTranslate();
  const {restingPlayers, loadingMatches} = useTournamentMatchesContext();

  if (loadingMatches || !restingPlayers || restingPlayers.length === 0) {
    return;
  }

  return (
      <Box sx={{
        p: 4,
        px: 8,
        pb: 6,
      }}>
        <Typography variant="h5" textAlign={'center'} mb={4}>
          {t('tournaments.matches.restingPlayers.title')}
        </Typography>
        <Stack direction={'row'} columnGap={4} rowGap={3} flexWrap={'wrap'}>
          {
            restingPlayers?.map((player, index) => {
              return <RestingPlayer key={index} player={player}/>;
            })}
        </Stack>
      </Box>
  );

}

function RestingPlayer({player}) {

  const {user} = player;

  const rating = (()=>{
    let r = convertToNumber(user?.rating)
    if (typeof r === 'number') {
      return r.toFixed(2);
    }else {
      return "NA"
    }
  })()

  return (
      <Stack alignItems={'flex-start'}>
        <Typography color={'text.secondary'}>
          {`${user?.firstName || ''} ${user?.lastName || ''}`}
        </Typography>
        <ChipRating rating={rating} variant={'light'}/>
      </Stack>
  );
}

export default TournamentMatchesMatchListResting;
