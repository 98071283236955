import {createContext, useContext, useMemo, useState} from 'react';
import {bookingsCancel_POST} from '../../../vendor/redux/actions/bookings';
import {useSnackbar} from 'notistack';
import {useDispatch} from 'react-redux';
import {useTranslate} from '@tolgee/react';

const CourtsAvailabilityContext = createContext();

function TournamentsCourtsAvailabilityListProvider({
                                                     type = 'add', // 'edit' || 'detail',
                                                     tournament,
                                                     courts,
                                                     isLoadingCourts,
                                                     onAddBookingSuccess = () => {
                                                     },
                                                     onCancelBookingSuccess = () => {
                                                     },
                                                     children,
                                                   }) {

  const [cancelBookingId, setCancelBookingId] = useState(null);
  const [cancelBookingIndex, setCancelBookingIndex] = useState(0);
  const [cancelNotifyCustomer, setCancelNotifyCustomer] = useState();
  const [cancelNotes, setCancelNotes] = useState();
  const [showCancelBookingDialog, setShowCancelBookingDialog] = useState();
  const [isLoadingCancelBooking, setIsLoadingCancelBooking] = useState(false);
  const [showAddBookingDialog, setShowAddBookingDialog] = useState(false);

  const {enqueueSnackbar} = useSnackbar();
  const dispatch = useDispatch();
  const {t} = useTranslate();

  const conflictingCourts = useMemo(() => {
    return courts?.filter((c) => {
      return c.status === 'fail';
    });
  }, [courts]);
  const courtsCount = courts?.length;
  const conflictingCourtsCount = conflictingCourts?.length;

  function handleCancelBooking() {

    setIsLoadingCancelBooking(true);

    dispatch(
        bookingsCancel_POST(
            cancelBookingId,
            {
              notes: cancelNotes,
              sendEmail: cancelNotifyCustomer,
            },
            cbSuccess,
            cbFail,
        ));

    function cbSuccess(res) {
      onCancelBookingSuccess();
      setShowCancelBookingDialog(false);
      enqueueSnackbar(t('bookings.recurring.single.detail.cancel.success'),
          {variant: 'success'});
      setIsLoadingCancelBooking(false);
    }

    function cbFail(e) {
      const error = e.response.data.data;
      enqueueSnackbar(error, {variant: 'error'});
      setIsLoadingCancelBooking(false);
    }
  }

  return (
      <CourtsAvailabilityContext.Provider
          value={{
            type,
            tournament,
            /*Courts*/
            courts,
            isLoadingCourts,
            courtsCount,
            conflictingCourtsCount,
            /*Add Booking*/
            showAddBookingDialog,
            setShowAddBookingDialog,
            onAddBookingSuccess,
            /*Cancel Booking*/
            showCancelBookingDialog,
            setShowCancelBookingDialog,
            cancelBookingId,
            setCancelBookingId,
            cancelBookingIndex,
            setCancelBookingIndex,
            isLoadingCancelBooking,
            cancelNotifyCustomer,
            setCancelNotifyCustomer,
            cancelNotes,
            setCancelNotes,
            handleCancelBooking,
            onCancelBookingSuccess,
          }}
      >
        {children}
      </CourtsAvailabilityContext.Provider>
  );
}

export default TournamentsCourtsAvailabilityListProvider;

export function useTournamentsCourtsAvailabilityListContext() {
  const {
    type,
    tournament,
    /*Courts*/
    courts,
    isLoadingCourts,
    courtsCount,
    conflictingCourtsCount,
    /*Add Booking*/
    showAddBookingDialog,
    setShowAddBookingDialog,
    onAddBookingSuccess,
    /*Cancel Booking*/
    showCancelBookingDialog,
    setShowCancelBookingDialog,
    cancelBookingId,
    setCancelBookingId,
    cancelBookingIndex,
    setCancelBookingIndex,
    isLoadingCancelBooking,
    cancelNotifyCustomer,
    setCancelNotifyCustomer,
    cancelNotes,
    setCancelNotes,
    handleCancelBooking,
    onCancelBookingSuccess,
  } = useContext(CourtsAvailabilityContext);

  return {
    type,
    tournament,
    /*Courts*/
    courts,
    isLoadingCourts,
    courtsCount,
    conflictingCourtsCount,
    /*Add Booking*/
    showAddBookingDialog,
    setShowAddBookingDialog,
    onAddBookingSuccess,
    /*Cancel Booking*/
    showCancelBookingDialog,
    setShowCancelBookingDialog,
    cancelBookingId,
    setCancelBookingId,
    cancelBookingIndex,
    setCancelBookingIndex,
    isLoadingCancelBooking,
    cancelNotifyCustomer,
    setCancelNotifyCustomer,
    cancelNotes,
    setCancelNotes,
    handleCancelBooking,
    onCancelBookingSuccess,
  };
}
