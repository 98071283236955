export const MODULE_CUSTOMERS = 'customers';
export const MODULE_BOOKINGS = 'bookings';
export const MODULE_FINANCIALS = 'financials';
export const MODULE_EVENTS = 'events';
export const MODULE_ADMINS = 'admins';
export const MODULE_REPORTS = 'reports';
export const MODULE_COURTS = 'courts';
export const MODULE_EXTRAS = 'extras';
export const MODULE_HOLIDAYS = 'holidays';
export const MODULE_CLUBS = 'clubs';
export const MODULE_PRICING = 'pricing';
export const MODULE_COMPANY = 'company';
export const MODULE_POLICIES = 'policies';
export const MODULE_WALLET = 'wallets';
export const MODULE_PACKAGES = 'packages';
export const MODULE_INTEGRATIONS = 'integrations';
export const MODULE_VAT = 'vat';
export const MODULE_MESHLINK = 'meshlink';
export const MODULE_TRAINING = 'trainings';
export const MODULE_TOURNAMENTS = 'tournaments';
export const MODULE_OPEN_MATCHES= 'open_matches';
export const MODULE_SCHEDULE= 'scheduler';
export const MODULE_PROFILE= 'admin_profile';
export const MODULE_TRAININGS_BROADCAST= 'training_broadcast';
