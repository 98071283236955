import {useTranslate} from '@tolgee/react';
import {Chip} from '@mui/material';

function TournamentParticipantStatusChip({status}) {

  const {t} = useTranslate();

  let color, label;

  switch (status) {
    case 'played':
      color = 'success';
      label = t('tournaments.participants.status.played');
      break;
    case 'not_played':
      color = 'primary';
      label = t('tournaments.participants.status.not_played');
      break;
    case 'resting':
      color = 'error';
      label = t('tournaments.participants.status.resting');
      break;
    default:
      color = 'default';
      label = t('NA');
  }

  return (
      <Chip
          variant={'soft'}
          label={label}
          color={color}
      />
  );

}

export default TournamentParticipantStatusChip;
