import {
  Box,
  Grid,
  Slider,
  Stack,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import PageSection from '../../_Global/Page/PageSection';
import {useTranslate} from '@tolgee/react';
import PageSectionTitle from '../../_Global/Page/PageSectionTitle';
import PageInfoItem from '../../_Global/Page/PageInfoItem';
import {getSliderMarks} from '../../../utils/slider';
import dayjs from 'dayjs';
import UploadedFileList
  from '../../_Global/Uploads/UploadedFileList/UploadedFileList';
import useDefaults from '../../../hooks/defaults/useDefaults';
import {convertToNumber} from '../../../utils/type-coercion';

function TournamentDetailDetails({details, clubRatingSystem}) {

  const {t} = useTranslate();

  const {
    enablePlayerSide,
    name,
    description,
    type,
    format,
    scoringFormat,
    scoringFormatValue,
    tournamentCategories,
    tournamentOrganisers,
    tournamentCourts,
    sport,
    startDate,
    startTime,
    endDate,
    endTime,
    registrationDeadlineDate,
    registrationDeadlineTime,
    internalNotes,
    tournamentAssets,
  } = details || {};

  const categories = tournamentCategories.map(
      (tc) => t(`tournaments.types.categories.${tc.type}`),
  )?.join(', ');

  const organizers = tournamentOrganisers?.map((o) => {
    const admin = o.clubAdmin;
    return `${admin?.firstName} ${admin?.lastName}`;
  })?.join(', ');

  const courts = tournamentCourts.map((tc) => tc?.court?.name)?.
      join(', ');

  const galleryImages = tournamentAssets?.filter((a) => a.type === 'image') ||
      [];
  const attachments = tournamentAssets?.filter((a) => a.type === 'pdf') || [];

  const minRating = convertToNumber(tournamentCategories[0]?.minRating);
  const maxRating = convertToNumber(tournamentCategories[0]?.maxRating);

  const scoringFormatLabel = (() => {

    if (scoringFormat === 'custom') {
      return `${t('tournaments.scoringFormat.custom')} (${scoringFormatValue})`;
    }

    if (scoringFormat === 'standard') {
      return t('tournaments.scoringFormat.standard');
    }

    if (scoringFormat === 'classic') {
      return t('tournaments.scoringFormat.classic');
    }

    return scoringFormat;

  })();

  let clubMinRating;
  let clubMaxRating;

  if (sport && clubRatingSystem) {
    let sportRating = clubRatingSystem[sport.toLowerCase()];
    clubMinRating = convertToNumber(sportRating?.min);
    clubMaxRating = convertToNumber(sportRating?.max);
  }

  return (
      <Box>
        <PageSection>
          <PageSectionTitle
              text={t('tournaments.create.section.basicInfo')}
          />
          <Grid container spacing={2}>
            <PageInfoItem
                label={t('tournaments.create.tournamentName')}
                value={name}
                col={12}
            />
            <PageInfoItem
                label={t('tournaments.create.description')}
                value={description}
                col={12}
            />
            <PageInfoItem
                label={t('tournaments.create.clubPageVisibility')}
                value={enablePlayerSide ?
                    t('global.types.enabledDisabled.enabled') :
                    t('global.types.enabledDisabled.disabled')
                }
                col={12}
            />
            <PageInfoItem
                label={t('tournaments.create.tournamentFormat')}
                value={t(`tournaments.types.formats.${format}`)}
                col={12}
            />
            <PageInfoItem
                label={t('tournaments.create.scoringFormat')}
                value={scoringFormatLabel}
                col={12}
            />
            <PageInfoItem
                label={t('tournaments.create.tournamentCategory')}
                value={categories}
                col={12}
            />

            {
              tournamentCategories?.map((c, index) => {
                return (
                    <CategoryInfo key={index} category={c}/>
                );
              })
            }

            <PageInfoItem
                label={t('tournaments.create.tournamentOrganizer')}
                value={organizers}
                col={12}
            />

          </Grid>
        </PageSection>

        <PageSection>
          <PageSectionTitle
              text={t('tournaments.create.section.sport')}
          />
          <Grid container>
            <PageInfoItem
                label={t('tournaments.create.sport')}
                value={sport}
                col={12}
            />
          </Grid>
        </PageSection>
        {
            ((minRating || minRating === 0) &&
                (maxRating || maxRating === 0)) &&
            <PageSection>
              <PageSectionTitle
                  text={t('tournaments.create.section.ratingRange')}
                  titleMeta={
                    <Switch disabled checked={true}/>
                  }
              />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box px={1.5}>
                    <Slider
                        disabled
                        min={clubMinRating}
                        max={clubMaxRating}
                        marks={getSliderMarks(clubMinRating, clubMaxRating, 1,
                            '')}
                        value={[minRating, maxRating]}
                    />
                  </Box>
                </Grid>
              </Grid>
            </PageSection>
        }
        <PageSection>
          <PageSectionTitle
              text={t('tournaments.detail.section.assignedCourts')}
          />
          <PageInfoItem
              label={t('tournaments.create.courts')}
              value={courts}
              col={12}
          />
        </PageSection>
        <PageSection>
          <PageSectionTitle
              text={t('tournaments.create.section.dateTime')}
          />
          <Grid container spacing={2}>
            <PageInfoItem
                label={t('tournaments.create.tournamentStartDate')}
                value={dayjs(startDate).format('ddd MMM D, YYYY')}
                col={6}
            />
            <PageInfoItem
                label={t('tournaments.create.tournamentEndDate')}
                value={dayjs(endDate).format('ddd MMM D, YYYY')}
                col={6}
            />
            <PageInfoItem
                label={t('tournaments.create.tournamentStartTime')}
                value={dayjs(startTime, 'HH:mm:ss').format('LT')}
                col={6}
            />
            <PageInfoItem
                label={t('tournaments.create.tournamentEndTime')}
                value={dayjs(endTime, 'HH:mm:ss').format('LT')}
                col={6}
            />
          </Grid>

        </PageSection>
        {
            (registrationDeadlineDate && registrationDeadlineTime) &&
            <PageSection>
              <PageSectionTitle
                  text={t('tournaments.create.section.registrationDeadline')}
              />
              <Grid container spacing={2}>
                <PageInfoItem
                    label={t('tournaments.create.registrationDeadlineDate')}
                    value={dayjs(registrationDeadlineDate).
                        format('ddd MMM D, YYYY')}
                    col={6}
                />
                <PageInfoItem
                    label={t('tournaments.create.registrationDeadlineTime')}
                    value={dayjs(registrationDeadlineTime, 'HH:mm:ss').
                        format('LT')}
                    col={6}
                />

              </Grid>
            </PageSection>
        }

        <PageSection>
          <PageSectionTitle
              text={t('tournaments.create.section.internalNotes')}
          />
          <Grid container spacing={2}>
            <PageInfoItem
                value={internalNotes}
                col={12}
            />
          </Grid>
        </PageSection>

        <PageSection>
          <PageSectionTitle
              text={t('tournaments.create.gallery')}
          />
          <UploadedFileList
              type={'preview'}
              emptyText={'There are no images to view'}
              files={galleryImages}
          />
        </PageSection>
        <PageSection>
          <PageSectionTitle
              text={t('tournaments.create.attachments')}
          />
          <UploadedFileList
              type={'list'}
              emptyText={'There are no files to view'}
              files={attachments}
          />
        </PageSection>
      </Box>
  );
}

function CategoryInfo({category}) {

  const theme = useTheme();
  const {t} = useTranslate();
  const {defaultCurrencySymbol} = useDefaults();

  return (
      <Grid item xs={12}>
        <Stack
            sx={{
              backgroundColor: theme.palette.grey[100],
              border: `1px solid ${theme.palette.divider}`,
              p: 2,
              pb: 3,
              borderRadius: '8px',
            }}
        >
          <Typography mb={2}>
            {t(`tournaments.types.categories.${category.type}`)}
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CategoryInfoItem
                  label={t('tournaments.create.numberOfParticipants')}
                  value={category?.participantCount}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CategoryInfoItem
                  label={t('tournaments.create.pricePerParticipant')}
                  value={`${defaultCurrencySymbol} ${category?.price}`}
              />
            </Grid>
          </Grid>

        </Stack>
      </Grid>

  );
}

function CategoryInfoItem({label, value}) {

  const theme = useTheme();

  return (
      <Stack
          spacing={.5}
          sx={{
            backgroundColor: '#fff',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '4px',
            p: 1,
          }}
      >
        <Typography color={'text.secondary'} variant={'caption'}>
          {label}
        </Typography>
        <Typography>{value}</Typography>
      </Stack>
  );

}

export default TournamentDetailDetails;
