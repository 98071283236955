import {Box, Stack, useTheme} from '@mui/material';
import TournamentsMatchesRoundSelector from './TournamentsMatchesRoundSelector';
import TournamentsMatchesCategorySelector
  from './TournamentsMatchesCategorySelector';
import TournamentsMatchesMatchList from './TournamentsMatchesMatchList';
import TournamentMatchesProvider from './TournamentMatchesProvider';
import PageCard from '../../_Global/Page/PageCard/PageCard';

function TournamentsMatches({tournamentDetails}) {

  const categories = tournamentDetails?.tournamentCategories;
  const scoringFormat = tournamentDetails?.scoringFormat;
  const scoringFormatCustomValue = tournamentDetails?.scoringFormatValue;
  const tournamentId = tournamentDetails?.id;
  const tournamentSport = tournamentDetails?.sport;

  return (
      <TournamentMatchesProvider
          categories={categories}
          scoringFormat={scoringFormat}
          scoringFormatCustomValue={scoringFormatCustomValue}
          tournamentId={tournamentId}
          tournamentSport={tournamentSport}
      >
        <PageCard>
          <Box
              sx={{
                backgroundColor: '#F0FAFF',
                borderRadius: '14px',
                pt: '16px',
                display: 'flex',
              }}
          >
            <Stack direction="row" sx={{
              width: '100%',
            }}>
              <TournamentsMatchesRoundSelector/>
              <Stack sx={{flex: 1}}>
                <TournamentsMatchesCategorySelector/>
                <TournamentsMatchesMatchList/>
              </Stack>
            </Stack>

          </Box>
        </PageCard>
      </TournamentMatchesProvider>
  );

}

export default TournamentsMatches;
