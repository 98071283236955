import {useEffect, useRef} from 'react';

function useComponentDidUpdate(
    onUpdateHandler = () => {
    }, dependencies = [],
) {

  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      return onUpdateHandler();
    } else {
      mounted.current = true;
    }

  }, [...dependencies]);

}

export default useComponentDidUpdate;
