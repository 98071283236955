export function toFixedNoRounding(num, decimalPlaces) {

  const reg = new RegExp('^-?\\d+(?:\\.\\d{0,' + decimalPlaces + '})?', 'g');
  const a = num.toString().match(reg)[0];
  const dot = a.indexOf('.');
  if (dot === -1) { // integer, insert decimal dot and pad up zeros
    return a + '.' + '0'.repeat(decimalPlaces);
  }
  const b = decimalPlaces - (a.length - dot) + 1;
  return b > 0 ? (a + '0'.repeat(b)) : a;
}

export function roundToPrecision(value, precision) {
  // var multiplier = Math.pow(10, precision || 0);
  // return Math.round(value * multiplier) / multiplier;
  var rounder = Math.pow(10, precision);
  return (Math.round(value * rounder) / rounder).toFixed(precision);
}

export function roundToTwoDecimal(num){
  try {
    const converted = parseFloat(num);
    let rounded = (Math.round((converted + Number.EPSILON) * 100) / 100).toString();
    if (!rounded.includes('.')) rounded += '.00';
    if (rounded.split('.')[1].length !== 2) rounded += '0'
    return rounded;
  }
  catch (err) {
    throw new Error(err);
  }
}