export function stringBoolToBool(stringBool) {
  if(stringBool==undefined) return false;
  if(stringBool=='undefined') return false;
  if (stringBool === 'false') return false;
  if (stringBool === 'true') return true;
  if(stringBool === null) return false;
  throw new Error('Not a string bool');
}

export const convertToNumber = (value) => {
  if (value === undefined || value === null || value === '') return undefined;
  if (typeof value === 'number') return value;
  const num = Number(value);
  return isNaN(num) ? undefined : num;
};

export function boolToStringBool(bool) {
  if (bool === true) return 'true';
  if (bool === false) return 'false';
  throw new Error('Not a bool');
}
