import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {
  CAPABILITY_READ,
  CAPABILITY_READ_ALL, CAPABILITY_UPDATE,
} from '../../constants/capabilities';
import {MODULE_VAT} from '../../constants/modules';
import {lazy} from 'react';

const PageVATDetail = lazy(
    () => import( '../../pages/VAT/PageVatDetail'));

const PageVATEdit = lazy(
    () => import( '../../pages/VAT/PageVatEdit'));

const RoutesSettingsVat = (
    <>
      <Route path={`/settings/vat`}>
        <Route element={<AccessProtected
            capabilities={CAPABILITY_READ}
            module={MODULE_VAT}
        />}>
          <Route index element={<PageVATDetail/>}/>
        </Route>
        <Route element={<AccessProtected
            capabilities={CAPABILITY_UPDATE}
            module={MODULE_VAT}
        />}>
          <Route path={'edit'} element={<PageVATEdit/>}/>
        </Route>

      </Route>
    </>
);

export default RoutesSettingsVat;
