import {useSelectedClub} from '../../../hooks/clubs/useSelectedClub';
import {useTranslate} from '@tolgee/react';
import {useEffect, useState} from 'react';
import {
  columnTypeDefaults,
} from '../../../vendor/mui/dataGridFilterColumnTypes';
import DataGridReusable from '../../_Global/DatGrid/DataGridReusable';
import {
  tournamentsCustomerList_GET,
  tournamentsList_GET,
} from '../../../api/tournaments';
import dayjs from 'dayjs';
import {useNavigate} from 'react-router-dom';
import {getLocalizedNum} from '../../../utils/localization';
import useLocale from '../../../hooks/localization/useLocale';
import useDefaults from '../../../hooks/defaults/useDefaults';
import TournamentStatusChip from '../TournamentStatusChip';
import TournamentStateChip from '../TournamentStateChip';
import {Stack, Typography} from '@mui/material';
import Progress from '../../_Global/Progress/Progress';
import {
  Visibility,
  VisibilityOff,
  VisibilityOutlined,
} from '@mui/icons-material';

function TournamentsList({
                           type = 'active' || 'past',
                           api = 'list' || 'customer',
                           hideToolbar = false,
                           customerId,
                         }) {

  const {t} = useTranslate();
  const navigateTo = useNavigate();

  const {locale} = useLocale();
  const {defaultCurrencySymbol} = useDefaults();

  const selectedClub = useSelectedClub();
  const clubId = selectedClub?.id;

  const [rows, setRows] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      ...columnTypeDefaults,
      field: 'name',
      headerName: t('tournaments.list.column.tournamentName'),
      flex: 1.3,
      valueGetter: (_, row) => {
        return row?.name;
      },
      renderCell: ({row}) => {
        return (
            <Typography component={'span'} fontSize={'14px'} fontWeight={700}>
              {row?.name}
            </Typography>
        );
      },
    },
    {
      ...columnTypeDefaults,
      field: 'startDate',
      headerName: t('tournaments.list.column.startDate'),
      flex: 1,
      valueGetter: (_, row) => {
        return dayjs(row?.startDate).format('ddd, DD MMM YY');
      },
    },
    {
      ...columnTypeDefaults,
      field: 'endDate',
      headerName: t('tournaments.list.column.endDate'),
      flex: 1,
      valueGetter: (_, row) => {
        return dayjs(row?.endDate).format('ddd, DD MMM YY');
      },
    },
    {
      ...columnTypeDefaults,
      field: 'time',
      headerName: t('tournaments.list.column.time'),
      flex: 1,
      valueGetter: (_, row) => {
        const tStart = dayjs(row?.startTime, 'HH:mm:ss').format('LT');
        const tEnd = dayjs(row?.endTime, 'HH:mm:ss').format('LT');
        return `${tStart} - ${tEnd}`;
      },
    },
    ...(
        api === 'list' ?
            [
              // {
              //   ...columnTypeDefaults,
              //   field: 'activeSlots',
              //   headerName: t('tournaments.list.column.activeSlots'),
              //   flex: 1,
              //   valueGetter: (_, row) => {
              //     return row?.activeSlots;
              //   },
              // },
              // {
              //   ...columnTypeDefaults,
              //   field: 'maxSlots',
              //   headerName: t('tournaments.list.column.maxSlots'),
              //   flex: 1,
              //   valueGetter: (_, row) => {
              //     return row?.maxSlots;
              //   },
              // },
              {
                ...columnTypeDefaults,
                field: 'registrations',
                headerName: t('tournaments.list.column.registrations'),
                flex: .8,
                valueGetter: (_, row) => {
                  return row?.activeSlots;
                },
                renderCell: ({row}) => {
                  const active = row?.activeSlots;
                  const total = row?.maxSlots;
                  const percentage = (active / total) * 100;
                  return (
                      <Stack sx={{height: '100%'}} justifyContent={'center'}
                             rowGap={1}>
                        <Progress percent={percentage}/>
                        <Typography variant={'caption'}
                                    color={'text.secondary'}>
                          {`${active} of ${total}`}
                        </Typography>
                      </Stack>
                  );
                },
              },
              {
                ...columnTypeDefaults,
                field: 'tournamentFormat',
                headerName: t('tournaments.list.column.tournamentFormat'),
                flex: .6,
                valueGetter: (_, row) => {
                  return t(`tournaments.types.formats.${row?.format}`);
                },
              },
              {
                ...columnTypeDefaults,
                field: 'enablePlayerSide',
                headerName: t('tournaments.list.column.clubPageVisibility'),
                flex: .6,
                headerAlign: 'center',
                align: 'center',
                renderHeader: () => {
                  return <Visibility/>;
                },
                valueGetter: (_, row) => {
                  return row?.enablePlayerSide;
                },
                renderCell: ({row}) => {
                  const enabled = row?.enablePlayerSide;

                  let color, Icon;

                  if (enabled) {
                    color = 'success';
                    Icon = Visibility;
                  } else {
                    color = 'error';
                    Icon = VisibilityOff;
                  }

                  //
                  // return enabled ?
                  //     t('global.types.enabledDisabled.enabled') :
                  //     t('global.types.enabledDisabled.disabled');


                  return (
                      <Stack sx={{height: '100%'}} justifyContent={'center'} alignItems={'center'}>
                        <Icon color={color} fontSize={'small'}/>
                      </Stack>
                  )

                },
              },
              {
                ...columnTypeDefaults,
                field: 'totalRevenue',
                headerName: t('tournaments.list.column.totalRevenue'),
                flex: 1,
                valueGetter: (_, row) => {
                  return row?.totalRevenue;
                },
                renderCell: ({row}) => {
                  let revenue = row?.totalRevenue;
                  let output = '-';
                  if (revenue) {
                    output = `${defaultCurrencySymbol} ${getLocalizedNum(locale,
                        revenue)}`;
                  }
                  return output;
                },
              },
            ] :
            []
    ),

    {
      ...columnTypeDefaults,
      field: 'status',
      headerName: t('tournaments.list.column.status'),
      flex: 1,
      valueGetter: (_, {state, status}) => {
        if (state === 'in_progress' || state === 'completed') {
          return state;
        }
        return status;
      },
      renderCell: ({row: {status, state}}) => {

        if (state === 'in_progress' || state === 'completed') {
          return <TournamentStateChip state={state}/>;
        }

        return <TournamentStatusChip status={status}/>;
      },
    },
  ];

  function fetchRows() {

    setIsLoading(true);

    if (api === 'list') {
      tournamentsList_GET({clubId, type, cbSuccess, cbFail});
    }
    if (api === 'customer') {
      tournamentsCustomerList_GET(
          {customerId, clubId, type, cbSuccess, cbFail});
    }

    function cbSuccess(res) {
      const {rows} = res;
      setRows(rows);
      setIsLoading(false);
    }

    function cbFail(e) {
      setRows([]);
      setIsLoading(false);
    }
  }

  function handleRowClick({row}) {
    navigateTo(`/tournaments/detail/${row?.id}`);
  }

  useEffect(() => {
    fetchRows();
  }, [clubId]);

  return (
      <DataGridReusable
          rows={rows}
          isLoading={isLoading}
          columns={columns}
          handleRowClick={handleRowClick}
          showRowCursorPointer={true}
          disableToolbar={hideToolbar}
          // getRowHeight={() => 80}
      />
  );
}

export default TournamentsList;
