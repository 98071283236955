function PlumpyUpdates(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
        <path d="M8.305,14c0.617,0,0.927-0.744,0.493-1.182L5.713,9.703c-0.393-0.396-1.033-0.396-1.426,0l-3.085,3.114C0.768,13.256,1.079,14,1.695,14H8.305z" fill="currentColor" />
        <path d="M7,17v-4H3v5c0,1.657,1.343,3,3,3h13v-4H7z" fill="currentColor" />
        <path d="M22.305,10H21V6c0-1.657-1.343-3-3-3H5C3.895,3,3,3.895,3,5c0,1.105,0.895,2,2,2h12v3h-1.305 c-0.617,0-0.927,0.744-0.493,1.182l3.085,3.114c0.393,0.396,1.033,0.396,1.426,0l3.085-3.114C23.232,10.744,22.921,10,22.305,10z" opacity=".35" fill="currentColor" />
        <circle cx="19" cy="19" r="2" fill="currentColor" />
      </svg>
  );
}

export default PlumpyUpdates;
