import {useFormContext} from 'react-hook-form';
import dayjs from 'dayjs';
import {FormHelperText, Stack} from '@mui/material';
import {DatePicker} from '../../_Global/DateTime/DatePicker';
import TimeRangePicker from '../../_Global/DateTime/TimeRangePicker';
import {useTranslate} from '@tolgee/react';
import { useEffect } from 'react';

export function StartTimeSelect({
  defaultDate,
  disabled,
  minMaxTimes,
  minDate = dayjs(),
  minInterval = 30,
  timeInterval = 30,
  onTimeChange = () => {},
  onDateChange = () => {},
  isRecurrAndBookings = false
}) {

  const {t} = useTranslate();
  const {register, watch, setValue, formState} = useFormContext();

  register('startDate',
      {required: {value: true, message: 'Select Start Date'}});
  register('startTime',
      {required: {value: true, message: 'Select Start Time'}});

  register('endTime', {required: {value: true, message: 'Select End Time'}});

  const ERRORS = formState.errors;
  const ERROR_START_DATE = ERRORS?.startDate;
  const ERROR_START_TIME = ERRORS?.startTime;
  const ERROR_END_TIME = ERRORS?.endTime;

  const watched = watch();

  function handleChangeStartDate(date) {
    setValue('startDate', date.locale('en').format('YYYY-MM-DD'),
        {shouldDirty: true, shouldValidate: true});
    const endDate = watched?.endDate;
    if (endDate) {
      if (date.isAfter(dayjs(endDate, 'YYYY-MM-DD'))) {
        setValue('endDate', date.locale('en').format('YYYY-MM-DD'),
            {shouldDirty: true, shouldValidate: true});
      }
    }
    onDateChange();
  }

  function handleChangeStartTime(date) {
    setValue('startTime', date.locale('en').format('HH:mm'),
        {shouldDirty: true, shouldValidate: true});
    onTimeChange();
  }

  function handleChangeEndTime(date) {
    setValue('endTime', date.locale('en').format('HH:mm'),
        {shouldDirty: true, shouldValidate: true});
    onTimeChange();
  }
 
  return (
      <Stack>
        <Stack direction={'row'} spacing={1}>
          <DatePicker
              label={t('global.components.startTime.date.label')}
              onChange={handleChangeStartDate}
              date={watched?.startDate &&
                  dayjs(watched.startDate, 'YYYY-MM-DD')}
              minDate={minDate}
              error={ERROR_START_DATE}
              disabled={disabled ?? !minMaxTimes}
              showErrorMessage={false}
          />
          <TimeRangePicker
              onChangeStartTime={handleChangeStartTime}
              onChangeEndTime={handleChangeEndTime}
              startTime={watched?.startTime &&
                  dayjs(watched.startTime, 'HH:mm')}
              endTime={watched?.endTime && dayjs(watched.endTime, 'HH:mm')}
              startTimeError={ERROR_START_TIME}
              endTimeError={ERROR_END_TIME}
              isDisabled={disabled ?? !minMaxTimes}
              minTime={minMaxTimes && dayjs(minMaxTimes.min, 'HH:mm')}
              maxTime={minMaxTimes && dayjs(minMaxTimes.max, 'HH:mm')}
              minInterval={minInterval}
              isEditBooking={true}
              timeInterval={timeInterval}
          />
        </Stack>
        {
            (ERROR_START_DATE || ERROR_START_TIME || ERROR_END_TIME) &&
            <FormHelperText error>
              {t('global.components.startTime.error')}
            </FormHelperText>
        }
      </Stack>
  );
}

